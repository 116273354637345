import {Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {useDispatch, useSelector} from 'react-redux';
import {signUpActions} from '../../Store/signUpSlice';
import {profileActions} from '../../Store/profileSlice';
import {eAlertActions} from '../../Store/eAlertSlice';
import * as API from '../../Utils/Global';
import $ from 'jquery';

const OptOutModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user_id = localStorage.getItem('user_id');
  const ProfileDetails = useSelector((state) => state.profile);
  const checkHandler = (e) => {
    let id = e.target.id;
    if (id == 'yes') {
      props.setOptoutShow(false);

      $.ajax({
        url: API.deleteUser,
        type: 'POST',
        async: false,
        accepts: 'application/json',
        contentType: 'application/json',
        headers: {
          'x-auth-token': localStorage.getItem('user_token'),
        },
        data: JSON.stringify({
          user_id: user_id,
          email: ProfileDetails.email,
        }),
        success: function (response) {
          dispatch(signUpActions.reset());
          dispatch(profileActions.reset());
          dispatch(eAlertActions.reset());
          var Profile = document.getElementById('profile');
          Profile.className = 'profile dropdown';
          localStorage.removeItem('user_token');
          localStorage.removeItem('user_id');
          localStorage.removeItem('user_firstname');
          localStorage.removeItem('member_id');
          localStorage.removeItem('user_name');
          history.push('/');
        },
        error: function (xhr, response) {
          console.log(xhr);
          console.log(response);
        },
      });
    } else {
      props.setConsentCheck(true);
      props.setOptoutShow(false);
    }
  };

  return (
    <div>
      <Modal show={props.show} backdrop="static" keyboard={false}>
        <div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modalcontent">
                  <div className="consent">
                    <p>"Are you opting out of our Dealfinder Loyalty Program?"</p>
                  </div>
                  <br />
                  <button id="yes" onClick={checkHandler} className="btn redbtn submit-button">
                    Yes
                  </button>
                  <button id="no" onClick={checkHandler} className="btn yellowbtn submit-button">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OptOutModal;
