import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';

const PrivacyPolicy = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div class="clearfix"></div>
      <div class="container main-wrap">
        <div class="heading-wrap">
          <h2>Privacy Policy</h2>
        </div>
        <div class="col-md-12">
          <div class="terms-wrap">
            <p>
              This privacy statement reflects Dirt Cheap and Treasure Hunt's firm commitment to and respect for your privacy. To this end, the
              following discloses the information gathering and dissemination practices for this Web site.
            </p>

            <p>
              The intent of this privacy policy is to detail the information Dirt Cheap and Treasure Hunt may gather about individuals who visit its
              Web sites, how that information is used and Dirt Cheap and Treasure Hunt‘s disclosure practices. Please note that this policy applies
              only to DIRT CHEAP AND TREASURE HUNT Web sites and not to the Web sites of other companies or organizations to which we provide links.
            </p>

            <h3>Site Usage Information</h3>
            <p>
              Dirt Cheap and Treasure Hunt gathers "site usage" information from all visitors to Dirt Cheap and Treasure Hunt Web sites. Such data is
              used in the aggregate, to help determine general usage patterns and fine-tune layout to ensure that our sites are as useful and
              enjoyable as possible. This "site usage" and statistical information may be made public information at Dirt Cheap and Treasure Hunt's
              discretion.
            </p>

            <h3>Individual User Information</h3>
            <p>
              Individually identifiable information will not be released without that individual's prior consent. Visitors may be asked for
              individually identifiable information when signing up to use certain Dirt Cheap and Treasure Hunt services. In some instances, users may
              be asked for personal interest and demographic information in order to create a more customized user experience; however these fields
              will generally be optional.
            </p>

            <h3>Disclosure</h3>
            <p>
              Dirt Cheap and Treasure Hunt reserve the right to access and disclose individually identifiable information to comply with applicable
              laws and lawful government requests, to operate its systems properly or to protect itself or its users. Where practical, Dirt Cheap and
              Treasure Hunt will attempt to obtain the prior consent of the individual prior to disclosing individually identifiable information.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
