import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {trackPromise} from 'react-promise-tracker';
import {Link, useHistory} from 'react-router-dom';
import * as API from '../../Utils/Global.js';
import Share from '../Share';

const PriceCheck = (props) => {
  var history = useHistory();

  useEffect(() => {
    async function getprice() {
      await fetch(API.price, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((raw) => raw.json())
        .then((response) => {
          setPrice(parseFloat(response.scannedPrice).toFixed(2));
          var discount = response.percentage;
          var responsePrice = parseFloat(response.price).toFixed(2);
          var priceType = response.priceType;
          var alreadyDiscountedPricePanel = document.getElementById('alreadyDiscountedPricePanel');
          alreadyDiscountedPricePanel.style.display = 'none';
          if (priceType === 1) {
            if (discount > 0 && responsePrice > 0) {
              setDiscountPrice(responsePrice);
              setDiscountPercent(discount);
            } else if (discount === 0) {
              var pricePanel = document.getElementById('pricePanel');
              var circlePanel = document.getElementById('circleDiv');
              pricePanel.style.display = 'none';
              // alreadyDiscountedPricePanel.style.display="block";
              circlePanel.className = 'price_circle';
              setDiscountPercent(null);
              setResponsePrice('Price already discounted!');
              alreadyDiscountedPricePanel.style.display = 'block';
            } else {
              handleShow('null');
            }
          } else if (priceType === 2) {
            var pricePanel = document.getElementById('pricePanel');
            var circlePanel = document.getElementById('circleDiv');
            pricePanel.style.display = 'none';
            circlePanel.className = 'price_circle';
            setDiscountPercent(null);
            if (response.scannedPrice === responsePrice) {
              setResponsePrice('Price already discounted!');
              alreadyDiscountedPricePanel.style.display = 'block';
            } else {
              setResponsePrice('You Pay ' + '$' + responsePrice);
            }
          } else if (priceType === 3) {
            var pricePanel = document.getElementById('pricePanel');
            var circlePanel = document.getElementById('circleDiv');
            pricePanel.style.display = 'none';
            circlePanel.className = 'price_circle';
            setDiscountPercent(null);
            if (response.scannedPrice === responsePrice) {
              setResponsePrice('Price already discounted!');
              alreadyDiscountedPricePanel.style.display = 'block';
            } else {
              setResponsePrice('You Pay ' + '$' + responsePrice);
            }
          } else {
            handleShow('null');
          }
        })
        .catch((err) => {
          handleShow('null');
        });
    }
    trackPromise(getprice());
  }, []);

  //VARIABLES
  // const link="https://pricecheck.ccmllc.com:3002/api/price";
  const [retailPrice, setRetailPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [discountpercent, setDiscountPercent] = useState(0);
  const [msg, setMsg] = useState(null);
  const [responsePrice, setResponsePrice] = useState(null);
  //FUNCTIONS
  const queryString = decodeURIComponent(window.location.search);

  const urlParams = new URLSearchParams(queryString);
  var message1 = (
    <p>
      Our prices are always changing 
      to give you the best deals! 
      Please ask an associate for assistance. 
      Make sure that you’ve selected 
      the store you are currently shopping 
      in for the most accurate prices.
    </p>
  );
  var message2 = <p>Prices are as marked!</p>;
  const [price, setPrice] = useState(parseFloat(urlParams.get('price')).toFixed(2));
  var productname = urlParams.get('product_name');
  var store = urlParams.get('name');
  var state = urlParams.get('state');
  var isBarcode = urlParams.get('isBarcode') === 'true';
  var barcodeID = urlParams.get('barcode_id');
  var scanHistoryID = urlParams.get('scanHistoryID');
  var label_string = urlParams.get('label_string');
  var data = {
    store_id: urlParams.get('store_id'),
    stock2CatID: urlParams.get('stock2cat'),
    category: null,
    barcodeID: barcodeID,
    isBarcode: isBarcode,
    scanHistoryID: scanHistoryID,
    BarcodeLabel: label_string,
    method: 'web',
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    scanAgain();
  };
  const handleShow = (msg) => {
    switch (msg) {
      case 'discount':
        setMsg(message2);
        break;
      case 'null':
        setMsg(message1);
        break;
    }
    setShow(true);
  };

  const scanAgain = () => {
    history.push('/scan');
  };

  return (
    <>
      <div className="clearfix"></div>
      <div className="container main-wrap">
        <div class="form-wrapscan">
          <div className="collapsible_text">
            <h5>
              {store}, {state}
            </h5>
          </div>
        </div>
        {/* <div className="checkbox-wrap"><i className="fa fa-arrow-left right"></i></div> */}
        <div className="heading-wrap">
          <h2>Check Price</h2>
        </div>
        <div className="form-wrap">
          <div className="col-sm-12 center">
            <div id="circleDiv" className="circle">
              {discountpercent != null ? (
                discountpercent > 0 ? (
                  discountpercent + '% off!'
                ) : (
                  ' '
                )
              ) : (
                <>
                  <i className="fa fa-solid fa-tags"></i>
                  <br />
                  {responsePrice}
                </>
              )}
            </div>
            <h3>{ productname.toLocaleUpperCase()}</h3>
          
          </div>
          <div id="pricePanel" className="">
            <div className="row pricetag">
              <div className="col-md-3"></div>
              <div className="col-md-6 ">
                <div>
                  <span className="youpay">
                    Retail Price
                    <br />
                    <span className="retail"> {discountpercent > 0 ? '$' + price : ' '}</span>
                  </span>
                  <span className="retailprice">
                    You Pay
                    <br />
                    <span className="pay"> {discountpercent > 0 ? '$' + discountPrice : ' '}</span>
                  </span>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
          <div id="alreadyDiscountedPricePanel" className="">
            <div className="">
              <div className="col-md-3"> </div>
              <div className="col-md-6 sameprice">
                <span className="">
                  You Pay
                  <br />
                  <span className="pay"> {discountpercent === 0 ? '$' + price : '$' + price}</span>
                </span>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        
          <div className="col-sm-12 center">
            <button onClick={scanAgain} className="btn redbtn  submit-button">
              scan another item
            </button>
          </div>
          <div className="col-sm-12 center">
            <Share label="Share this deal!" title="Deal" text="deal" link={window.location.href}></Share>
          </div>
        </div>
        <div>
          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <div>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modalcontent">
                      <div className="message-img"></div>
                      <br />
                      <h3>Sorry!</h3>
                      {msg}

                      <div className="modalbutton">
                        <button type="submit" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} className="btn redbtn  submit-button">
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="top-pad"></div>
        {localStorage.getItem('user_token') ? (
          <></>
        ) : (
          <>
            <div className="main-sign-wrap">
              <div className="sign-wrap">
              {(localStorage.getItem("store_type")==("DC")||localStorage.getItem("store_type")=="TH")?<h3>Sign up for alerts & loyalty rewards!</h3>:
                <h3>Sign up for alerts & more!</h3>}
                <button className="btn yellowbtnsmall  submit-button" onClick={() => history.push('/loyalty')}>
                  Click here
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PriceCheck;
