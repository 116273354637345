import {useHistory} from 'react-router-dom';
import React, {useEffect} from 'react';
import * as Links from '../../Utils/Links';
const Terms = () => {
  const history = useHistory();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  return (
    <>
      <div className="clearfix"></div>
      <div className="container main-wrap">
        <div className="heading-wrap">
          <h2>
            Dirt Cheap Alerts <br></br>Terms and Conditions
          </h2>
        </div>
        <div className="col-md-12">
          <div className="terms-wrap">
            <p>
              By using Dirt Cheap Alerts, you agree to comply with these Terms. Dirt Cheap/Treasure Hunt may update these Terms or make any changes to
              our application at any time without notice. If at any time, you do not accept all of these Terms & Conditions, you must immediately stop
              using the Site. If there is a difference in the price displayed in the app and the price displayed at the register, the register price
              or price given by management will be final.
            </p>

            <h3>License and Access</h3>
            <p>
              Dirt Cheap/Treasure Hunt grants you a limited license to access and make personal use of the Site and the Content for noncommercial
              purposes only and only to the extent such use does not violate these Terms & Conditions including, without limitation, the prohibitions
              below.
            </p>
            <p>
              You may download, print, and copy Content for personal, noncommercial purposes only, provided you do not modify or alter the Content in
              any way, delete, or change any copyright notice or violate these Terms & Conditions in any way. Accessing, downloading, printing,
              posting, storing, or otherwise using the Site or any of the Content for any commercial purpose, whether on behalf of yourself or a third
              party, constitutes a material breach of these Terms & Conditions. This app is intended for use by individuals 13 years of age or older.
              If you are under 18, you may only use this app with involvement of a parent or guardian.
            </p>
            <p>
              Dirt Cheap/Treasure Hunt makes a conscientious effort to describe and display product information accurately. Despite these efforts,
              items may be mislabeled, mispriced, discounted incorrectly, calculated incorrectly, or otherwise have an incorrect total price shown. We
              may experience delays in updating this information electronically or in store. As a result, we cannot and do not guarantee the accuracy
              or completeness of any information displayed within the app, including prices and availability. If there is a difference in the price
              displayed in the app and the price displayed at the register, the register price or price given by management will be final. Only store
              managers and district managers have authority to override prices at store level.
            </p>
            <p>
              Customers may be eligible for an additional 10% savings on applicable days by meeting certain criteria. Active, reserve, and retired
              military members, veterans and dependents with valid military identification will receive an additional 10% off on Mondays. Those over
              age 60 receive an additional 10% off on Tuesdays.
            </p>
            <p>
              These discounts are subject to cashier verification at register. If discounts are selected in app and customers do not meet
              requirements, discounts will not be given at the register.
            </p>
            <p>
              Dirt Cheap/Treasure Hunt reserves the right to change or update information and to correct errors, inaccuracies, or omissions at any
              time without prior notice. We apologize for any inconvenience.
            </p>

            <h3>Your account</h3>
            <p>
              You have the option to register with Dirt Cheap/Treasure Hunt to manage email subscription alerts. Registration is not required to
              utilize features of the app. You may unsubscribe from alerts at any time by clicking “Manage Subscription” within the app.
            </p>
            <p>
              If you elect to register for merchandise alerts, we may collect your personal information, limited to name, email address, and mobile
              phone number; frequently shopped stores, as selected by the user; and categories of merchandise, as selected by the user. This
              information is only used to send marketing communication regarding products and promotions. An email address is required to register for
              merchandise alerts.
            </p>

            <h3>Dirt Cheap Text Messaging: SMS Terms of use </h3>

            <p>
              Dirt Cheap sends text messages alerting customers of store inventory, sales, and special promotions. In order to receive text messaging
              you must opt-in on our home page <a href={Links.home}>{Links.home}</a> and indicate you agree to receive text message alerts.{' '}
            </p>
            <p>
              For help or information at any time:
              <ul>
                <li>Text “HELP” to 28196</li>
                <li>
                  Visit <a href={Links.home + 'contact/'}>{Links.home + 'contact/'}</a>{' '}
                </li>
                <li>
                  Email <a href={'mailto:support@' + Links.domainName + '.com'}>{'support@' + Links.domainName + '.com'}</a>
                </li>
              </ul>
            </p>
            <p>
              To discontinue receiving messages (opt-out) send <strong>"STOP"</strong> to 28196 at any time. We will reply with a one-time opt-out
              confirmation message, then you will no longer receive messages from us. To resume messages, text <strong>"UNSTOP".</strong>
            </p>
            <p>
              Standard message and data rates may apply. Message frequency is based on account settings. The most common message frequency is 2-4
              messages per week.{' '}
            </p>
            <p>
              Messages may not be delivered to you if your phone is not in range of a transmission site, or if sufficient network capacity is not
              available at a particular time. Even within a coverage area, factors beyond the control of your wireless carrier may interfere with
              message delivery, including the customer's equipment, terrain, proximity to buildings, foliage, and weather. You acknowledge that urgent
              alerts may not be timely received and that your wireless carrier does not guarantee that alerts will be delivered. By participating in
              the Dirt Cheap Alerts program, you are agreeing to the terms and conditions presented here. You can view the Dirt Cheap Privacy Policy
              here: <a href={Links.home + 'privacy/'}>{Links.home + 'privacy/'}</a> .
            </p>

            <h3>Privacy</h3>
            <p>
              Deal Finder does access the user's camera to submit QR code information for price checking. Photos are not stored by Dirt Cheap and are
              only used for the purpose of scanning QR codes.
            </p>
            <p>
              Individually identifiable information will not be released without that individual's prior consent. Visitors may be asked for
              individually identifiable information when signing up to use certain Dirt Cheap and Treasure Hunt services. In some instances, users may
              be asked for personal interest and demographic information in order to create a more customized user experience; however these fields
              will generally be optional. From time to time, Dirt Cheap and Treasure Hunt and/or its partners may contact individual users regarding
              community news or to make promotional offers.
            </p>
            <p>
              If you elect to register for merchandise alerts, we may collect your personal information, limited to name, email address, and mobile
              phone number.
            </p>

            <h3>User Content</h3>
            <p>
              We expect all content submitted by you to comply with our terms below and with Facebook’s Statements of Rights and Responsibilities or
              Twitter’s Terms of Service. If you are employed or otherwise compensated by Dirt Cheap/Treasure Hunt, you must show your connection to
              Dirt Cheap/Treasure Hunt and disclose your relationship when posting any content with regard to Dirt Cheap/Treasure Hunt.
            </p>

            <h3>Intellectual Property</h3>
            <p>
              Dirt Cheap/Treasure Hunt owns all rights in the product, images, and descriptions posted within Deal Finder. Dirt Cheap, Treasure Hunt,
              Deal Finder, and logos are trademarks of Channel Control Merchants, LLC in the United States and other countries. You are not authorized
              to use any of the above trademarks, products, or other images posted by Dirt Cheap/Treasure Hunt within Deal Finder or anywhere else.
            </p>
            <p>
              All content included, such as text, graphics, logos, images, and other material (“Content”) is owned or licensed by Dirt Cheap/Treasure
              Hunt or its suppliers and is protected by copyright, trademark patent, or other property rights.{' '}
            </p>
            <p>
              The collection, arrangement, and assembly of all Content is the exclusive property of Channel Control Merchants, LLC. Dirt
              Cheap/Treasure Hunt and suppliers expressly reserve all intellectual property rights in all Content.
            </p>

            <h3>Limitation of liability</h3>
            <p>
              Under no circumstances shall Dirt Cheap/Treasure Hunt or its employees, directors, officers, or agents be liable for any direct or
              indirect losses or damages arising out of or in connection with your use of or inability to use the app. This is a comprehensive
              limitation of liability that applies to all losses and damages of any kind, whether in contract or tort, even if Dirt Cheap/Treasure
              Hunt has been advised of or should have known of the possibility of such damage. Each provision of these terms and conditions that
              provides for a limitation of liability, disclaimer of warranties or exclusion of damages is to allocate the risk under the agreement
              with you and Dirt Cheap/Treasure Hunt. This allocation is an essential element of the basis of the bargain between you and Dirt
              Cheap/Treasure Hunt. The limitations in this section will apply even if any limited remedy fails of its essential purpose(s). If you are
              dissatisfied with the app, any content, or these terms & conditions, your sole and exclusive remedy is to discontinue using the app. You
              acknowledge, by your use of the site, that your use of the app is at your sole risk. Applicable law may not allow the limitation of
              liability set forth above, so this limitation of liability may not apply to you, and you may have additional rights to those contained
              herein.
            </p>

            <h3>Indemnification and Defense</h3>
            <p>
              As a condition of the use of the app, you agree to defend, indemnify, and hold harmless Dirt Cheap/Treasure Hunt and its respective
              employees, directors, officers, agents, vendors, and suppliers from and against any liabilities, losses, investigations, inquiries,
              claims, suits, damages, costs, and expenses (including, without limitation, reasonable attorneys’ fees and expenses) arising out of or
              otherwise relating to your use of the Site, including without limitation any User Content submitted by you and Claims alleging facts
              that if true would constitute a breach by you of these Terms & Conditions.
            </p>

            <h3>Contact Us</h3>
            <p>
              If you have any concerns about Dirt Cheap Alerts or use of the application, please contact us at{' '}
              <a href={'mailto:support@' + Links.domainName + '.com'}> {'support@' + Links.domainName + '.com'}</a> with a detailed description, and
              we will try to resolve it.
            </p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
