import axios from 'axios';
import bcrypt from 'bcryptjs';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {profileActions} from '../../Store/profileSlice';
import {signUpActions} from '../../Store/signUpSlice';
import * as API from '../../Utils/Global.js';

const ChangePassword = (event) => {
  const history = useHistory();
  const user_token = localStorage.getItem('user_token');

  useEffect(() => {
    if (user_token == '' || user_token == null) {
      history.push('/signin');
    }
  });
  const dispatch = useDispatch();
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confPass, setConfPass] = useState('');
  const [show, setShow] = useState(false);

  const changePass = (event) => {
    event.preventDefault();
    const salt = bcrypt.genSaltSync(10);
    const oldHash = bcrypt.hashSync(oldPass.toString(), salt);
    const newHash = bcrypt.hashSync(newPass.toString(), salt);

    if (newPass != confPass) {
      toast.warning('Confirm Password is not matched', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      return;
    }

    var form = {
      UserId: '18',
      EmailAddress: '',
      OldPassHash: oldHash,
      NewPassHash: newHash,
    };

    // fetch(config.changePassword, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(form),
    // })
    //   .then((resp) => {
    //     console.log(resp);
    //   })
    //   .catch((err) => console.log(err));

    axios
      .post(
        API.changePassword,
        {
          UserID: localStorage.getItem('user_id'),
          OldPassHash: oldPass,
          NewPassHash: newPass,
          store_type: localStorage.getItem('store_type'),
        },
        {
          headers: {
            'content-type': 'application/json',
            'x-auth-token': localStorage.getItem('user_token'),
          },
        },
      )
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.msg, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
        } else {
          setShow(true);
        }
      })
      .catch((err) => {
        toast.error(err.request.responseText, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };
  const handleClose = () => {
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_firstname');
    dispatch(signUpActions.reset());
    dispatch(profileActions.reset());
    history.push('/signin');
  };

  return (
    <div>
      <div className="clearfix"></div>
      <div className="sign-in-wrap mt-5">
        <div className="chicken_head_white"></div>
        <div className="dc_logo_signin"></div>
      </div>

      <div className="container main-wrap">
        <div className="">
          <div className="col-md-12">
            <div className="signin-wrap">
              <div className="heading-wrap">
                <h2>Change Password</h2>
              </div>
              <div className="form-wrap-signin">
                <form onSubmit={changePass}>
                  <div className="">
                    <div className="col-md-12 center">
                      <span className="Password-text">Your new password must be different 
                    from previous used passwords.
                    Must be at least 8 characters.</span>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Old Password"
                        onChange={(e) => setOldPass(e.target.value)}
                        required
                        minLength={8}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        onChange={(e) => setNewPass(e.target.value)}
                        required
                        minLength={8}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        onChange={(e) => setConfPass(e.target.value)}
                        required
                        minLength={8}
                      />
                    </div>

                    <div className="col-md-12 center">
                      <button className="btn redbtn  submit-button" type="submit">
                        Change Password
                      </button>{' '}
                    </div>
                    <div className="col-md-12"></div>
                  </div>
                  {/* <!--Modal start--> */}
                  <Modal show={show} backdrop="static" keyboard={false}>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby=" " aria-hidden="true"></div>
                  </Modal>
                  <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    
                    <div className="modal-dialog">
                      
                      <div className="modal-content">
                        <div className="modal-body">
                          <div className="modalcontent">
                            <div className="success"></div>
                            <br />
                            <h2 className="modal-heading">Success!</h2>
                            <p className="reset-text">Your password has been updated</p>
                            <br />
                            <div className="modalbutton">
                              <button type="submit" className="btn redbtn" onClick={handleClose}>
                                SIGN IN
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>

                  {/* <!--Modal End--> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
