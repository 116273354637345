import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  fName: '',
  lName: '',
  email: '',
  addr1: '',
  addr2: '',
  phone: '',
  city: '',
  country: '',
  state: '',
  zip: '',
  stores: [],
  pass: '',
  pass2: '',
  cookies: false,
  terms: false,
};

const signUpSlice = createSlice({
  name: 'signUp',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setForm(state, action) {
      switch (action.payload.attr) {
        case 'fName':
          state.fName = action.payload.attrVal;
          break;
        case 'lName':
          state.lName = action.payload.attrVal;
          break;
        case 'email':
          state.email = action.payload.attrVal;
          break;
        case 'addr1':
          state.addr1 = action.payload.attrVal;
          break;
        case 'addr2':
          state.addr2 = action.payload.attrVal;
          break;
        case 'phone':
          state.phone = action.payload.attrVal;
          break;
        case 'city':
          state.city = action.payload.attrVal;
          break;
        case 'country':
          state.country = action.payload.attrVal;
          break;
        case 'state':
          state.state = action.payload.attrVal;
          break;
        case 'zip':
          state.zip = action.payload.attrVal;
          break;
        case 'stores':
          state.stores = action.payload.attrVal;
          break;
        case 'pass':
          state.pass = action.payload.attrVal;
          break;
        case 'pass2':
          state.pass2 = action.payload.attrVal;
          break;
        case 'cookies':
          state.cookies = action.payload.attrVal;
          break;
        case 'terms':
          state.terms = action.payload.attrVal;
          break;
        case 'favStore':
          state.stores = action.payload.attrVal;
          break;
      }
    },
  },
});

export const signUpActions = signUpSlice.actions;
export default signUpSlice;
