import { checkDomain } from ".";
//checkDomain();

export const apiUrl = "https://findprice.ilovedirtcheap.com:3002/api"; //Global

export const store = apiUrl + "/store";
//  +"?store_type=" + localStorage.getItem("store_type");
export const typeStores =
  apiUrl + "/store" + "?store_type=" + localStorage.getItem("store_type");
export const price = apiUrl + "/price";
export const recordScan = apiUrl + "/recordScan";
export const user = apiUrl + "/user";
export const changePassword = apiUrl + "/changePassword";
export const login = apiUrl + "/login";
export const profile = apiUrl + "/user/profile";
export const forgotPassword = apiUrl + "/forgotPassword";
export const resetPassword = apiUrl + "/resetPassword";
export const update = apiUrl + "/user/update";
export const eAlert = apiUrl + "/eAlert";
export const aspectRatio = 0.7;
export const cameraTimeout = 15000;
export const eAlertStores = apiUrl + "/eAlert/store";
export const eAlertCategory = apiUrl + "/eAlert/category";
export const userPref = apiUrl + "/ealert/getUserPref";
export const setUserPref = apiUrl + "/ealert/addUpdatePref";
export const updatePref = apiUrl + "/ealert/sendEalertSubscriber";
export const state = apiUrl + "/state";
export const checkExistingEmail = apiUrl + "/checkemailexist";
export const privacyConsent = apiUrl + "/privacyConsent";
export const deleteUser = apiUrl + "/deleteUser";
export const PurchaseHistoryApi = apiUrl + "/userPurchaseData";
//export const vouchercode = apiUrl + "/voucherCode";
export const loyaltyRewardCode = apiUrl + "/loyalty/getLoyaltyReward";


export const US_STORES = ["DC", "TH", "DCBS"];
export const CANADA_STORES = ["CA"];