import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import * as API from '../../Utils/Global.js';
import axios from 'axios';
import {Modal, Button, Form} from 'react-bootstrap';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  var history = useHistory();
  var queryString = window.location.pathname;
  var token = queryString.substring(queryString.lastIndexOf('/') + 1, queryString.length);
  const [newPass, setNewPass] = useState('');
  const [confPass, setConfPass] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    history.push('/signin');
  };
  const handleShow = () => setShow(true);

  const handleResetPassword = (event) => {
    event.preventDefault();
    if (queryString.includes('token')) {
      if (newPass === confPass) {
        axios
          .post(
            API.resetPassword,
            {
              Token: token,
              PassHash: newPass,
            },
            {headers: {'content-type': 'application/json'}},
          )
          .then((response) => {
            if (response.data.error == true) {
              toast.error(response.data.msg, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            } else {
              if (response.status === 200) {
                handleShow();
              }
            }
          })
          .catch((err) => {
            toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          });
      } else {
        toast.warning('Confirm Password is not matched', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      }
    } else {
      toast.warning('Invalid Password Reset link', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
    }
  };

  return (
    <div>
      <div className="clearfix"></div>
      <div className="sign-in-wrap mt-5">
        <div className="chicken_head_white"></div>
        <div className="dc_logo_signin"></div>
      </div>

      <div className="container main-wrap">
        <div className="">
          <div className="col-md-12">
            <div className="signin-wrap">
              <div className="heading-wrap">
                <h2>Reset Password</h2>
              </div>
              <div className="form-wrap-signin">
                <form onSubmit={handleResetPassword}>
                  <div className="">
                    <div className="col-md-12 center">
                      <span className="Password-text">
                        Your new password must be different from previous used passwords. Must be at least 8 characters.
                      </span>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="password"
                        onChange={(e) => setNewPass(e.target.value)}
                        className="form-control"
                        placeholder="Password"
                        minLength="8"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="password"
                        onChange={(e) => setConfPass(e.target.value)}
                        className="form-control"
                        placeholder="Confirm Password"
                        minLength="8"
                        required
                      />
                    </div>

                    <div className="col-md-12 center">
                      <button className="btn redbtn  submit-button">Reset Password</button>
                    </div>
                    <div className="col-md-12"></div>
                  </div>
                  <div>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                      <div>
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="modalcontent">
                                <div className="modalcontent">
                                  <div className="success"></div>
                                  <br />
                                  <h2>Success!</h2>
                                  <br />
                                  <p className="reset-text">Your password has been updated</p>
                                  <br />
                                  <div className="modalbutton">
                                    <button type="submit" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} className="btn redbtn">
                                      SIGN IN
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
