import axios from 'axios';
import bwipjs from 'bwip-js';
import $ from 'jquery';
import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {trackPromise} from 'react-promise-tracker';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import close from '../../assets/images/close.png';
import {allstoresActions} from '../../Store/allStoresSlice';
import {eAlertActions} from '../../Store/eAlertSlice';
import {profileActions} from '../../Store/profileSlice';
import * as API from '../../Utils/Global.js';
import ProgressBar from 'react-bootstrap/ProgressBar';

let mapped = [];
var strs = [];

const Profile = () => {
    //VARIABLES
    const history = useHistory();
    const dispatch = useDispatch();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var user_id = localStorage.getItem("user_id");
    const GlobalStore = useSelector((state) => state.store);
    const AllStores = useSelector((state) => state.allstores);
    const eAlert = useSelector((state) => state.eAlert);
    const [Name, setName] = useState();
    const [MemberId, setMemberId] = useState("");
    const [vouchercode, setVoucherCode] = useState("");
    const [vouchertext, setVoucherText] = useState("");
    const [verbal, setVerbal] = useState("");
    const [home_store, setHomeStore] = useState("");
    const [homeStoreValue, setHomeStoreValue] = useState(0);
    const [show, setShow] = useState(false);
    const Profile = useSelector((state) => state.profile);
    const [fName, setfname] = useState(Profile.first_name);
    const [lName, setlname] = useState(Profile.last_name);
    const [email, setemail] = useState(Profile.email);
    const [addr1, setaddr1] = useState(Profile.address_1);
    const [addr2, setaddr2] = useState(Profile.address_2);
    const [phone, setphone] = useState(Profile.phone);
    const [country, setcountry] = useState(Profile.country);
    const [tempHomeStoreValue, setTempStoreValue] = useState("");
    const [tempHomeStoreLabel, setTempStore] = useState("");
    const [city, setcity] = useState(Profile.city);
    const [userstate, setstate] = useState(Profile.state);
    const [zip, setzip] = useState(Profile.zip);
    const [spend, setspend] = useState(Profile.spend);
    const [selected, setSelected] = useState([]);
    const [voucherCodesArray, setVoucherCodesArray] = useState([]);
  
    const [favstores, setfavstores] = useState([]);
    //FUNCTIONS
  
    const Update = async () => {
      await fetch(API.updatePref, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("user_token"),
        },
        body: JSON.stringify({
          user_id: localStorage.getItem("user_id"),
        }),
      })
        .then(async (raw) => {})
        .catch((err) => {});
    };
  
    async function getVoucherCode(MemberId) {
      await axios
        .post(
          API.loyaltyRewardCode,
          {
            member_id: MemberId,
            store_type: localStorage.getItem("store_type")
          },
          {
            headers: {
              "content-type": "application/json",
              "x-auth-token": localStorage.getItem("user_token"),
            },
          }
        )
        .then((response) => {
          let voucherArray = [];
  
          for (var i = 0; i < response.data.voucher_code.length; i++) {
            let voucherObj = response.data.voucher_code[i];
            let voucher_code = voucherObj.aztec_code;
            let voucher_text = voucherObj.aztec_code;
  
            voucherArray.push({
              voucherCanvas: "voucher_" + i,
              voucher_code: voucher_code,
              voucher_text: voucher_text,
              voucher_verbal: voucherObj.verbal,
              voucher_expiry: new Date(voucherObj.expiry_date).toLocaleDateString('en-GB', {
                day: '2-digit', month: 'short', year: 'numeric'
              }).replace(/ /g, '-')
            });
          }
  
          setVoucherCodesArray(voucherArray)
          displayVoucher(voucherArray);
        })
        .catch((err) => {});
    }
    useEffect(() => {
      if (AllStores.stores.length == 0) {
        //Getting Stores
  
        let Country = localStorage.getItem("store_type");
        var url = "";
  
        if (Country.toLocaleLowerCase() == "canada") {
          url = API.eAlertStores + "?store_type=CANADA";
        } else {
          url = API.eAlertStores;
        }
        trackPromise(
          $.ajax({
            url: url,
            type: "GET",
            async: false,
            success: function (response) {
              strs = response.stores;
              getStoreList(response.stores);
            },
            error: function (xhr, response) {},
          })
        );
      }
  
      async function getuser() {
        if (localStorage.getItem("user_id")) {
          await axios
            .post(
              API.profile,
              { UserID: localStorage.getItem("user_id") },
              {
                headers: {
                  "content-type": "application/json",
                  "x-auth-token": localStorage.getItem("user_token"),
                },
              }
            )
            .then((response) => {
              var data = response.data[0];
              strs = strs.length == 0 ? AllStores.stores : strs;
              var st = getStoreList(strs, data.state);
              if (data.favStores != null) {
                var favSt = data.favStores.split(",");
                var arr1 = favSt.map((el) => parseInt(el));
                var arr = [];
                for (let i = 0; i < arr1.length; i++) {
                  for (let j = 0; j < st.length; j++) {
                    if (arr1[i] == st[j].value) {
                      arr.push(st[j]);
                    }
                  }
                }
                dispatch(profileActions.setSelected({ selected: arr }));
                setSelected(arr);
              }
              if (data.home_store == 0 || data.home_store == null) {
                setShow(true);
              } else {
                var homeStoreObj = strs.filter((el) => {
                  return el.idd_store_id == data.home_store;
                })[0];
                if (!(homeStoreObj == undefined) || !(homeStoreObj == null)) {
                  setHomeStore(homeStoreObj.name);
                  setHomeStoreValue(homeStoreObj.idd_store_id);
                  setTempStore(homeStoreObj.name);
                  setTempStoreValue(homeStoreObj.idd_store_id);
                }
              }
              getVoucherCode(data.member_id);
              setName(data.first_name + " " + data.last_name);
              setMemberId(data.member_id);
              setfname(data.first_name);
              setlname(data.last_name);
              setemail(data.email);
              setaddr1(data.address_1);
              setaddr2(data.address_2);
              setcity(data.city);
              setcountry(data.country);
              setstate(data.state);
              setzip(data.zip);
              setphone(data.phone);
              setspend(Number((data.total_purchase)%100).toFixed(2));
              localStorage.setItem("user_name", data.first_name);
            })
            .catch((err) => {
              console.log(err);
            });
  
          await axios
            .post(
              API.userPref,
              { user_id: localStorage.getItem("user_id") },
              {
                headers: {
                  "content-type": "application/json",
                  "x-auth-token": localStorage.getItem("user_token"),
                },
              }
            )
            .then((response) => {
              response = response.data;
              var isEmpty = $.isEmptyObject(response);
              if (!isEmpty) {
                var subscriber_categories = [];
                var subscriber_locations = [];
                var isEmpty = $.isEmptyObject(response);
                if (!isEmpty) {
                  if (response.subscriber_categories != "") {
                    var sub_cat = response.subscriber_categories.split(",");
                    sub_cat.map((el) => subscriber_categories.push(parseInt(el)));
                  }
                  if (response.subscriber_locations_email != "") {
                    var sub_loc = response.subscriber_locations_email.split(",");
                    sub_loc.map((el) => subscriber_locations.push(parseInt(el)));
                  }
                }
  
                dispatch(eAlertActions.setPrefId(response.pref_id));
                dispatch(
                  eAlertActions.setEmailPref({ email: response.deliver_email })
                );
                dispatch(eAlertActions.setSmsPref({ sms: response.deliver_sms }));
                dispatch(
                  eAlertActions.addCategories({
                    categories: subscriber_categories,
                  })
                );
                dispatch(
                  eAlertActions.setLocations({
                    locations: subscriber_locations,
                  })
                );
              }
              var editPreferences = document.getElementById("editPreferences");
              var eAlertSignUp = document.getElementById("eAlertSignUp");
              if (response.is_sent === 0 || Object.keys(response).length === 0) {
                eAlertSignUp.style.display = "block";
                editPreferences.style.display = "none";
              } else {
                eAlertSignUp.style.display = "none";
                editPreferences.style.display = "block";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          history.push("/signin");
        }
      }
      
      trackPromise(getuser());
    }, []);
  
    function getStoreList(stores, state) {
      var storelist = [];
      for (let i = 0; i < stores.length; i++) {
        // if (stores[i].state == state)
        storelist.push({
          value: stores[i].idd_store_id,
          label: stores[i].name,
        });
      }
      // dispatch(profileActions.setStorelist({ storelist: storelist }));
      storelist = storelist.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
      );
      dispatch(allstoresActions.storeList({ storelist: storelist }));
      dispatch(allstoresActions.homeStores({ homestores: storelist }));
      return storelist;
    }
  
    const addPreferences = () => {
      Update();
      history.push("/preferences");
    };
  
    if (MemberId) {
      try {
        let canvas = bwipjs.toCanvas("mycanvas", {
          bcid: "azteccode", // Barcode type
          text: MemberId,
          scale: 3,
          height: 12,
          includetext: true,
          textxalign: "center",
        });
      } catch (e) {}
    }
  
    function displayVoucher(voucherArray) {
      if (voucherArray.length > 0) {
        try {
          for (var i = 0; i < voucherArray.length; i++) {
            let canvas = bwipjs.toCanvas(voucherArray[i].voucherCanvas, {
              bcid: "azteccode", // Barcode type
              text: voucherArray[i].voucher_code,
              scale: 2,
              height: 18,
              includetext: true,
              textxalign: "center",
            });
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  
    const handleClose = (event) => {
      event.preventDefault();
      if (tempHomeStoreValue !== 0) {
        setShow(false);
        setHomeStore(tempHomeStoreLabel);
        setHomeStoreValue(tempHomeStoreValue);
      }
  
      const form = {
        UserID: localStorage.getItem("user_id"),
        EmailAddress: email,
        FirstName: fName,
        LastName: lName,
        Address1: addr1,
        Address2: addr2,
        Phone: phone,
        City: city,
        State: userstate,
        Country: country,
        ZipCode: zip,
        FavStores: favstores,
        HomeStore: tempHomeStoreValue,
      };
      var response = [
        {
          first_name: fName,
          last_name: lName,
          email: email,
          address_1: addr1,
          address_2: addr2,
          phone: phone,
          city: city,
          state: userstate,
          country: country,
          zip: zip,
          cookie_agreement: Profile.cookie_agreement,
          tnc_agreement: Profile.tnc_agreement,
          member_id: user_id,
          last_login: null,
          home_store: tempHomeStoreValue,
          favStores: favstores,
          storeList: Profile.storeList,
          selected: [],
        },
      ];
      trackPromise(
        fetch(API.update, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        })
          .then((raw) => {
            if (raw.status == 200) {
              dispatch(profileActions.setDet(response));
              toast.success(
                "User Details Updated Successfully",
                { position: toast.POSITION.TOP_CENTER },
                { autoClose: 1000 }
              );
            }
          })
          .catch((err) => {
            toast.error(
              err,
              { position: toast.POSITION.TOP_CENTER },
              { autoClose: 1000 }
            );
          })
      );
      var locations = [
        ...new Set([...[tempHomeStoreValue], ...eAlert.subscriber_locations]),
      ];
      trackPromise(
        fetch(API.setUserPref, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("user_token"),
          },
          body: JSON.stringify({
            user_id: user_id,
            deliver_email: eAlert.deliver_email,
            deliver_sms: eAlert.deliver_sms,
            subscriber_categories: eAlert.subscriber_categories,
            subscriber_locations: locations,
          }),
        })
          .then((raw) => {})
          .catch((err) => {
            console.log(err);
          })
      );
    };
  
    const homeStoreHandler = (event) => {
      if (!event.target.value == "") {
        let value = parseInt(event.target.value);
        let storeObj = AllStores.storelist.filter((el) => {
          return el.value == value;
        });
        setTempStore(storeObj[0].label);
        setTempStoreValue(storeObj[0].value);
        
      }
    };

  return (
    <>
      <div className="clearfix"></div>
      <div className="container main-wrap">
        <div className="heading-wrap">
            <h5>
              <b>
                {GlobalStore.store.name ? GlobalStore.store.name + ', ' : ''}
                {GlobalStore.state ? GlobalStore.state : ''}{' '}
              </b>
            </h5>
        </div>
        {/* <div class=""> */}
        {voucherCodesArray.length > 0 ? (
          <div className="heading-wrap">
            <h3 className="promo_banner">YOU HAVE NEW REWARDS!</h3>
          </div>
        ) : null}
        {/* <div class="promo_banner">
        YOU HAVE A NEW PROMO COUPON!</div>
    </div> */}
        <div className="heading-wrap">
          <h2>Profile</h2>
        </div>

        <div className="qr">
          <canvas id="mycanvas"></canvas>
        </div>
        <div className="">
          {/* <div className="col-md-2"> </div> */}
          <div className="col-md-12 profilebg">
            <div className="profile-wrap row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
            <p>
                <b>Name :</b> {Name}
                <br />
                <b>Member Id :</b> {MemberId}
                <br />
                <b>Home Store : </b>
                {home_store ? home_store : ''}
              </p>
            </div>
<div class="col-md-2"></div>
<div className="center">
              {home_store ? (
                <button className="btn redbtn  submit-button" onClick={() => setShow(true)}>
                  Edit home store
                </button>
              ) : null}
            </div>
            </div>
            
          </div>
          <div className="form-wrap">
            <div className="col-sm-12 center">
              <button className="btn redbtn  submit-button" onClick={() => history.push('edit')}>
                Edit Profile{' '}
              </button>
            </div>
          </div>
          <div className="heading-wrap"></div>
          {localStorage.getItem("store_type")=="CANADA"?<></>:localStorage.getItem("store_type")=="DCBS"?<></>: <>
            <h3 style={{"fontSize":"1.1rem","textAlign":"center","fontWeight":"bold"}} >Progress until you get additional 10% off on a future purchase!</h3>
            <div className="">
            <div className="col-xs-6">
            <ProgressBar style={{fontSize: "16px",fontWeight: "bold"}} label={spend==0?"":"$"+`${spend}`} min={-2} max={100} now={spend}/>
            <range>
              <p style={{fontSize: "16px",fontWeight: "bold",}}>$0</p>
              <a style={{fontSize: "16px",fontWeight: "bold",}}>$100</a>
            </range>
            </div>
            </div>
            </>}
          {voucherCodesArray.length > 0 ? (
            <div className="voucher-wrap">
              <h2>
                <span className="voucherCode">REWARD CODES</span>
              </h2>
              <p className="voucher-text">Only one reward code can be used per transaction.</p>
              <p className="voucher-note-text"> Max. discount up to $100.</p>
            </div>
          ) : null}
          {voucherCodesArray.length > 0
            ? voucherCodesArray.map((code) =>
                code ? (
                  <div key={code.voucherCanvas}>
                    <div className="voucher-profile-wrap">
                      <div key={code.voucherCanvas} className="voucher">
                        <canvas id={code.voucherCanvas.toLocaleLowerCase()}></canvas>
                        </div>
                      <div className="voucher-profile-wrap">
                        <h3 className="verbal">{code.voucher_verbal}</h3>
                      <p className="voucher-note-text">*Excludes flooring</p>
                      <p className="voucher-note-text">Expires On: {code.voucher_expiry}</p>
                      <p className="voucher-note-text">Cannot be combined with any overall discount.</p>
                      </div>
                    </div>
                  </div>
                ) : null,
              )
            : null}
          <div className="heading-wrap ">
            <h2 >EALERT</h2>
          </div>
          <div className="form-wrap">
            <div className="col-sm-12 center">
              <div id="editPreferences">
                <button className="btn redbtn  submit-button" onClick={() => history.push('/preferences')}>
                  EDIT EALERTS{' '}
                </button>
              </div>
              <div id="eAlertSignUp">
                <button className="btn redbtn  submit-button" onClick={addPreferences}>
                  SIGN UP FOR  eALERTS
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div>
          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <div>
              <div className="modal-dialog m-0">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modalcontent">
                      {/* <div
                        style={{
                          float: "right",
                          margin: "none",
                          padding: "none",
                        }}
                      >
                        <h3
                          style={{ color: "black" }}
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <strong>x</strong>
                        </h3>
                      </div> */}
                      {/* <div
                        className="modal-close"
                        onClick={() => {
                          setShow(false);
                        }}>
                        <img src={close} alt="Close" data-bs-dismiss="modal" aria-label="Close" width="16" height="14" />
                      </div> */}
                      {/* <div className="message-img"></div> */}
                      <br />
                      <br />
                      <p className="heading-wrap pb-4">
                        <h3>PLEASE SELECT YOUR HOME STORE</h3>
                      </p>
                      <div
                        className="col-md-12 col-lg-8 col-12 mx-auto"
                        // style={{ padding: "20px" }}
                      >
                        <form onSubmit={handleClose}>
                          <select className="form-select select form-control"  onChange={homeStoreHandler} value={tempHomeStoreValue} required>
                            <option value={''}>What is your home store?*</option>
                            {AllStores.storelist.map((store) => (
                              <option key={store.value} value={store.value}>
                                {store.label}
                              </option>
                            ))}
                          </select>
                          <div className="modalbutton">
                            <br/>
                            <button
                              type="submit"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              // onClick={handleClose}
                              className="btn redbtn">
                              SAVE
                            </button>
                            <br/>
                            <br/>
                            <button className="btn yellowbtn" onClick={() => history.push('/')}>
                    Go to Home
                  </button>
                  <br/>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className="top-pad-store"></div>
        <div className="top-pad"></div>
      </div>
    </>
  );
};

export default Profile;
