import NavList from '../Components/Navigation/NavList';
import Footer from '../Components/Footer/Footer';
import {Route} from 'react-router-dom';
import {usePromiseTracker} from 'react-promise-tracker';
import {ThreeDots} from 'react-loader-spinner';
import {getVoucherCode} from '.';
import {useState, useEffect} from 'react';

const LoadingIndicator = (props) => {
  const {promiseInProgress} = usePromiseTracker();
  return (
    promiseInProgress && (
      <>
        <div
          className="centered"
          style={{
            width: '100%',
            height: '100',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ThreeDots color={localStorage.getItem('color')} height="100" width="100" />
        </div>
      </>
    )
  );
};
const Template = (props) => {
  const [successVoucher, setSuccess] = useState(false);
  useEffect(async () => {
    let member_id = localStorage.getItem('member_id');
    if (member_id) {
      let success = await getVoucherCode(member_id);
      if (success) {
        setSuccess(success);
      } else {
        setSuccess(false);
      }
    } else {
      setSuccess(false);
    }
  });


  return (
    <Route path={props.path} exact={props.exact}>
      <NavList success={successVoucher} />
      <LoadingIndicator />
      <>
      {props.element}
      </>
      <Footer />
    </Route>
  );
};

export default Template;
