import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {Link} from 'react-router-dom';
import * as API from '../../Utils/Global.js';
import {useDispatch, useSelector} from 'react-redux';
import {profileActions} from '../../Store/profileSlice';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {flagActions} from '../../Store/flagSlice';
import * as Links from '../../Utils/Links';
import {getUserDetails} from '../../Utils/index.js';

const SignIn = () => {
  const history = useHistory();
  const flag = useSelector((state) => state.flags);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const user_token = localStorage.getItem('user_token');
  const dispatch = useDispatch();
  switch (document.referrer) {
    case Links.buildingsupplies:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.buildingsupplies));
      break;

    case Links.treasurehunt:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.treasurehunt));
      break;

    case Links.dirtcheap:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.dirtcheap));
      break;

    case Links.treasurehuntcanada:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.treasurehuntcanada));
  }
  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const scroll = () => {
    const element = document.activeElement;
    element.scrollIntoView();
  };

  useEffect(() => {
    if (user_token !== null) {
      history.push('/profile');
    }
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    //this.form.validateAll();
    login(username, password).then(
      (data) => {
        if (data.error == true) {
          toast.error(data.msg, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
        } else {
          fetch(API.profile, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('user_token'),
            },
            body: JSON.stringify({
              UserID: localStorage.getItem('user_id'),
            }),
          })
            .then((resp) => resp.json())
            .then((response) => {
              dispatch(profileActions.setDet(response));
              response = response[0];
              if (
                (response.fromIDD == 2 || response.fromIDD == 1) &&
                (response.updated_date == null || response.updated_date == '' || response.updated_date == undefined)
              ) {
                history.push('/edit');
              } else {
                history.push('/profile');
              }
            })
            .catch((err) => {});
        }
      },
      (error) => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        toast.error(resMessage, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      },
    );
  };

  function login(EmailAddress, PassHash) {
    return axios
      .post(API.login, {
        EmailAddress,
        PassHash,
      })
      .then(async (response) => {
        if (response.data.LoginStatus === 'Success') {
          localStorage.setItem('user_token', response.headers['x-auth-token']);
          localStorage.setItem('user_id', response.data.UserID);
          localStorage.setItem('user_firstname', response.data.UserName);
          await getUserDetails();
        }
        return response.data;
      })
      .catch((err) => {
        toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  }

  return (
    <div>
      <div className="clearfix"></div>
      <div className="sign-in-wrap mt-5">
        <div className="chicken_head_white"></div>
        <div className="dc_logo_signin"></div>
      </div>

      <div className="container main-wrap">
        <div className="">
          <div className="col-md-12">
            <div className="signin-wrap">
              <div className="heading-wrap">
                <h2>sign in</h2>
              </div>
              <div className="form-wrap-signin">
                <form onSubmit={handleLogin}>
                  <div className="">
                    <div className="col-md-12">
                      <input
                        type="email"
                        name="username"
                        onChange={onChangeUsername}
                        className="form-control"
                        required
                        placeholder="Email Address"
                        // onFocus={scroll}
                        // autoComplete="off"
                      />
                    </div>
                    <div className="col-md-12">
                      <input type="password" name="password" onChange={onChangePassword} className="form-control" required placeholder="Password" />
                    </div>
                    <div className="col-md-12">
                      <input type="hidden" />
                    </div>
                    <div className="col-md-12 center">
                      <button
                        className="btn redbtn  submit-button"
                        type="submit"
                        //   onClick={handleLogin}
                      >
                        Sign In
                      </button>
                    </div>
                    <div className="col-md-12">
                      <span className="forgot">
                        {' '}
                        <Link to="/forgotpassword">Forgot Password?</Link>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-12 center">
                <span className="notmember">
                  {' '}
                  Not a member yet? <Link to="/signup">Sign Up</Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
