import {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import close from '../../assets/images/close.png';
import {useDispatch} from 'react-redux';
import {signUpActions} from '../../Store/signUpSlice';

const ExistingEmailModal = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const msg = (
    <div>
      <p>
        User already exists!
        <br />
        Do you want to reset your password?
      </p>
      <br />
    </div>
  );
  const handleClose = () => {
    dispatch(signUpActions.reset());
    history.push('/forgotpassword');
  };
  const closeModal = () => {
    dispatch(signUpActions.setForm({attr: 'email', attrVal: ''}));
    props.setShow(false);
  };
  return (
    <Modal
      show={props.show}
      // onHide={handleClose}
      backdrop="static"
      keyboard={false}>
      <div className="ExistingEmailModal">
      <div className="modal-close" onClick={closeModal}>
            <img src={close} alt="Close" data-bs-dismiss="modal" aria-label="Close" width="16" height="14" />
          </div>
        <div className="modal-dialog">

          <div className="modal-content">
            <div className="modal-body">
              <div className="modalcontent">
                <div className="message-img"></div>
                <br />
                <h6>{msg}</h6>

                <div className="modalbutton">
                  <button type="submit" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} className="btn redbtn">
                    OK
                  </button>
                </div>
                {/* <div >
                    <button type='submit' data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} className="btn yellowbtn">
                      ClOSE
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ExistingEmailModal;
