import {useHistory} from 'react-router-dom';

const Page404 = () => {
  const history = useHistory();
  const homeClick = () => {
    history.push('/');
  };
  return (
    <>
      <div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modalcontent">
                <div className="message-img"></div>
                <br />

                <h1>Oh no!</h1>
                <br />
                <p>
                  <strong>
                    Sorry we couldn't find the page
                    <br />
                    you're looking for
                    <br />
                  </strong>
                </p>
                <div className="modalbutton">
                  <button
                    type="submit"
                    data-bs-dismiss="modal"
                    onClick={homeClick}
                    className="btn mainbtn"
                  >
                    Go to Home Page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
