import {useHistory} from 'react-router-dom';

const Loyalty = () => {
  const history = useHistory();
  const signUp = () => {
    history.push('/signup');
  };
  return (
    <>
      <div className="clearfix"></div>
      <div className="col-sm-12">
        <div className="container main-wrap">
          <div className="heading-wrap ">
           { localStorage.getItem("store_type")=="DC"?<h2> {" Dirt Cheap Rewards!"}
            </h2>:localStorage.getItem("store_type")=="TH"?<h2>
            {"Treasure Hunt Rewards!"} 
            </h2>:<h2>new Loyalty program <br />
              coming soon</h2>}
          </div>
          <div className="form-wrap">
            <div className="col-md-12 center">
              <div className="loyaltyimg"></div>
              {(localStorage.getItem("store_type")==("DC")||localStorage.getItem("store_type")=="TH")?<h3>Sign up for our loyalty rewards program! If you spend $100, you will get an additional 10% off on your future purchase!</h3>:
              <h3> We are preparing the launch of our 
              New Customer Loyalty Program so 
              click below to be among the first to 
              learn the details!</h3>}
              
            </div>
            <div className="col-sm-12 center">
              <button className="btn redbtn submit-button" onClick={signUp}>
                Sign Up Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loyalty;
