import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {storeActions} from '../../Store/storeSlice';
import {flagActions} from '../../Store/flagSlice';
import * as API from '../../Utils/Global.js';
import {useEffect} from 'react';
import {isNullOrUndefined} from 'html5-qrcode/esm/core';
import * as Links from '../../Utils/Links';

const StoreSelect = (props) => {
  const GlobalStore = useSelector((state) => state.store);
  const flag = useSelector((state) => state.flags);
  const dispatch = useDispatch();
  var searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString);
  var store_id = urlParams.get('store_id');
  var store_name = urlParams.get('store_name');
  var user_id = localStorage.getItem('user_id');

  switch (document.referrer) {
    case Links.buildingsupplies:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.buildingsupplies));
      break;

    case Links.treasurehunt:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.treasurehunt));
      break;

    case Links.dirtcheap:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.dirtcheap));
      break;

    case Links.treasurehuntcanada:
      dispatch(flagActions.setIddFlag(true));
      dispatch(flagActions.setIddUrl(Links.treasurehuntcanada));
  }
  useEffect(() => {
    fetch(API.typeStores)
      .then((response) => response.json())
      .then((resp) => {
        dispatch(storeActions.stores({stores: resp.stores}));
        if (!isNullOrUndefined(store_id) || !isNullOrUndefined(store_name)) {
          var store = resp.stores.filter((store) => store.id == parseInt(store_id));
          dispatch(storeActions.stateChange({state: store[0].state}));
          dispatch(storeActions.storeChange({id: store_id, name: store_name}));
          getStoreList(resp.stores, store[0].state);
          history.push('/prescan');
        }
        getStateList(resp.stores);
      })
      .catch((err) => {
        console.log(err);
      });
    if ((GlobalStore.state != '') & (GlobalStore.store.name != '')) {
      history.push('/prescan');
    }
  }, []);

  const history = useHistory();

  //FUNCTIONS
  function getStateList(stores) {
    var states = [];
    for (let i = 0; i < stores.length; i++) {
      if (states.length == 0) {
        states.push(stores[i].state);
      }
      if (states.indexOf(stores[i].state) == -1) {
        states.push(stores[i].state);
      }
    }
    states = states.sort();
    dispatch(storeActions.stateList({statelist: states}));
  }

  function getStoreList(stores, state) {
    var storelist = [];
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].state == state)
        storelist.push({
          id: stores[i].id,
          name: stores[i].name,
        });
    }
    storelist = storelist.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    dispatch(storeActions.storeList({storelist: storelist}));
  }

  const stateChange = (event) => {
    dispatch(storeActions.stateChange({state: event.target.value}));
    dispatch(storeActions.storeChange({id: 0, name: ''}));
    getStoreList(GlobalStore.stores, event.target.value);
  };

  const storeChange = (event) => {
    GlobalStore.stores.forEach((element) => {
      if (element.id == event.target.value) {
        dispatch(storeActions.storeChange({id: element.id, name: element.name}));
      }
    });
  };

  const myFunc = (event) => {
    event.preventDefault();
    if (GlobalStore.state != '' && GlobalStore.store.id != 0) {
      history.push('/prescan');
    } else {
      alert('Please select store details!');
    }
  };

  const signin = () => {
    history.push('/signin');
  };

  return (
    <>
      <div className="clearfix"></div>
      <div className="col-sm-12">
        <div className="container main-wrap">
          <div className="top-pad">
            <div className="heading-wrap">
              <h2>select store</h2>
            </div>
          </div>
          <div className="form-wrap">
            <form onSubmit={myFunc}>
              <div className="row form_inner_wrap">
                <div className="col-md-6 col-sm-6 col-xs-6 ">
                  <select className="form-select form-select-sm" onChange={stateChange} value={GlobalStore.state} required>
                    {/* <option value={""}>Select State</option>
                  {GlobalStore.statelist.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))} */}

                    {localStorage.getItem('store_type') === 'CANADA' ? (
                      <>
                        <option value={''}>Select Province</option>
                        {GlobalStore.statelist.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}{' '}
                      </>
                    ) : (
                      <>
                        <option value={''}>Select State</option>
                        {GlobalStore.statelist.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}{' '}
                      </>
                    )}
                  </select>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <select className="form-select form-select-sm" onChange={storeChange} value={GlobalStore.store.id} required>
                    <option value={''}>Select Store</option>
                    {GlobalStore.storelist.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-12 center">
                <button type="submit" className="btn redbtn  submit-button">
                  Next
                </button>
              </div>

              <div className="col-sm-12 center">
                {user_id ? (
                  <button className="btn yellowbtn  submit-button" onClick={() => history.push('/profile')}>
                    Profile Details
                  </button>
                ) : (
                  <button className="btn yellowbtn  submit-button" onClick={signin}>
                    Sign In
                  </button>
                )}
              </div>
            </form>
          </div>

          <div className="top-pad"></div>
          {localStorage.getItem('user_token') ? (
            <></>
          ) : (
            <>
              <div className="main-sign-wrap">
                <div className="sign-wrap">
                {(localStorage.getItem("store_type")==("DC")||localStorage.getItem("store_type")=="TH")?<h3>Sign up for alerts & loyalty rewards!</h3>:
                <h3>Sign up for alerts & more!</h3>}
                  <button className="btn yellowbtnsmall  submit-button" onClick={() => history.push('/loyalty')}>
                    Click here
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StoreSelect;
