import {useDispatch, useSelector} from 'react-redux';
// import "../../css/eAlert.css";
import * as API from '../../Utils/Global';
import {eAlertActions} from '../../Store/eAlertSlice';
import {Link} from 'react-router-dom';
import EStores from './EStores';

const State = (props) => {
  var idletter = props.idletter;
  var classname = 'collapsed';
  if (props.class != '') {
    classname = '';
  }

  return (
    <div
      id={'pane-' + idletter}
      className={'card tab-pane fade ' + props.tabclass}
      role="tabpanel"
      aria-labelledby={'tab-' + idletter}>
      <div className="card-header" role="tab" id="heading-A">
        <h5 className="mb-0">
          <a
            data-toggle="collapse"
            href={'#collapse-' + idletter}
            aria-expanded="false"
            aria-controls={'collapse-' + idletter}
            className={classname}>
            {props.state}
          </a>
        </h5>
      </div>
      <EStores
        idletter={idletter}
        storelist={props.storelist}
        class={props.class}
        locations={props.locations}
      />
    </div>
  );
};

export default State;
