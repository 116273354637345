import {useDispatch, useSelector} from 'react-redux';
import * as API from '../../Utils/Global';
import {eAlertActions} from '../../Store/eAlertSlice';
import {profileActions} from '../../Store/profileSlice';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import $ from 'jquery';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {trackPromise} from 'react-promise-tracker';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cross from '../../assets/images/close.png';

const Categories = () => {
  const eAlert = useSelector((state) => state.eAlert);
  const Profile = useSelector((state) => state.profile);
  const history = useHistory();
  const dispatch = useDispatch();
  var user_id = window.localStorage.getItem('user_id');
  const [mounted, setMounted] = useState(false);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState();
  const [msgImg, setMsgImg] = useState('success-img');
  const store_type = localStorage.getItem('store_type');
  const store_desc = store_type === 'DC' ? 'Dirt Cheap' : store_type === 'DCBS' ? 'Dirt Cheap' : 'Treasure Hunt';

  var msg1 = (
    <div>
      <h3>Sorry!</h3>
      <p>Could not update preferences!</p>
    </div>
  );
  var msg2 = (
    <div>
      <p>Preferences updated successfully!</p>
    </div>
  );

  if (!mounted) {
    if (eAlert.pref_id == 0) {
      $.ajax({
        url: API.userPref,
        type: 'POST',
        async: false,
        accepts: 'application/json',
        contentType: 'application/json',
        data: JSON.stringify({
          user_id: user_id,
        }),
        headers: {
          'x-auth-token': localStorage.getItem('user_token'),
        },
        success: function (response) {
          var subscriber_categories = [];
          var subscriber_locations = [];

          if (response.subscriber_categories != '') {
            var sub_cat = response.subscriber_categories.split(',');
            sub_cat.map((el) => subscriber_categories.push(parseInt(el)));
          }
          if (response.subscriber_locations_email != '') {
            var sub_loc = response.subscriber_locations_email.split(',');
            sub_loc.map((el) => subscriber_locations.push(parseInt(el)));
          }
          var arr1 = Profile.favStores.map((el) => parseInt(el));

          var union = [...new Set([...subscriber_locations, ...arr1])];

          // arr1.map((el) => subscriber_locations.push(el));
          var deliver_sms = response.deliver_sms;
          var deliver_email = response.deliver_email;

          dispatch(eAlertActions.setPrefId(response.pref_id));
          dispatch(eAlertActions.setEmailPref({email: deliver_email}));
          dispatch(eAlertActions.setSmsPref({sms: deliver_sms}));
          dispatch(eAlertActions.addCategories({categories: subscriber_categories}));
          dispatch(eAlertActions.setLocations({locations: union}));
        },
        error: function (xhr, response) {
          console.log(xhr);
          console.log(response);
        },
      });
    }
  }

  useEffect(() => {
    setMounted(true);

    window.scrollTo(0, 0);
  }, []);
  const checkAll = () => {
    var subscriber_categories = [];
    eAlert.categories.forEach((element) => {
      subscriber_categories.push(element.id);
    });
    fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: eAlert.deliver_email,
        deliver_sms: eAlert.deliver_sms,
        subscriber_categories: subscriber_categories,
        subscriber_locations: eAlert.subscriber_locations,
      }),
    })
      .then((raw) => {
        $('input:checkbox').prop('checked', true);
        dispatch(eAlertActions.addCategories({categories: []}));
        dispatch(eAlertActions.addCategories({categories: subscriber_categories}));
      })
      .catch((err) => {
        toast.error(err.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };
  const uncheckAll = () => {
    var subscriber_categories = [];

    fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: eAlert.deliver_email,
        deliver_sms: eAlert.deliver_sms,
        subscriber_categories: subscriber_categories,
        subscriber_locations: eAlert.subscriber_locations,
      }),
    })
      .then((raw) => {
        $('input:checkbox').prop('checked', false);
        dispatch(eAlertActions.addCategories({categories: subscriber_categories}));
      })
      .catch((err) => {
        toast.error(err.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };

  useEffect(() => {
    if (user_id == null || user_id == '') {
      history.push('/signin');
    }
  });

  const handleClose = () => {
    setShow(false);
    history.push('/profile');
  };

  const Update = async () => {
    await fetch(API.updatePref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then(async (raw) => {
        var response = await raw.json();
        setMsg(response.msg);
        if (response.status == 'success') {
          setMsgImg('success-img');
        } else {
          setMsgImg('error-img');
        }
        setShow(true);
        dispatch(profileActions.reset());
      })
      .catch((err) => {
        setMsg(msg1);
        setShow(true);
        console.log(err);
      });
  };

  const unsubscribe = async () => {
    await fetch(API.updatePref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        unsubscribe: true,
      }),
    })
      .then(async (raw) => {
        var response = await raw.json();
        setMsg(response.msg);
        if (response.status == 'success') {
          setMsgImg('success-img');
        } else {
          setMsgImg('error-img');
        }
        setShow(true);
        dispatch(profileActions.reset());
      })
      .catch((err) => {
        setMsg(msg1);
        setShow(true);
        console.log(err);
      });
  };

  const getProfileDetails = () => {
    $.ajax({
      url: API.profile,
      type: 'POST',
      async: false,
      accepts: 'application/json',
      contentType: 'application/json',
      data: JSON.stringify({
        UserID: user_id,
      }),
      headers: {
        'x-auth-token': localStorage.getItem('user_token'),
      },
      success: function (response) {
        var locations = [];
        if (response[0].favStores != null) {
          var ar = response[0].favStores.split(',');
          ar.map((el) => locations.push(parseInt(el)));
        }
        dispatch(eAlertActions.setLocations({locations: locations}));
        setResponse(response);
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });

    $.ajax({
      url: API.userPref,
      type: 'POST',
      async: false,
      accepts: 'application/json',
      contentType: 'application/json',
      data: JSON.stringify({
        user_id: user_id,
      }),
      headers: {
        'x-auth-token': localStorage.getItem('user_token'),
      },
      success: function (response) {
        var subscriber_categories = [];
        var subscriber_locations = [];

        if (response.subscriber_categories != '') {
          var sub_cat = response.subscriber_categories.split(',');
          sub_cat.map((el) => subscriber_categories.push(parseInt(el)));
        }
        if (response.subscriber_locations_email != '') {
          var sub_loc = response.subscriber_locations_email.split(',');
          sub_loc.map((el) => subscriber_locations.push(parseInt(el)));
        }

        var deliver_sms = response.deliver_sms;
        var deliver_email = response.deliver_email;
        dispatch(eAlertActions.setEmailPref({email: deliver_email}));
        dispatch(eAlertActions.setSmsPref({sms: deliver_sms}));
        dispatch(eAlertActions.addCategories({categories: subscriber_categories}));
        dispatch(eAlertActions.setLocations({locations: subscriber_locations}));
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
  };
  const setResponse = (response) => {
    dispatch(profileActions.setDet(response));
  };
  if (Profile.email == '') {
    getProfileDetails();
  }

  const categoryCheck = (e, id) => {
    var categories = [...eAlert.subscriber_categories];
    if (e.target.checked) {
      categories.push(id);
    } else {
      const index = categories.indexOf(id);
      if (index > -1) {
        categories.splice(index, 1);
      }
    }
    dispatch(eAlertActions.addCategories({categories: categories}));
    fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: eAlert.deliver_email,
        deliver_sms: eAlert.deliver_sms,
        subscriber_categories: categories,
        subscriber_locations: eAlert.subscriber_locations,
      }),
    })
      .then((raw) => {})
      .catch((err) => {
        var cats = [...categories];
        const index = cats.indexOf(id);
        if (!e.target.checked) {
          cats.push(id);
          $('#' + id).prop('checked', true);
        } else {
          if (index > -1) {
            cats.splice(index, 1);
          }
          $('#' + id).prop('checked', false);
        }
        dispatch(eAlertActions.addCategories({categories: cats}));
        toast.error(err.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };

  if (eAlert.categories.length == 0) {
    fetch(API.eAlertCategory, {})
      .then(async (response) => {
        response = await response.json();
        response = response.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
        dispatch(eAlertActions.setCategories({categories: response}));
      })
      .catch();
  }
  return (
    <div>
      <div className="clearfix"></div>
      <div className="container main-wrap">
        <div className="heading-wrap mb-4">
        <h2 className='ealert'>eAlert Preferences</h2>
        </div>
        <div className="container">
          <div className="card-header1">Select your Alert categories</div>
          <div className="card-body1">
            <div className="row">
              <div className="col-am-12">
                <p className="para">Use the options below to configure what eAlerts you receive by text message and/or email.</p>
                <ul id="category-list" className="list-check">
                  {eAlert.categories.map((category) => (
                    <li className="list">
                      <input
                        type="checkbox"
                        className="checkbox"
                        id={category.id.toString()}
                        defaultChecked={eAlert.subscriber_categories.indexOf(category.id) != -1}
                        onChange={(e) => {
                          categoryCheck(e, category.id);
                        }}
                      />
                      {category.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="form-wrap">
              <div className="col-sm-12 center">
                <button className="btn redbtnsmall  submit-button spacer" onClick={checkAll}>
                  select all
                </button>
                <button className="btn yellowbtnsmall  submit-button spacer" onClick={uncheckAll}>
                  cancel all
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="alert-head">
                <h3>Save your settings!</h3>
              </div>
              <div className="col-sm-12 center">
                {' '}
                <button
                  className="btn redbtn  submit-button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    trackPromise(Update());
                  }}>
                  update Preferences
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="alert-head">
                <h3>Not interested any more?</h3>
              </div>
              <div className="col-sm-12 center">
                {' '}
                <button
                  className="btn yellowbtn  submit-button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    trackPromise(unsubscribe());
                  }}>
                  Unsubscribe Instantly
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="alerttext">
              <p>
                {store_desc} sends text messages alerting customers of store inventory, sales, and special promotions.
                <br />
                <br /> Standard message and data rates may apply. Message frequency is based on account settings. The most common message frequency is
                2-4 messages per week.
              </p>
              <p>
                <Link to="/privacy">Privacy Policy</Link>{' '}
              </p>
              <p>
                <Link to="/terms">Terms and Conditions</Link>{' '}
              </p>
            </div>
          </div>
          <div className="top-pad">&nbsp;</div>
        </div>
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>

            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="modalcontent">
                    <div className={msgImg}></div> <br />
                    <h3>{msg}</h3>
                    <div className="modalbutton">
                      <button onClick={handleClose} type="submit" data-bs-dismiss="modal" aria-label="Close" className="btn redbtnsmall">
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </Modal>
      </div>
    </div>
  );
};

export default Categories;
