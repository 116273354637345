import {useEffect, useState} from 'react';

const HealthCheck = () => {
  const [success, setSuccess] = useState('Success');
  // useEffect(()=>{
  // });

  return <></>;
};

export default HealthCheck;
