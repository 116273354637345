import menu from '../../assets/images/menu.png';
import location from '../../assets/images/location.png';
import menu_TH from '../../assets/images/TH/menu.png';
import location_TH from '../../assets/images/TH/location.png';
import menu_DCBS from '../../assets/images/DCBS/menu.png';
import location_DCBS from '../../assets/images/DCBS/location.png';
import location_CA from '../../assets/images/TH_CANADA/location.png';
import menu_CA from '../../assets/images/TH_CANADA/menu.png';
import $ from 'jquery';
import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';

import {eAlertActions} from '../../Store/eAlertSlice';
import {profileActions} from '../../Store/profileSlice';
import {signUpActions} from '../../Store/signUpSlice';
import * as API from '../../Utils/Global.js';
import * as Links from '../../Utils/Links';

const NavList = (props) => {
  let domain = localStorage.getItem('store_type');
  const Store = useSelector((state) => state.store);
  const ProfileDetails = useSelector((state) => state.profile);
  const [logo, setLogo] = useState('logo');
  const history = useHistory();
  const user_id = localStorage.getItem('user_id');
  const dispatch = useDispatch();

  useEffect(()=>{
    window.scrollTo(0,0);
  });

  const getDomainMenu = () => {
    switch (domain) {
      case 'TH':
        return menu_TH;
        break;
      case 'DCBS':
        return menu_DCBS;
        break;
      case 'CANADA':
        return menu_CA;
        break;
      default:
        return menu;
        break;
    }
  };

  const getDomainLocation = () => {
    switch (domain) {
      case 'TH':
        return location_TH;
        break;
      case 'DCBS':
        return location_DCBS;
        break;
      case 'CANADA':
        return location_CA;
        break;
      default:
        return location;
    }
  };

  function openNav3() {
    var element = document.getElementById('profilenav-desktop');

    if (element.style.width == '400px') {
      element.style.width = '0px';
    } else {
      element.style.width = '400px';
    }
  }

  function openNav1() {
    var element = document.getElementById('mydropnav');

    if (element.style.width == '100%') {
      element.style.width = '0';
    } else {
      element.style.width = '100%';
    }
  }

  function openNav2() {
    var element = document.getElementById('profilenav');

    if (element.style.width == '100%') {
      element.style.width = '0';
    } else {
      element.style.width = '100%';
    }
  }

  const openNav = () => {
    var isCanada = localStorage.getItem('store_type') === 'CANADA';
    if (!isCanada) {
      document.getElementById('mydropnav').style.width = '0px';
      document.getElementById('mySidenav').style.width = '250px';
    }
  };

  const signout = (event) => {
    event.preventDefault();
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_firstname');
    localStorage.removeItem('member_id');
    localStorage.removeItem('user_name');
    dispatch(signUpActions.reset());
    dispatch(profileActions.reset());
    dispatch(eAlertActions.reset());
    history.push('/signin');
  };
  const Scan = () => {
    history.push('/prescan');
  };

  const purchaseHistory = (event) => {
    event.preventDefault();
    history.push('/purchasehistory');
  };

  const profileDetails = (event) => {
    event.preventDefault();
    history.push('/profile');
  };

  const signUp = (event) => {
    event.preventDefault();
    history.push('/signup');
  };

  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  const handleYes = () => {
    setActive(false);
    $.ajax({
      url: API.deleteUser,
      type: 'POST',
      async: false,
      accepts: 'application/json',
      contentType: 'application/json',
      headers: {
        'x-auth-token': localStorage.getItem('user_token'),
      },
      data: JSON.stringify({
        user_id: user_id,
        email: ProfileDetails.email,
      }),
      success: function (response) {
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_firstname');
        localStorage.removeItem('member_id');
        localStorage.removeItem('user_name');
        dispatch(signUpActions.reset());
        dispatch(profileActions.reset());
        dispatch(eAlertActions.reset());
        history.push('/');
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
  };
  const handleNo = () => {
    setActive(false);
    setShow(true);
  };
  const handleAgree = () => {
    fetch(API.privacyConsent, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: localStorage.getItem('user_id'),
        consent: 1,
      }),
    })
      .then((raw) => {
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDisagree = () => {
    setShow(false);
    setActive(true);
    fetch(API.privacyConsent, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: localStorage.getItem('user_id'),
        consent: 0,
      }),
    })
      .then((raw) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  function checkConsent() {
    let user_token = localStorage.getItem('user_token');
    let user_id = localStorage.getItem('user_id');
    let resp = null;
    if (user_token !== null) {
      $.ajax({
        url: API.privacyConsent + '?user_id=' + user_id,
        type: 'GET',
        async: false,
        accepts: 'application/json',
        contentType: 'application/json',
        headers: {
          'x-auth-token': localStorage.getItem('user_token'),
        },
        success: function (response) {
          if (!response.consent == 1) {
            resp = true;
            return;
          }
          resp = false;
        },
        error: function (xhr, response) {
          console.log(xhr);
          console.log(response);
        },
      });
      return resp;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (Store.store.name === 'BRANTFORD RETAIL') {
      
      var mobile_logo = document.getElementById('mobile_logo');
      var desktop_logo = document.getElementById('desktop_logo');
      mobile_logo.className='logo_wrap_m_ccm';
      desktop_logo.className='logo_wrap_ccm';
    } else {
      var mobile_logo = document.getElementById('mobile_logo');
      var desktop_logo = document.getElementById('desktop_logo');
      mobile_logo.className='logo_wrap_m';
      desktop_logo.className='logo_wrap';
    }

    var z = document.getElementById('mydropnav');
    if (z != null) {
      z.style.width = '0%';
    }

    var y = document.getElementById('topnav');
    if (y != null) {
      y.style.width = '0%';
    }

    var x = document.getElementById('profilenav');
    if (x != null) {
      x.style.width = '0%';
    }

    var w = document.getElementById('profilenav-desktop');
    if (w != null) {
      w.style.width = '0';
    }
    var ProfileDetail = document.getElementById('profileDetail');
    var SignOut = document.getElementById('SignOut');
    var Profile = document.getElementById('profile');

    // var Alert = document.getElementById("Alert");
    var SignIn = document.getElementById('SignIn');
    var SignUp = document.getElementById('SignUp');
    var PurchaseHistory = document.getElementById('purchaseHistory');
    var ProfileDetail_M = document.getElementById('profileDetails_M');
    var SignOut_M = document.getElementById('SignOut_M');
    // var Alert = document.getElementById("Alert");
    var SignIn_M = document.getElementById('SignIn_M');
    var SignUp_M = document.getElementById('SignUp_M');
    var PurchaseHistory_M = document.getElementById('purchaseHistory_M');
    var Profile_M = document.getElementById('profile_m');
    let user_token = localStorage.getItem('user_token');
    if (user_token !== null) {
      ProfileDetail.style.display = 'block';
      SignOut.style.display = 'block';
      if (props.success == true) {
        Profile.className = 'profile-voucher dropdown';
        Profile_M.className = 'profile-voucher dropdown';
      } else {
        Profile.className = 'profile dropdown';
        Profile_M.className = 'profile dropdown';
      }
      // Alert.style.display = 'block';
      PurchaseHistory.style.display = 'block';
      SignIn.style.display = 'none';
      SignUp.style.display = 'none';
      SignUp.style.display = 'none';
      ProfileDetail_M.style.display = 'block';
      SignOut_M.style.display = 'block';
      //Alert.style.display = "block";
      PurchaseHistory_M.style.display = 'block';
      SignIn_M.style.display = 'none';
      SignUp_M.style.display = 'none';
      SignUp_M.style.display = 'none';

      if (localStorage.getItem('store_type') === 'CANADA') {
        if (!active && !show) {
          let consentResult = checkConsent();
          setShow(consentResult);
        }
      }
    } else {
      SignIn.style.display = 'block';
      SignUp.style.display = 'block';
      SignOut.style.display = 'none';
      //Alert.style.display = "none";
      PurchaseHistory.style.display = 'none';
      ProfileDetail.style.display = 'none';
      SignIn_M.style.display = 'block';
      SignUp_M.style.display = 'block';
      SignOut_M.style.display = 'none';
      //Alert.style.display = "none";
      PurchaseHistory_M.style.display = 'none';
      ProfileDetail_M.style.display = 'none';
      Profile.className = 'profile-disable dropdown';
      Profile_M.className = 'profile-disable dropdown';
    }
  });

  return (
    <>
      <div id="desktop">
        <div id="desktop_logo" className="logo_wrap" data-toggle="tooltip" title="Change Store" onClick={openNav}></div>
        <div className="top_wrap">
          <div className="topnav" id="myTopnav">
            <ul>
              <li>
                <a href={Links.home}>Home</a>
              </li>
              <li>
                <a href={Links.about}>About Us</a>
              </li>
              <li>
                <a href={Links.whatsinstore}>What's In Store</a>
              </li>
              <li>
                <a href={Links.locations}>Locations</a>
              </li>
              <li>
                <a href={Links.careers}>Careers</a>
              </li>
              <li>
                <a href={Links.contact}>Contact</a>
              </li>
            </ul>
          </div>
          <div className="Top-NavScan" onClick={Scan}>
            Scan
            <span className="scan"></span>
          </div>
          <div className="Top-NavProfile" onClick={openNav3}>
            Profile
            <span id="profile" className=""></span>
          </div>
          <div className="" id="profilenav-desktop">
            <ul className="list-group">
              <li className="list-group-item" id="SignIn" onClick={signout}>
                <a href={"#"}>Sign In</a>
              </li>
              <li className="list-group-item" id="SignUp" onClick={signUp}>
                <a href={"#"}>Sign Up</a>
              </li>
              <li className="list-group-item" id="profileDetail" onClick={profileDetails}>
                <a href={"#"}>Profile Details</a>
              </li>
              <li className="list-group-item" id="purchaseHistory" onClick={purchaseHistory}>
                <a href={"#"}>Purchase History</a>
              </li>
              <li className="list-group-item" id="SignOut" onClick={signout}>
                <a href={"#"}>Sign Out</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="mobile">
        <div className="logo_wrap_m" data-toggle="tooltip" title="Change Store" id="mobile_logo" onClick={openNav}>
          <a  className={logo}></a>
        </div>

        <div className="top_wrap">
          <div className="M-nav-icon" data-toggle="tooltip">
            <a onClick={openNav1}>
              {' '}
              <img src={getDomainMenu()} className="Menu" />
              <p className="Menu-p">Menu</p>
            </a>
          </div>
          <div className="M-nav-profileicon">
            <a href={Links.locations}>
              <img src={getDomainLocation()} className="location" />{' '}
            </a>
            <p>Locations</p>
          </div>

          <div className="dropdown-content" id="profilenav">
            <ul className="list-group">
              <li className="list-group-item" id="SignIn_M" onClick={signout}>
                <a href={"#"}>Sign In</a>
              </li>
              <li className="list-group-item" id="SignUp_M" onClick={signUp}>
                <a href={"#"}>Sign Up</a>
              </li>
              <li className="list-group-item" id="profileDetails_M" onClick={profileDetails}>
                <a href={"#"}>Profile Details</a>
              </li>
              <li className="list-group-item" id="purchaseHistory_M" onClick={purchaseHistory}>
                <a href={"#"}>Purchase History</a>
              </li>
              <li className="list-group-item" id="SignOut_M" onClick={signout}>
                <a href={"#"}>Sign Out</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Top-NavScan" onClick={Scan}>
          <p>Scan</p>
          <span className="scan"></span>
        </div>
        <div className="Top-NavProfile" onClick={openNav2}>
          <p>Profile</p>
          <span id="profile_m" className=""></span>
        </div>
      </div>

      <Modal show={show} onHide={handleAgree} backdrop="static" keyboard={false}>
        <div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modalcontent">
                  <div className="consent">
                    <h3>Informed Consent Statement</h3>
                    <p>
                      "I have read and understood the terms of CCM's{' '}
                      <a href="https://ccmllc.com/privacy/" target="_blank">
                        <b>privacy policy</b>
                      </a>
                      . I hereby provide my informed consent to the collection and use of my personal information for the following purposes in
                      addition to those set out in CCM's privacy policy: to allow CCM to identify me, communicate with me, track my purchases for
                      loyalty benefits, and to manage its relationship with me including providing promotional offers."{' '}
                    </p>
                  </div>
                  <br />
                  <div className="modalbutton">
                    <button type="submit" data-bs-dismiss="modal" aria-label="Agree" onClick={handleAgree} className="btn redbtn  submit-button">
                      I AGREE
                    </button>
                    <button
                      type="submit"
                      data-bs-dismiss="modal"
                      aria-label="Disagree"
                      onClick={handleDisagree}
                      className="btn yellowbtn  submit-button">
                      I DON'T AGREE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={active} onHide={handleYes} backdrop="static" keyboard={false}>
        <div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modalcontent">
                  <div className="consent">
                    <p>"Are you opting out of our Dealfinder Loyalty Program?"</p>
                  </div>
                  <br />
                  <div className="modalbutton">
                    <button type="submit" data-bs-dismiss="modal" aria-label="Yes" onClick={handleYes} className="btn redbtn  submit-button">
                      Yes
                    </button>
                    <button type="submit" data-bs-dismiss="modal" aria-label="No" onClick={handleNo} className="btn yellowbtn  submit-button">
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NavList;
