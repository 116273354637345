import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {storeActions} from '../../Store/storeSlice';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min';
import {Modal, Button, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import * as API from '../../Utils/Global.js';
import {BarcodeReader} from 'dynamsoft-javascript-barcode';
import VideoDecode from '../VideoDecode/VideoDecode';
import '../../dbr'; // import side effects. The license, engineResourcePath, so on.
import {toast} from 'react-toastify';
import * as config from '../../Utils/Global';
import {checkMobileDevice, getStoreName} from '../../Utils';

const Scan = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const GlobalStore = useSelector((state) => state.store);
  const [defaultState, setDefaultState] = useState('');
  const [defaultStore, setDefaultStore] = useState({
    id: 0,
    name: '',
  });
  const [storeList, setStoreList] = useState([]);
  const [show, setShow] = useState(false);
  let timer;
  const [newPrice, setRawPrice] = useState();
  const [price, setPrice] = useState(null);
  const [stock2cat, setS2C] = useState(null);
  const [pName, setPName] = useState();
  const [isBarcd, setBarcd] = useState(false);
  const [msg, setMsg] = useState(null);
  const [barcodeId, setbarcodeId] = useState(null);
  const [scanHistoryId, setscanHistoryId] = useState();
  const [label_string, setLabelString] = useState('');
  const storeName = getStoreName();

  const inputHandler = (event) => {
    var value = parseInt(event.target.value);
    setRawPrice(value);
  };

  var Message1 = (
    <p>
      <h3>Sorry!</h3>
      Make sure to scan a current
      <br /> {storeName} code.
      <br /> Contact a store manager for assistance.
    </p>
  );

  // var Message2 = (
  //   <p>
  //     Please enter a price!
  //     <br />
  //     <input
  //       type="number"
  //       onChange={inputHandler}
  //       className="form-control"
  //       value={newPrice}
  //       min="1"
  //       required
  //     />
  //   </p>
  // );

  var invalidCodeMessage = (
    <p>
      This system scans only AZTEC.
      <br />
      Kindly try to scan a valid AZTEC.
    </p>
  );

  if (GlobalStore.stores.length == 0) {
    fetch(API.store)
      .then((response) => response.json())
      .then((resp) => {
        dispatch(storeActions.stores({stores: resp.stores}));
        getStateList(resp.stores);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // localStorage.setItem()
  let storeId = 0;
  let states = [];
  let storelist = [];

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      if (GlobalStore.state == '' || GlobalStore.store.name == '') {
        history.push('/');
      } else {
        cameraStanby();
        startScanning();
      }
    }

    return () => {
      if (history.action === 'POP') {
        history.replace('/prescan');
      }
      clearTimeout(timer);
    };
  }, [GlobalStore.state, GlobalStore.store.name]);

  useEffect(() => {
    if (!checkMobileDevice()) {
      handleShow('desktop');
    }
  }, []);
  //FUNCTIONS
  function getStateList(stores) {
    var states = [];
    for (let i = 0; i < stores.length; i++) {
      if (states.length == 0) {
        states.push(stores[i].state);
      }
      if (states.indexOf(stores[i].state) == -1) {
        states.push(stores[i].state);
      }
    }
    // dispatch(storeActions.stateList({statelist:states}));
  }

  function getStoreList(stores, state) {
    storelist = [];
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].state == state)
        storelist.push({
          id: stores[i].id,
          name: stores[i].name,
        });
    }
    storelist = storelist.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    setStoreList(storelist);
    // dispatch(storeActions.storeList({storelist:storelist}));
  }

  const sendData = () => {
    var params = encodeURIComponent(
      new URLSearchParams({
        name: GlobalStore.store.name,
        state: GlobalStore.state,
        store_id: GlobalStore.store.id,
        stock2cat: stock2cat,
        price: parseFloat(newPrice),
        product_name: pName,
        isBarCode: isBarcd,
        label_string: label_string,
        scanHistoryID: scanHistoryId,
        barcode_id: barcodeId,
      }),
    );

    history.push({
      pathname: '/product',
      search: '?' + params,
    });
  };

  function cameraStanby() {
    timer = setTimeout(() => {
      history.push('/prescan');
      toast.warning('Camera is on standby!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
    }, config.cameraTimeout);
  }

  const stateChange = (event) => {
    setDefaultState(event.target.value);
    getStoreList(GlobalStore.stores, event.target.value);
  };

  const storeChange = (event) => {
    GlobalStore.stores.forEach((element) => {
      if (element.id == event.target.value) {
        setDefaultStore({
          id: element.id,
          name: element.name,
        });
      }
    });
  };

  const save = (e) => {
    e.preventDefault();
    var content = document.getElementsByClassName('content')[0];
    if (defaultState != '') {
      dispatch(storeActions.stateChange({state: defaultState}));
      dispatch(storeActions.storeList({storelist: storeList}));
      setDefaultState('');
      setStoreList([]);
      content.style.display = 'none';
    }
    if (defaultStore.id != 0) {
      dispatch(
        storeActions.storeChange({
          id: defaultStore.id,
          name: defaultStore.name,
        }),
      );
      setDefaultStore({
        id: 0,
        name: '',
      });
      content.style.display = 'none';
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
    // if (price <= 0 && price != null) {
    //   sendData();
    // }
    // else {
    //cameraStanby();
    if (!checkMobileDevice()) {
      history.push('/profile');
    } else {
      history.push('/prescan');
    }

    //}
  };

  const handleShow = (msg) => {
    clearTimeout(timer);
    switch (msg) {
      case 'null':
        setMsg(Message1);
        break;
      // case "zeroPrice":
      //   setMsg(Message2);
      //   break;
      case 'invalidCodeMessage':
        setMsg(invalidCodeMessage);
        break;
      case 'desktop':
        setMsg(
          <p>
            <strong>
              Visit this page with your mobile <br />
              device to check individual discounts <br />
              on great items in store! <br />
            </strong>
          </p>,
        );
        break;
      default:
    }
    setShow(true);
  };

  const successCallback = async (resText) => {
    clearTimeout(timer);
    var price_T;
    var code_T = null;
    var isBarCode_T = false;
    var barcodeId_T = null;
    var product_T = '';
    var scanHistoryId_T = '';
    setLabelString(resText);
    if (resText.match(/^\d{1,}[$].+[!]([#|&]|\d{1,})/gm)) {
      if (resText.includes('#')) {
        isBarCode_T = true;
        barcodeId_T = resText.split('$')[0];
      } else if (resText.includes('!&')) {
        isBarCode_T = false;
        code_T = resText.split('$')[0];
        if (resText.lastIndexOf('&') === resText.indexOf('&')) {
          barcodeId_T = resText.substring(resText.indexOf('!&') + 2);
        } else {
          barcodeId_T = resText.substring(resText.indexOf('!&') + 2, resText.indexOf('&U'));
        }
      } else {
        isBarCode_T = false;
        code_T = resText.split('$')[0];
        barcodeId_T = null;
      }
      price_T = resText.substring(resText.indexOf('$') + 1, resText.indexOf('!'));
      if (resText.indexOf('&D:') == -1) {
        product_T = '';
      } else {
        product_T = resText.substring(resText.indexOf('&D:') + 3);
      }
      setS2C(code_T);
      setPName(product_T);
      setBarcd(isBarCode_T);
      setPrice(parseFloat(price_T));
      setbarcodeId(barcodeId_T);

      var scanHistoryID = await scanHistory(resText, barcodeId_T, code_T, null, null);
      // if (price_T <= 0) {
      //   handleShow("zeroPrice");
      // } else
      //{
      var params = encodeURIComponent(
        new URLSearchParams({
          name: GlobalStore.store.name,
          state: GlobalStore.state,
          store_id: GlobalStore.store.id,
          stock2cat: code_T,
          price: parseFloat(price_T),
          product_name: product_T,
          isBarcode: isBarCode_T,
          barcode_id: barcodeId_T,
          label_string: resText,
          scanHistoryID: scanHistoryID,
        }),
      );
      history.push({
        pathname: '/product',
        search: '?' + params,
      });
      //}
    } else {
      scanHistory(resText, '', '', 'Invalid aztec code', 'fail');
      handleShow('null');
    }
  };

  const startScanning = async () => {
    //obj = new Html5Qrcode("reader");

    // dispatch(qrActions.setReaderObj({obj:obj}))
    //startScanner(obj, successCallback);
    try {
      await BarcodeReader.loadWasm();
    } catch (ex) {
      alert('Error in starting camera');
      throw ex;
    }
  };

  const scanNow = () => {
    //obj.resume();
  };

  const stopScanning = () => {
    // if (obj != null) {
    //   stopScanner(obj);
    // }
  };

  const scanHistory = async (labelstring, barcodeID, code, message, status) => {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + '.' + today.getMilliseconds();
    var dateTime = date + ' ' + time;
    var data = {
      UserID: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null,
      StoreID: GlobalStore.store.id,
      Stock2catID: code,
      BarcodeLabel: labelstring,
      BarcodeID: barcodeID,
      Message: message,
      Status: status,
      ScanTime: dateTime,
    };
    var resp = await fetch(API.recordScan, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        response = await response.json();
        setscanHistoryId(response.id);
        return response.id;
      })
      .catch((err) => {
        console.log('Failed to add scanning history');
      });
    return resp;
  };

  const display = () => {
    clearTimeout(timer);
    var content = document.getElementsByClassName('content')[0];
    if (content.style.display === 'block') {
      cameraStanby();
      content.style.display = 'none';
      setDefaultState('');
      setStoreList([]);
      setDefaultStore({
        id: 0,
        name: '',
      });
    } else {
      clearTimeout(timer);
      content.style.display = 'block';
    }
  };

  return (
    <>
    <div className="clearfix"></div>
    <div className="col-sm-12">
      <div className="container main-wrap">
        <div className="">
          <div className="collapsible" data-toggle="tooltip" onClick={display} title="Change Store">
            {GlobalStore.store.name}, {GlobalStore.state} <i className="fa fa-solid fa-angle-down"></i>
          </div>
          <div className="content">
            <form onSubmit={save}>
              <div className="">
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <select
                    className="form-select form-select-sm"
                    onChange={stateChange}
                    value={defaultState == '' ? GlobalStore.state : defaultState}
                    required>
                    {/* <option key="" value={""}>
                      Select State
                    </option>
                    {GlobalStore.statelist.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))} */}
                    {localStorage.getItem('store_type') === 'CANADA' ? (
                      <>
                        <option value={''}>Select Province</option>
                        {GlobalStore.statelist.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}{' '}
                      </>
                    ) : (
                      <>
                        <option value={''}>Select State</option>
                        {GlobalStore.statelist.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}{' '}
                      </>
                    )}
                  </select>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6 ">
                  <select
                    className="form-select form-select-sm"
                    onChange={storeChange}
                    value={defaultStore.id == 0 ? GlobalStore.store.id : defaultStore.id}
                    required>
                    <option value={''}>Select Store</option>
                    {storeList.length == 0
                      ? GlobalStore.storelist.map((store) => (
                          <option key={store.id} value={store.id}>
                            {store.name}
                          </option>
                        ))
                      : storeList.map((store) => (
                          <option key={store.id} value={store.id}>
                            {store.name}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 center">
                <button type="submit" className="btn redbtn  submit-button">
                  save
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="">
          {' '}
          <div className="heading-wrap">
            <h2>scan product</h2>
            <h3 style={{ color: '#ff0000', padding: '0 50px', 'text-align': 'center', 'font-weight': 'bold', 'font-size': '20px' }} >Deals are always changing, scanned price is not guaranteed.</h3>
          </div>{' '}
        </div>
        <div className="">
          <VideoDecode class="dce-video" playsinline="true" muted style="width:100%;height:100%;" callback={successCallback}></VideoDecode>
        </div>
        <div className="form-wrap"></div>
        <div>
          <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <form onSubmit={handleClose}>
              <div>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="modalcontent">
                        <div className="message-img"></div>
                        <br />
                        {msg}
                        <div className="modalbutton">
                          <button
                            type="submit"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            // onClick={handleClose}
                            className="btn redbtn  submit-button">
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </div>
        {localStorage.getItem('user_token') ? (
          <></>
        ) : (
          <>
            <div className="main-sign-wrap" >
              <div className="sign-wrap">
              {(localStorage.getItem("store_type")==("DC")||localStorage.getItem("store_type")=="TH")?<h3>Sign up for alerts & loyalty rewards!</h3>:
                <h3>Sign up for alerts & more!</h3>}
                <button className="btn yellowbtnsmall  submit-button" onClick={() => history.push('/loyalty')}>Click here</button>
              </div> 
            </div>
          </>
        )}
      </div>
    </div>
    </>
  );
};

export default Scan;
//export const Obj = obj;
