import axios from 'axios';
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Email from '../../assets/images/email.png';
import * as API from '../../Utils/Global.js';

const ForgotPassword = () => {
  var history = useHistory();
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    history.push('/signin');
  };
  const handleShow = () => setShow(true);

  const handleResetPassword = (event) => {
    event.preventDefault();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      axios
        .post(
          API.forgotPassword,
          {EmailAddress: email, store_type: localStorage.getItem('store_type')},
          {headers: {'content-type': 'application/json'}},
        )
        .then((response) => {
          if (response.data.error) {
            toast.error(response.data.msg, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          } else {
            handleShow();
          }
        })
        .catch((err) => {
          toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
        });
    } else {
      toast.warning('Please enter valid Email Address', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
    }
  };
  return (
    <div>
      <div className="clearfix"></div>
      <div className="sign-in-wrap mt-5">
        <div className="chicken_head_white"></div>
        <div className="dc_logo_signin"></div>
      </div>

      <div className="container main-wrap">
        <div className="">
          <div className="col-md-12">
            <div className="signin-wrap">
              <div className="heading-wrap">
                <h2>Forgot Password</h2>
              </div>
              <div className="form-wrap-signin">
                <form onSubmit={handleResetPassword}>
                  <div className="">
                    <div className="col-md-12 center">
                      <br />
                      <p>Please enter the email address you signed up with and we'll send you a password reset link.</p>
                      <br />
                    </div>
                    <div className="col-md-12">
                      <input type="email" required className="form-control" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="col-md-12 center">
                      <button className="btn redbtn  submit-button">Reset Password</button>{' '}
                    </div>
                    <div className="col-md-12"></div>
                  </div>
                  <div>
                    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                      <div>
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="modalcontent">
                                <h3>CHECK YOUR EMAIL</h3>
                                <img src={Email} alt="Email" />
                                <br />
                                <br />
                                <p className="reset-text">
                                  Your request has been processed
                                  <br />
                                  check your registered email id to update
                                  <br />
                                  your password.
                                </p>
                                <div className="modalbutton">
                                  <button type="submit" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} className="btn redbtn">
                                    OK
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
