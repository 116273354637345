import {useRef} from 'react';
import {Link} from 'react-router-dom';
// import "../../css/eAlert.css";

const Tab = (props) => {
  var idletter = props.idletter;
  return (
    <li className="nav-item">
      <a
        id={'tab-' + idletter}
        href={'#pane-' + idletter}
        className={'nav-link ' + props.class}
        data-toggle="tab"
        role="tab"
        aria-selected="false"
        key={idletter}
      >
        {props.state}
      </a>
    </li>
  );
};
export default Tab;
