import {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import close from '../../assets/images/close.png';
import {useDispatch} from 'react-redux';
import {signUpActions} from '../../Store/signUpSlice';

const ConsentModal = (props) => {
  const msg =
    "I have read and understood the terms of CCM's privacy policy. I hereby provide my informed consent to the collection and use of my personal information for the following purposes in addition to those set out in CCM’s privacy policy: to allow CCM to identify me, communicate with me, track my purchases for loyalty benefits, and to manage its relationship with me including providing promotional offers";
  const checkHandler = (e) => {
    let id = e.target.id;
    if (id == 'agree') {
      props.setAgreeChecked(true);
      props.setDisagreeChecked(false);
      props.submitHandler();
    } else {
      props.setAgreeChecked(false);
      props.setDisagreeChecked(true);
      props.submitHandler();
    }
    props.setConsentShow(false);
  };

  return (
    <div>
      <Modal show={props.show} backdrop="static" keyboard={false}>
        <div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modalcontent">
                  <div className="consent">
                    <h3>Informed Consent Statement</h3>
                    <p>
                      I have read and understood the terms of CCM's{' '}
                      <a href="https://ccmllc.com/privacy/" target="_blank">
                        <b>privacy policy</b>
                      </a>
                      . I hereby provide my informed consent to the collection and use of my personal information for the following purposes in
                      addition to those set out in CCM's privacy policy: to allow CCM to identify me, communicate with me, track my purchases for
                      loyalty benefits, and to manage its relationship with me including providing promotional offers.
                    </p>
                  </div>
                  <br />
                  <button id="agree" onClick={checkHandler} className="btn redbtn submit-button">
                    I Agree
                  </button>
                  <button id="disagree" onClick={checkHandler} className="btn yellowbtn submit-button">
                    I don't agree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConsentModal;
