import {per} from '../Store/Store';
import {loadfont} from './loadfonts';
import axios from 'axios';
let profile = 'https://findprice.ilovedirtcheap.com:3002/api' + '/user/profile';
let loyaltyRewardCode = 'https://findprice.ilovedirtcheap.com:3002/api' + '/loyalty/getLoyaltyReward';

export const checkMobileDevice = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

export const checkDomain = () => {
  let name = window.location.hostname;
  let hostname;
  if (name === "localhost" || name === "pricecheck.ccmllc.com") {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (
      params.host_name === null &&
      localStorage.getItem("store_type") === null
    ) {
      hostname = "ilovedirtcheap";
      setStoreType(hostname);
    } else if (params.host_name !== null) {
      localStorage.removeItem("persist:" + per.key);
      hostname = params.host_name;
      setStoreType(hostname);
    }
  } else {
    if (name.split(".")[2] === "ca") {
      hostname = "canada";
    } else {
      hostname = name.split(".")[1];
    }
    setStoreType(hostname);
  }
  changestyle();
};

function setStoreType(hostname) {
  localStorage.removeItem('store_type');
  localStorage.removeItem('color');
  switch (hostname) {
    case 'ilovedirtcheap':
      localStorage.setItem('store_type', 'DC');
      localStorage.setItem('color', '#ff0000');
      break;
    case 'ilovedirtcheapbuildingsupplies':
      localStorage.setItem('store_type', 'DCBS');
      localStorage.setItem('color', '#FFFF00');
      break;
    case 'ilovetreasurehunt':
      localStorage.setItem('store_type', 'TH');
      localStorage.setItem('color', '#0071BC');
      break;
    case 'canada':
      localStorage.setItem('store_type', 'CANADA');
      localStorage.setItem('color', '#0000FF');
      break;
    default:
      localStorage.setItem('store_type', 'DC');
      localStorage.setItem('color', '#ff0000');
      break;
  }
}

function changestyle() {
  let domain = localStorage.getItem('store_type');
  switch (domain) {
    case 'DC':
      // import('../css/style_dirtcheap.css');
      document.documentElement.style.setProperty('--primary-bg-color', '#ff0000');
      document.documentElement.style.setProperty('--secondary-btn-color', '#FFFF00');
      document.documentElement.style.setProperty('--primary-btn-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--secondary-btn-text-color', '#000000');
      document.documentElement.style.setProperty('--primary-text-color', '#000000');
      document.documentElement.style.setProperty('--secondary-text-color', '#ffffff');
      document.documentElement.style.setProperty('--nav-text-color', '#000000');
      document.documentElement.style.setProperty('--third-text-color', '#ff0000');
      document.documentElement.style.setProperty('--loyalty-text-color', '#FFFF00;');
      document.documentElement.style.setProperty('--font-family-secondary', 'neue-haas-grotesk-text');

      document.documentElement.style.setProperty('--icn-sprites', `url(${require('../assets/images/DC/spritesV2.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks', `url(${require('../assets/images/DC/footer-tracks.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks-mobile', `url(${require('../assets/images/DC/footer-tracks-mobile.png')})`);
      document.documentElement.style.setProperty('--icn-loyalty', `url(${require('../assets/images/DC/loyalty.png')})`);
      document.documentElement.style.setProperty('--icn-success', `url(${require('../assets/images/DC/success.png')})`);
      document.documentElement.style.setProperty('--icn-thumbs-up', `url(${require('../assets/images/DC/thumbs-up.png')})`);
      document.documentElement.style.setProperty('--icn-error', `url(${require('../assets/images/DC/error.png')})`);
      document.documentElement.style.setProperty('--icn-new-logo', `url(${require('../assets/images/DC/new-logo.png')})`);
      document.documentElement.style.setProperty('--icn-desktop-logo', `url(${require('../assets/images/DC/DC-D-logo-big.png')})`);
      document.documentElement.style.setProperty('--icn-mobile-logo', `url(${require('../assets/images/DC/DC_M_logo.jpg')})`);
      document.documentElement.style.setProperty('--icn-logo-signin', `url(${require('../assets/images/DC/logo-signin.png')})`);
      document.documentElement.style.setProperty('--icn-chicken-head-white', `url(${require('../assets/images/DC/Chicken_Head_white.png')})`);
      document.documentElement.style.setProperty('--icn-profile-voucher', `url(${require('../assets/images/DC/profile-voucher.png')})`);
      document.documentElement.style.setProperty('--icn-nav-scan', `url(${require('../assets/images/DC/scan.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile', `url(${require('../assets/images/DC/profile.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile-disable', `url(${require('../assets/images/DC/profile-disable.png')})`);

      break;
    case 'DCBS':
      // import('../css/style_buildingsupplies.css');
      document.documentElement.style.setProperty('--primary-bg-color', '#FFFF00');
      document.documentElement.style.setProperty('--secondary-btn-color', '#000000');
      document.documentElement.style.setProperty('--primary-btn-text-color', '##000000');
      document.documentElement.style.setProperty('--secondary-btn-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--primary-text-color', '#000000');
      document.documentElement.style.setProperty('--primary-text-color', '#000000');
      document.documentElement.style.setProperty('--secondary-text-color', '#ffffff');
      document.documentElement.style.setProperty('--nav-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--third-text-color', '000000');
      document.documentElement.style.setProperty('--loyalty-text-color', '#000000');
      document.documentElement.style.setProperty('--a-link-text', '#000000');
      document.documentElement.style.setProperty('--main-nav-text', '#ffff00');
      document.documentElement.style.setProperty('--font-family-secondary', 'neue-haas-grotesk-text');
      document.documentElement.style.setProperty('--success-text', '#000000');
      document.documentElement.style.setProperty('--bs-body-color', '#000000');
      document.documentElement.style.setProperty('--icn-sprites', `url(${require('../assets/images/DCBS/spritesV2.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks', `url(${require('../assets/images/DCBS/footer-tracks.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks-mobile', `url(${require('../assets/images/DCBS/footer-tracks-mobile.png')})`);
      document.documentElement.style.setProperty('--icn-loyalty', `url(${require('../assets/images/DCBS/loyalty.png')})`);
      document.documentElement.style.setProperty('--icn-success', `url(${require('../assets/images/DCBS/success.png')})`);
      document.documentElement.style.setProperty('--icn-thumbs-up', `url(${require('../assets/images/DCBS/thumbs-up.png')})`);
      document.documentElement.style.setProperty('--icn-error', `url(${require('../assets/images/DCBS/error.png')})`);
      document.documentElement.style.setProperty('--icn-new-logo', `url(${require('../assets/images/DCBS/new-logo.png')})`);
      document.documentElement.style.setProperty('--icn-desktop-logo', `url(${require('../assets/images/DCBS/Dcbs-D-logo-big.jpg')})`);
      document.documentElement.style.setProperty('--icn-mobile-logo', `url(${require('../assets/images/DCBS/DCBS-M-logo.jpg')})`);
      document.documentElement.style.setProperty('--icn-logo-signin', `url(${require('../assets/images/DCBS/logo-signin.png')})`);
      document.documentElement.style.setProperty('--icn-chicken-head-white', `url(${require('../assets/images/DCBS/Chicken_Head_white.png')})`);
      document.documentElement.style.setProperty('--icn-profile-voucher', `url(${require('../assets/images/DCBS/profile-voucher.png')})`);
      document.documentElement.style.setProperty('--icn-nav-scan', `url(${require('../assets/images/DCBS/scan.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile', `url(${require('../assets/images/DCBS/profile.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile-disable', `url(${require('../assets/images/DCBS/profile-disable.png')})`);
      break;
    case 'TH':
      document.documentElement.style.setProperty('--primary-bg-color', '#0071BC');
      document.documentElement.style.setProperty('--secondary-btn-color', '#FF0000');
      document.documentElement.style.setProperty('--primary-btn-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--secondary-btn-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--primary-text-color', '#000000');
      document.documentElement.style.setProperty('--secondary-text-color', '#ffffff');
      document.documentElement.style.setProperty('--nav-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--third-text-color', '#0071BC');
      document.documentElement.style.setProperty('--loyalty-text-color', '#ffffff');
      document.documentElement.style.setProperty('--font-family-secondary', 'itc-avant-garde-gothic-pro');
      document.documentElement.style.setProperty('--main-nav-text', '#ffffff');
      document.documentElement.style.setProperty('--a-link-text', '#0071BC');

      document.documentElement.style.setProperty('--icn-sprites', `url(${require('../assets/images/TH/spritesV2.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks', `url(${require('../assets/images/TH/footer-tracks.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks-mobile', `url(${require('../assets/images/TH/footer-tracks-mobile.png')})`);
      document.documentElement.style.setProperty('--icn-loyalty', `url(${require('../assets/images/TH/loyalty.png')})`);
      document.documentElement.style.setProperty('--icn-success', `url(${require('../assets/images/TH/success.png')})`);
      document.documentElement.style.setProperty('--icn-thumbs-up', `url(${require('../assets/images/TH/thumbs-up.png')})`);
      document.documentElement.style.setProperty('--icn-error', `url(${require('../assets/images/TH/error.png')})`);
      document.documentElement.style.setProperty('--icn-new-logo', `url(${require('../assets/images/TH/new-logo.png')})`);
      document.documentElement.style.setProperty('--icn-desktop-logo', `url(${require('../assets/images/TH/TW-D-logo-big.png')})`);
      document.documentElement.style.setProperty('--icn-mobile-logo', `url(${require('../assets/images/TH/TW_M_logo.jpg')})`);
      document.documentElement.style.setProperty('--icn-logo-signin', `url(${require('../assets/images/TH/logo-signin.png')})`);
      document.documentElement.style.setProperty('--icn-chicken-head-white', `url(${require('../assets/images/TH/Chicken_Head_white.png')})`);
      document.documentElement.style.setProperty('--icn-profile-voucher', `url(${require('../assets/images/TH/profile-voucher.png')})`);
      document.documentElement.style.setProperty('--icn-nav-scan', `url(${require('../assets/images/TH/scan.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile', `url(${require('../assets/images/TH/profile.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile-disable', `url(${require('../assets/images/TH/profile-disable.png')})`);

      break;
    case 'CANADA':
      // import('../css/style_canada.css');
      document.documentElement.style.setProperty('--primary-bg-color', '#0000FF');
      document.documentElement.style.setProperty('--secondary-btn-color', '#FF0000');
      document.documentElement.style.setProperty('--primary-btn-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--secondary-btn-text-color', '#FFFFFF');
      document.documentElement.style.setProperty('--primary-text-color', '#000000');
      document.documentElement.style.setProperty('--secondary-text-color', '#ffffff');
      document.documentElement.style.setProperty('--nav-text-color', '#ffffff');
      document.documentElement.style.setProperty('--third-text-color', '#0000ff');
      document.documentElement.style.setProperty('--loyalty-text-color', '#000000');
      document.documentElement.style.setProperty('--font-family-secondary', 'neue-haas-grotesk-text');
      document.documentElement.style.setProperty('--main-nav-text', '#ffffff');
      document.documentElement.style.setProperty('--a-link-text', '#0000ff');

      document.documentElement.style.setProperty('--icn-sprites', `url(${require('../assets/images/TH_CANADA/spritesV2.png')})`);
      document.documentElement.style.setProperty('--icn-footer-tracks', `url(${require('../assets/images/TH_CANADA/footer-tracks.png')})`);
      document.documentElement.style.setProperty(
        '--icn-footer-tracks-mobile',
        `url(${require('../assets/images/TH_CANADA/footer-tracks-mobile.png')})`,
      );
      document.documentElement.style.setProperty('--icn-loyalty', `url(${require('../assets/images/TH_CANADA/loyalty.png')})`);
      document.documentElement.style.setProperty('--icn-success', `url(${require('../assets/images/TH_CANADA/success.png')})`);
      document.documentElement.style.setProperty('--icn-thumbs-up', `url(${require('../assets/images/TH_CANADA/thumbs-up.png')})`);
      document.documentElement.style.setProperty('--icn-error', `url(${require('../assets/images/TH_CANADA/error.png')})`);
      document.documentElement.style.setProperty('--icn-new-logo', `url(${require('../assets/images/TH_CANADA/new-logo.png')})`);
      document.documentElement.style.setProperty('--icn-desktop-logo', `url(${require('../assets/images/TH_CANADA/TH-D-logo-big.png')})`);
      document.documentElement.style.setProperty('--icn-mobile-logo', `url(${require('../assets/images/TH_CANADA/TH_M_logo.png')})`);
      document.documentElement.style.setProperty('--icn-logo-signin', `url(${require('../assets/images/TH_CANADA/logo-signin.png')})`);
      document.documentElement.style.setProperty('--icn-chicken-head-white', `url(${require('../assets/images/TH_CANADA/Chicken_Head_white.png')})`);
      document.documentElement.style.setProperty('--icn-profile-voucher', `url(${require('../assets/images/TH_CANADA/profile-voucher.png')})`);
      document.documentElement.style.setProperty('--icn-nav-scan', `url(${require('../assets/images/TH_CANADA/scan.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile', `url(${require('../assets/images/TH_CANADA/profile.png')})`);
      document.documentElement.style.setProperty('--icn-nav-profile-disable', `url(${require('../assets/images/profile-disable.png')})`);
      document.documentElement.style.setProperty('--icn-desktop-logo-ccm', `url(${require('../assets/images/TH_CANADA/ccm_logo.png')})`);
      document.documentElement.style.setProperty('--icn-mobile-logo-ccm', `url(${require('../assets/images/TH_CANADA/ccm_logo.png')})`);

      break;
    default:
      // import('../css/style.css');
      break;
  }
  loadfont();
}

export const getStoreName = () => {
  let domain = localStorage.getItem('store_type');
  let store = '';
  switch (domain) {
    case 'DC':
      store = 'Dirt Cheap';
      break;
    case 'DCBS':
      store = 'Dirt Cheap Building Supplies';
      break;
    case 'TH':
      store = 'Treasure Hunt';
      break;
    case 'CANADA':
      store = 'Treasure Hunt Canada';
      break;
    default:
      store = 'Dirt Cheap';
      break;
  }
  return store;
};

export const getUserDetails = async () => {
  if (localStorage.getItem('user_id')) {
    await axios
      .post(
        profile,
        {UserID: localStorage.getItem('user_id')},
        {
          headers: {
            'content-type': 'application/json',
            'x-auth-token': localStorage.getItem('user_token'),
          },
        },
      )
      .then((response) => {
        var data = response.data[0];
        localStorage.setItem('user_name', data.first_name);
        localStorage.setItem('member_id', data.member_id);
      })
      .catch((err) => {});
  }
};

export async function getVoucherCode(MemberId) {
  return await axios
    .post(
      loyaltyRewardCode,
      {
        member_id: MemberId,
        store_type: localStorage.getItem('store_type'),
      },
      {
        headers: {
          'content-type': 'application/json',
          'x-auth-token': localStorage.getItem('user_token'),
        },
      },
    )
    .then((response) => {
      return response.data.success;
    })
    .catch((err) => {});
} 
