import {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
// import { allstoresActions } from "../../Store/storeSlice";
import {allstoresActions} from '../../Store/allStoresSlice';
import {useHistory} from 'react-router-dom';
import {useEffect} from 'react';
import bcrypt from 'bcryptjs';
import {Link} from 'react-router-dom';
import {signUpActions} from '../../Store/signUpSlice';
import {eAlertActions} from '../../Store/eAlertSlice';
import * as API from '../../Utils/Global.js';
import Select from 'react-select';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {US_STORES} from '../../Utils/Global.js';
import $ from 'jquery';
import {profileActions} from '../../Store/profileSlice';
import InputMask from 'react-input-mask';
import ExistingEmailModal from './ExistingEmailModal';
import ConsentModal from './ConsentModal';
import OptOutModal from './OptOutModal';
import ShareMyInfoModal from './ShareMyInfoModal';

var stack = [];
var stackItem = null;
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '55px',
  }),
};

const SignUp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user_id = localStorage.getItem('user_id');
  const AllStores = useSelector((state) => state.allstores);
  const signUp = useSelector((state) => state.signUp);
  const eAlert = useSelector((state) => state.eAlert);
  const [storelist, setStores] = useState([]);
  const [pass, setpass] = useState('');
  const [pass2, setpass2] = useState('');
  const [cookies, setCookieCheck] = useState(true);
  const [terms, setTermsCheck] = useState(true);
  const [ealertcheck, setEalertCheck] = useState(true);
  const [preferences, pushPreferences] = useState(false);
  const [successpage, pushSuccess] = useState(false);
  const [homeStore, setHomeStore] = useState(0);
  const [show, setShow] = useState(false);
  const [OptoutShow, setOptoutShow] = useState(false);
  const [consentCheck, setConsentCheck] = useState(true);
  const [consentShow, setConsentShow] = useState(false);
  const [ShareMyInfo, ShareMyInfoShow] = useState(false);
  var agreeChecked = false;
  var disagreeChecked = false;
  var [selectRef, setRef] = useState(null);

   //checkDomain();
  let domain = localStorage.getItem('store_type');
  let Country = US_STORES.indexOf(domain) != -1 ? 'US' : 'CA';
  if (domain == null) {
    Country = 'US';
  }

  const setAgreeChecked = (value) => {
    agreeChecked = value;
  };
  const setDisagreeChecked = (value) => {
    disagreeChecked = value;
  };

  const Update = async () => {
    await fetch(API.updatePref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: localStorage.getItem('user_id'),
      }),
    })
      .then(async (raw) => {})
      .catch((err) => {});
  };

  function getStoreList(stores, state) {
    var storelist = [];
    for (let i = 0; i < stores.length; i++) {
      // if (stores[i].state == state)
      storelist.push({
        value: stores[i].idd_store_id,
        label: stores[i].name,
      });
    }
    // dispatch(profileActions.setStorelist({ storelist: storelist }));
    storelist = storelist.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    dispatch(allstoresActions.storeList({storelist: storelist}));
    dispatch(allstoresActions.homeStores({homestores: storelist}));
    return storelist;
  }

  useEffect(() => {
    if (localStorage.getItem('user_id')) {
      history.push('/profile');
    }
    let country = localStorage.getItem('store_type');
    var url = '';

    if (country.toLocaleLowerCase() == 'canada') {
      url = API.eAlertStores + '?store_type=CANADA';
    } else {
      url = API.eAlertStores;
    }

    let appending = localStorage.getItem('store_type') === 'CANADA' ? 'canada' : 'us';

    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      success: function (response) {
        dispatch(allstoresActions.stores({stores: response.stores}));
        $.ajax({
          url: API.state + '?country=' + appending,
          type: 'GET',
          async: false,
          success: function (response) {
            // var stateList=response;
            // setState(stateList);
            getStateList(response, Country);
          },
          error: function (xhr, response) {
            console.log(xhr);
            console.log(response);
          },
        });

        getStoreList(response.stores);
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });

    if (eAlert.categories.length == 0) {
      fetch(API.eAlertCategory, {})
        .then(async (response) => {
          response = await response.json();
          let subscriber_categories = [];
          response.map((el) => subscriber_categories.push(el.id));
          dispatch(eAlertActions.addCategories({categories: subscriber_categories}));
          response = response.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0));
          dispatch(eAlertActions.setCategories({categories: response}));
        })
        .catch();
    }
  }, []);

  useEffect(() => {
    if (preferences) {
      Update();
      history.push('/preferences');
    }
    if (successpage) {
      history.push('/success');
    }
  }, [preferences, successpage]);
  useEffect(() => {
    if (stackItem !== null) {
      selectRef.selectOption(stackItem);
    }
  }, [stackItem]);
  //FUNCTIONS

  function getStores(country) {
    let appending = localStorage.getItem('store_type') === 'CANADA' ? 'canada' : 'us';
    fetch(API.store)
      .then((response) => response.json())
      .then((resp) => {
        dispatch(allstoresActions.stores({stores: resp.stores}));
        $.ajax({
          url: API.state + '?country=' + appending,
          type: 'GET',
          async: false,
          success: function (response) {
            // var stateList=response;
            // setState(stateList);
            getStateList(response, Country);
          },
          error: function (xhr, response) {
            console.log(xhr);
            console.log(response);
          },
        });
        // getStateList(resp.stores, country);
        getStoreList(resp.stores, AllStores.state);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function getStateList(stores, country) {
    //   {
    //     "id": 3,
    //     "state": "Alberta",
    //     "code": "AB",
    //     "country": "CANADA"
    // }
    var states = [];
    for (let i = 0; i < stores.length; i++) {
      if (states.length == 0 || states.indexOf(stores[i].state) == -1) {
        // if (
        //   (stores[i].store_type === "CANADA" && country === "CA") ||
        //   (stores[i].store_type !== "CANADA" && country === "US")
        // ) {
        states.push(stores[i].state);
        // }
      }
    }
    states = states.sort();
    dispatch(allstoresActions.stateList({statelist: states}));
  }

  function getStoreList(stores, state) {
    var storelist = [];
    for (let i = 0; i < stores.length; i++) {
      // if (stores[i].state == state)
      storelist.push({
        value: stores[i].idd_store_id,
        label: stores[i].name,
      });
    }
    // setStores(storelist);
    storelist = storelist.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    dispatch(allstoresActions.storeList({storelist: storelist}));
    dispatch(allstoresActions.homeStores({homestores: storelist}));
  }

  const fNameHandler = (event) => {
    // setfname(event.target.value);
    dispatch(signUpActions.setForm({attr: 'fName', attrVal: event.target.value}));
  };
  const lNameHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'lName', attrVal: event.target.value}));
  };
  const emailHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'email', attrVal: event.target.value}));
  };
  const addr1Handler = (event) => {
    dispatch(signUpActions.setForm({attr: 'addr1', attrVal: event.target.value}));
  };
  const addr2Handler = (event) => {
    dispatch(signUpActions.setForm({attr: 'addr2', attrVal: event.target.value}));
  };
  const phoneHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'phone', attrVal: event.target.value}));
  };
  const cityHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'city', attrVal: event.target.value}));
  };
  const countryHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'country', attrVal: event.target.value}));
    getStores(event.target.value);
  };
  const stateHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'state', attrVal: event.target.value}));
    getStoreList(AllStores.stores, event.target.value);
  };
  const zipHandler = (event) => {
    dispatch(signUpActions.setForm({attr: 'zip', attrVal: event.target.value}));
  };

  const favStoreChange = (params) => {
    var arr = params.map((store) => store.value);
    dispatch(signUpActions.setForm({attr: 'favStore', attrVal: arr}));
  };

  const passHandler = (event) => {
    setpass(event.target.value);
  };
  const pass2Handler = (event) => {
    setpass2(event.target.value);
  };

  const cookiesHandler = (e) => {
    setCookieCheck(!cookies);
  };
  const termsHandler = (e) => {
    setTermsCheck(!terms);
  };
  const ealertcheckHandler = (e) => {
    setEalertCheck(!ealertcheck);
  };
  const consentHandler = (e) => {
    if (!e.target.checked) {
      setOptoutShow(true);
    }
  };

  const SignUpWithoutConsent = () => {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!signUp.email.match(mailformat)) {
      toast.warning('Invalid email!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      dispatch(signUpActions.setForm({attr: 'email', attrVal: ''}));
      document.signupform.email.focus();
      return;
    }

    if (pass != pass2) {
      toast.warning("Password didn't match!", {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      return;
    }
    if(domain !=="CANADA"){
        if (signUp.state == '') {
          toast.warning('Please select state!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          return;
        }
        if (!signUp.phone.match(/^[2-9]{1}\d/g) || signUp.phone.length < 10 || signUp.phone.length > 25) {
          toast.warning('Invalid phone!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          dispatch(signUpActions.setForm({attr: 'phone', attrVal: ''}));
          document.signupform.phone.focus();
          return;
        }
        if (!signUp.zip) {
          let validationMessage = '';
          if (localStorage.getItem('store_type') === 'CANADA') {
            validationMessage = 'Postal code is required!';
          } else {
            validationMessage = 'Zip code is required!';
          }
          toast.warning(validationMessage, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
          return;
        }
        if (localStorage.getItem('store_type') === 'CANADA') {
          if (!signUp.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/g)) {
            toast.warning('Invalid postal code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
            return;
          }
        } else {
          if (signUp.zip<=0) {
            toast.warning('Invalid zip code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
            return;
          }
        }
    }
    if (terms == false || cookies == false) {
      toast.warning('Please Accept Agreements!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      return;
    }
    if (homeStore == 0 || homeStore == '' || homeStore == null) {
      toast.warning('Please select Home Store!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      return;
    }

    const salt = bcrypt.genSaltSync(10);
    // const hash = bcrypt.hashSync(pass.toString(), salt);
    const form = {
      EmailAddress: signUp.email,
      FirstName: signUp.fName,
      LastName: signUp.lName,
      Address1: signUp.addr1,
      Address2: signUp.addr2,
      Phone: signUp.phone,
      City: signUp.city,
      State: signUp.state,
      Country: Country,
      ZipCode: signUp.zip,
      PassHash: pass,
      CookieAgr: cookies == true ? 1 : 0,
      TnCAgr: terms == true ? 1 : 0,
      FavStores: signUp.stores,
      HomeStore: homeStore,
      store_type: localStorage.getItem('store_type'),
    };

    fetch(API.user, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form),
    })
      .then((raw) => raw.json())
      .then(async (response) => {
        if (response.error === true) {
          toast.error(response.msg, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
        } else {
          if (response.id > 0) {
            localStorage.setItem('member_id', response.memberId);
            var fav = signUp.stores.toString();
            dispatch(
              profileActions.setFav([
                {
                  favStores: fav,
                },
              ]),
            );

            var resp = await fetch(API.login, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                EmailAddress: form.EmailAddress,
                PassHash: form.PassHash,
              }),
            })
              .then(async (response) => {
                if (response.headers.get('x-auth-token')) {
                  localStorage.setItem('user_token', response.headers.get('x-auth-token'));
                }
                response = await response.json();
                if (response.LoginStatus === 'Success') {
                  localStorage.setItem('user_id', response.UserID);
                  localStorage.setItem('user_firstname', response.UserName);
                }
                if (ealertcheck) {
                  pushPreferences(true);
                } else {
                  pushSuccess(true);
                }
                // return response.data;
              })
              .catch((err) => {
                toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              });
            var favStores = [...new Set([...[homeStore], ...signUp.stores])];

            // if (ealertcheck) {
            fetch(API.setUserPref, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('user_token'),
              },
              body: JSON.stringify({
                user_id: localStorage.getItem('user_id'),
                deliver_email: 1,
                deliver_sms: 1,
                subscriber_categories: eAlert.subscriber_categories,
                subscriber_locations: favStores,
              }),
            })
              .then((raw) => {})
              .catch((err) => {
                console.log(err);
              });
            // }
            document.forms['signupform'].reset();
          } else {
            toast.error(response.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          }
        }
      })
      .catch((err) => {
        toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };

  const submitHandler = (event) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (localStorage.getItem('store_type') === 'CANADA') {
      if (disagreeChecked) {
        dispatch(signUpActions.reset());
        history.push('/');
      }
      if (agreeChecked) {
        setConsentShow(false);
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!signUp.email.match(mailformat)) {
          toast.warning('Invalid email!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          dispatch(signUpActions.setForm({attr: 'email', attrVal: ''}));
          document.signupform.email.focus();
          return;
        }

        if (pass != pass2) {
          toast.warning("Password didn't match!", {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          return;
        }
        if(domain !=="CANADA"){
            if (signUp.state == '') {
              toast.warning('Please select state!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              return;
            }
            if (!signUp.phone.match(/^[2-9]{1}\d/g) || signUp.phone.length < 10 || signUp.phone.length > 25) {
              toast.warning('Invalid phone!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              dispatch(signUpActions.setForm({attr: 'phone', attrVal: ''}));
              document.signupform.phone.focus();
              return;
            }
            if (!signUp.zip) {
              let validationMessage = '';
              if (localStorage.getItem('store_type') === 'CANADA') {
                validationMessage = 'Postal code is required!';
              } else {
                validationMessage = 'Zip code is required!';
              }
              toast.warning(validationMessage, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
              return;
            }
            if (localStorage.getItem('store_type') === 'CANADA') {
              if (!signUp.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/g)) {
                toast.warning('Invalid postal code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
                dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
                return;
              }
            } else {
              if (isNaN(signUp.zip)) {
                toast.warning('Invalid zip code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
                dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
                return;
              }
            }
        }
        if (terms == false || cookies == false) {
          toast.warning('Please Accept Agreements!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          return;
        }
        if (homeStore == 0 || homeStore == '' || homeStore == null) {
          toast.warning('Please select Home Store!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          return;
        }
        const salt = bcrypt.genSaltSync(10);
        // const hash = bcrypt.hashSync(pass.toString(), salt);
        const form = {
          EmailAddress: signUp.email,
          FirstName: signUp.fName,
          LastName: signUp.lName,
          Address1: signUp.addr1,
          Address2: signUp.addr2,
          Phone: signUp.phone,
          City: signUp.city,
          State: signUp.state,
          Country: Country,
          ZipCode: signUp.zip,
          PassHash: pass,
          CookieAgr: cookies == true ? 1 : 0,
          TnCAgr: terms == true ? 1 : 0,
          FavStores: signUp.stores,
          HomeStore: homeStore,
          store_type: localStorage.getItem('store_type'),
        };

        fetch(API.user, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form),
        })
          .then((raw) => raw.json())
          .then(async (response) => {
            if (response.error === true) {
              toast.error(response.msg, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            } else {
              if (response.id > 0) {
                localStorage.setItem('member_id', response.memberId);
                var fav = signUp.stores.toString();
                dispatch(
                  profileActions.setFav([
                    {
                      favStores: fav,
                    },
                  ]),
                );

                var resp = await fetch(API.login, {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    EmailAddress: form.EmailAddress,
                    PassHash: form.PassHash,
                  }),
                })
                  .then(async (response) => {
                    if (response.headers.get('x-auth-token')) {
                      localStorage.setItem('user_token', response.headers.get('x-auth-token'));
                    }

                    response = await response.json();
                    if (response.LoginStatus === 'Success') {
                      // localStorage.setItem("user_token", response.headers["x-auth-token"]);
                      localStorage.setItem('user_id', response.UserID);
                      localStorage.setItem('user_firstname', response.UserName);
                    }

                    //API call for consent
                    $.ajax({
                      url: API.privacyConsent,
                      type: 'POST',
                      async: false,
                      accepts: 'application/json',
                      contentType: 'application/json',
                      headers: {
                        'x-auth-token': localStorage.getItem('user_token'),
                      },
                      data: JSON.stringify({
                        user_id: response.UserID,
                        consent: consentCheck ? 1 : 0,
                      }),
                      success: function (response) {},
                      error: function (xhr, response) {
                        console.log(xhr);
                        console.log(response);
                      },
                    });
                    if (ealertcheck) {
                      pushPreferences(true);
                    } else {
                      pushSuccess(true);
                    }
                    // return response.data;
                  })
                  .catch((err) => {
                    toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
                  });

                var favStores = [...new Set([...[homeStore], ...signUp.stores])];

                // if (ealertcheck) {
                fetch(API.setUserPref, {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('user_token'),
                  },
                  body: JSON.stringify({
                    user_id: localStorage.getItem('user_id'),
                    deliver_email: 1,
                    deliver_sms: 1,
                    subscriber_categories: eAlert.subscriber_categories,
                    subscriber_locations: favStores,
                  }),
                })
                  .then((raw) => {})
                  .catch((err) => {
                    console.log(err);
                  });
                // }
                document.forms['signupform'].reset();
              } else {
                toast.error(response.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              }
            }
          })
          .catch((err) => {
            toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          });
      } else {
        if(domain!=="CANADA"){
          if (!signUp.phone.match(/^[2-9]{1}\d/g) || signUp.phone.length < 10 || signUp.phone.length > 25) {
            toast.warning('Invalid phone!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            dispatch(signUpActions.setForm({attr: 'phone', attrVal: ''}));
            return;
          }

          if (localStorage.getItem('store_type') === 'CANADA') {
            if (!signUp.zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/g)) {
              toast.warning('Invalid postal code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
              return;
            }
          } else {
            if (isNaN(signUp.zip)) {
              toast.warning('Invalid zip code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
              dispatch(signUpActions.setForm({attr: 'zip', attrVal: ''}));
              return;
            }
        }
      }

        if (terms == false || cookies == false) {
          toast.warning('Please Accept Agreements!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          return;
        }
        setConsentShow(true);
      }
    } else {
      SignUpWithoutConsent();
    }
  };

  const homeStoreHandler = (event) => {
    //     If(selected in fav stores)
    // Favourite.pop(selected);
    // Side_Array.push(selected);
    let value = event.target.value;
    value = value == '' ? 0 : parseInt(value);
    setHomeStore(value);
    let List = [...AllStores.storelist];
    if (stack.length !== 0) {
      stackItem = stack.pop();
      List.push(stackItem);
      dispatch(allstoresActions.homeStores({homestores: List}));
    }
    let obj = AllStores.storelist.filter((e) => {
      return e.value == value;
    });
    let index = AllStores.storelist.indexOf(obj[0]);
    let newList = [...List];
    newList.splice(index, 1);
    dispatch(allstoresActions.homeStores({homestores: newList}));
    // if (stackItem != null) {
    //   selectRef.props.options.push(stackItem);
    // }
    let favIndex = signUp.stores.indexOf(value);
    if (favIndex != -1) {
      let arr = [...signUp.stores];
      arr.splice(favIndex, 1);
      dispatch(signUpActions.setForm({attr: 'favStore', attrVal: arr}));
      let val = selectRef.props.value.filter((el) => {
        return el.value == value;
      })[0];
      selectRef.removeValue(val);
      stack.push(val);
    }
  };
  const checkExistingEmail = () => {
    if (!signUp.email == '') {
      fetch(API.checkExistingEmail, {
        method: 'POST',
        body: JSON.stringify({
          email: signUp.email,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async (response) => {
          response = await response.json();
          if (response.result == true) {
            setShow(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="clearfix"></div>
      <div className="container main-wrap">
        <div className="heading-wrap">
          <h2> sign up</h2>
        </div>

        <div className="form-wrap">
          <form name="signupform" onSubmit={submitHandler} autoComplete="off">
            <div className="row form_inner_wrap">
              <div className="col-md-6 col-sm-6 col-xs-6 ">
                <input type="text" className="form-control" placeholder={'First Name*'} required onChange={fNameHandler} />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" value={signUp.lName} placeholder="Last Name*" required onChange={lNameHandler} />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={signUp.email}
                  placeholder="Email*"
                  required
                  onChange={emailHandler}
                  onBlur={checkExistingEmail}
                />
              </div>
              {domain === "CANADA" ? 
                <></> 
              : (
                <>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" value={signUp.addr1} placeholder="Address1" onChange={addr1Handler} />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" value={signUp.addr2} placeholder="Address2" onChange={addr2Handler} />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="number" className="form-control" value={signUp.phone} placeholder="Phone Number*" onChange={phoneHandler} required />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" value={signUp.city} placeholder="City" onChange={cityHandler} />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" value={Country == 'US' ? 'United States' : 'Canada'} disabled required />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <select className="form-select" onChange={stateHandler} required>
                  {localStorage.getItem('store_type') === 'CANADA' ? (
                    <>
                      <option value={''}>Select Province*</option>{' '}
                      {AllStores.statelist.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}{' '}
                    </>
                  ) : (
                    <>
                      <option value={''}>Select State*</option>
                      {AllStores.statelist.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}{' '}
                    </>
                  )}
                  {/* {AllStores.statelist.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))} */}
                </select>
              </div>
              {/* Zip */}
              <div className="col-md-6 col-sm-6 col-xs-6">
                {localStorage.getItem('store_type') === 'CANADA' ? (
                  <InputMask
                    mask="*** ***"
                    onChange={zipHandler}
                    placeholder="Postal Code*"
                    value={signUp.zip}
                    className="form-control"
                    required></InputMask>
                ) : (
                  <InputMask mask="99999" onChange={zipHandler} placeholder="Zip*" value={signUp.zip} className="form-control" required></InputMask>
                )}
              </div>
              </>
              )}
              <div className="col-md-6 col-sm-6 col-xs-6">
                <select className="form-select" onChange={homeStoreHandler} required>
                  <option value={''}>What is your home store?*</option>
                  {AllStores.storelist.map((store) => (
                    <option key={store.value} value={store.value}>
                      {store.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 mt-1">
                <Select
                  ref={(ref) => {
                    selectRef = ref;
                  }}
                  placeholder={
                    localStorage.getItem('store_type') === 'CANADA' ? 'Would you like to add other stores?' : 'Would you like to add other stores?'
                  }
                  isMulti
                  options={AllStores.homestores}
                  onChange={favStoreChange}
                  // className="form-select"
                  styles={customStyles}
                  // styles={{fontSize: '16px !important'}}
                  isDisabled={homeStore === 0 ? true : false}
                />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input
                  type="password"
                  className="form-control"
                  value={pass}
                  placeholder="Password*"
                  required
                  onChange={passHandler}
                  minLength={8}
                  autoComplete="new-password"
                />
              </div>
              {/* Confirm Password */}
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input
                  type="password"
                  className="form-control"
                  value={pass2}
                  placeholder="Confirm Password*"
                  required
                  onChange={pass2Handler}
                  minLength={8}
                />
              </div>
            </div>

            <div className="row checkbox-container">
              <div className="col-md-12">
                <div className="checkbox-wrap">
                  <input type="checkbox" onChange={ealertcheckHandler} checked={ealertcheck} />
                </div>
                <div className="content-wrap">Sign up for eAlerts</div>
              </div>

              <div className="col-md-12">
                <div className="checkbox-wrap">
                  <input type="checkbox" onChange={cookiesHandler} checked={cookies} />
                </div>
                <div className="content-wrap">By checking this you are allowing our cookies</div>
              </div>

              <div className="col-md-12">
                <div className="checkbox-wrap">
                  <input type="checkbox" onChange={termsHandler} checked={terms} />
                </div>

                <div className="content-wrap">
                  Accept <Link to="/terms">Terms & Conditions</Link>
                </div>
              </div>
              {localStorage.getItem('store_type') === 'CANADA' ? (
                <div className="col-md-12">
                  <div className="checkbox-wrap">
                    <input id="consentCheckBox" type="checkbox" onChange={consentHandler} checked={consentCheck} />
                  </div>
                  <div className="content-wrap">
                    I consent to{' '}
                    <a
                      
                      onClick={() => {
                        ShareMyInfoShow(true);
                        return false;
                      }}>
                      <u>Sharing My Information</u>
                    </a>
                  </div>
                </div>
              ) : null}
              {/* <div className="col-md-12">
                  <div className="checkbox-wrap">
                    <input
                      id="consentCheckBox"
                      type="checkbox"
                      onChange={consentHandler}
                      checked={consentCheck}
                    />
                  </div>
                  <div className="content-wrap">
                    I consent to{" "}
                    <a href="" onClick={() => ShareMyInfoShow(true)}>
                      <u>Sharing My Information</u>
                    </a>
                  </div>
                </div> */}
            </div>
            <div className="col-sm-12 center">
              <button type="submit" className="btn redbtn  submit-button">
                sign up
              </button>
            </div>
            <div className="sign-text">
              Already have an account? <Link to="/signin"> Sign in</Link>{' '}
            </div>
          </form>
        </div>
        {/* <div className="footer-text">
          Already have an account? <Link to="/signin"> Sign in</Link>{' '}
        </div> */}
        <ExistingEmailModal show={show} setShow={setShow} />
        <ConsentModal
          setAgreeChecked={setAgreeChecked}
          setDisagreeChecked={setDisagreeChecked}
          show={consentShow}
          setConsentShow={setConsentShow}
          submitHandler={submitHandler}
        />
        <ShareMyInfoModal
          setAgreeChecked={setAgreeChecked}
          setDisagreeChecked={setDisagreeChecked}
          show={ShareMyInfo}
          ShareMyInfoShow={ShareMyInfoShow}
          setConsentCheck={setConsentCheck}
          setOptoutShow={setOptoutShow}
        />
        <OptOutModal show={OptoutShow} setConsentCheck={setConsentCheck} setOptoutShow={setOptoutShow} />
      </div>
    </>
  );
};

export default SignUp;
