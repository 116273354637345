import {checkDomain} from '.';

export const dirtcheap = "https://ilovedirtcheap.com";
export const buildingsupplies = "https://ilovedirtcheapbuildingsupplies.com";
export const treasurehunt = "https://ilovetreasurehunt.com";
export const treasurehuntcanada = "https://ilovetreasurehunt.ca";
export const youtube = 'https://www.youtube.com';
export const tiktok = 'https://www.tiktok.com';
export const twitter = 'https://twitter.com';
export const instagram = 'https://www.instagram.com';
export const facebook = 'https://www.facebook.com';

let homelink = '';
let aboutlink = '';
let locationslink = '';
let careerslink = '';
let contactlink = '';
let whatsinstorelink = '';
let footerlink = '';
let facebooklink = '';
let instagramlink = '';
let tiktoklink = '';
let youtubelink = '';
let twitterlink = '';
let base = '';
checkDomain();
let domain = localStorage.getItem('store_type');

switch (domain) {
  case 'DC':
    base = 'ilovedirtcheap';
    homelink = dirtcheap;
    aboutlink = dirtcheap + '/about';
    locationslink = dirtcheap + '/locations';
    careerslink = dirtcheap + '/careers';
    contactlink = dirtcheap + '/contact';
    whatsinstorelink = dirtcheap + '/whats-in-store';
    footerlink = 'Dirt Cheap, dealfinder.ilovedirtcheap.com';
    facebooklink = facebook + '/ilovedirtcheap';
    twitterlink = twitter + '/ilovedcdeals';
    youtubelink = youtube + '/channel/UCuxZubIAxIZgSrfuk9m14AQ/videos';
    tiktoklink = tiktok + '/@dirt.cheap';
    instagramlink = instagram + '/iLoveDirtCheap';
    break;
  case 'DCBS':
    base = 'ilovedirtcheapbuildingsupplies';
    homelink = buildingsupplies;
    aboutlink = buildingsupplies + '/about';
    locationslink = buildingsupplies + '/locations';
    careerslink = buildingsupplies + '/careers';
    contactlink = buildingsupplies + '/contact';
    whatsinstorelink = buildingsupplies + '/whats-in-store';
    footerlink = 'Dirt Cheap Building Supplies, dealfinder.ilovedirtcheapbuildingsupplies.com';
    facebooklink = facebook + '/iLoveDirtCheapBuildingSupplies';
    instagramlink = instagram + '/ilovedcbs';
    break;
  case 'TH':
    base = 'ilovetreasurehunt';
    homelink = treasurehunt;
    aboutlink = treasurehunt + '/about';
    locationslink = treasurehunt + '/locations';
    careerslink = treasurehunt + '/careers';
    contactlink = treasurehunt + '/contact';
    whatsinstorelink = treasurehunt + '/whats-in-store';
    footerlink = 'Treasure Hunt, dealfinder.ilovetreasurehunt.com';
    facebooklink = facebook + '/ilovetreasurehunt';
    instagramlink = instagram + '/ilovetreasurehunt';
    break;
  case 'CANADA':
    base = 'ilovetreasurehunt';
    homelink = treasurehuntcanada;
    aboutlink = treasurehuntcanada + '/about';
    locationslink = treasurehuntcanada + '/locations';
    careerslink = treasurehuntcanada + '/careers';
    contactlink = treasurehuntcanada + '/contact';
    whatsinstorelink = treasurehuntcanada + '/whats-in-store';
    footerlink = 'Treasure Hunt Canada, dealfinder.ilovetreasurehunt.ca';
    facebooklink = facebook + '/TreasureHuntCanada';
    instagramlink = instagram + '/treasurehuntcanada';
    break;
}

export const about = aboutlink;
export const locations = locationslink;
export const careers = careerslink;
export const contact = contactlink;
export const home = homelink;
export const whatsinstore = whatsinstorelink;
export const footer = footerlink;
export const facebookhandle = facebooklink;
export const twitterhandle = twitterlink;
export const youtubehandle = youtubelink;
export const tiktokhandle = tiktoklink;
export const instagramlhandle = instagramlink;
export const domainName = base;
