import {Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {useDispatch} from 'react-redux';
import {signUpActions} from '../../Store/signUpSlice';

const OptOutModal = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkHandler = (e) => {
    let id = e.target.id;
    if (id == 'yes') {
      dispatch(signUpActions.reset());
      props.setOptoutShow(false);
      history.push('/');
    } else {
      props.setConsentCheck(true);
      props.setOptoutShow(false);
    }
  };

  return (
    <div>
      <Modal show={props.show} backdrop="static" keyboard={false}>
        <div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="modalcontent">
                  <div className="consent">
                    <p>"Are you opting out of our Dealfinder Loyalty Program?"</p>
                  </div>
                  <br />
                  <button id="yes" onClick={checkHandler} className="btn redbtn submit-button">
                    Yes
                  </button>
                  <button id="no" onClick={checkHandler} className="btn yellowbtn submit-button">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OptOutModal;
