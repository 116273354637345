import * as Links from '../../Utils/Links';
import FacebookLogo from '../../assets/images/facebook.png';
import InstagramLogo from '../../assets/images/insta.png';
import TiktokLogo from '../../assets/images/tiktokN.png';
import YoutubeLogo from '../../assets/images/youtube.png';
import TwitterLogo from '../../assets/images/twitter.png';

export const DC_Social = () => {
  return (
    <>
      <ul>
        <li>
          {' '}
          <a href={Links.facebookhandle} className="fatik">
            <img src={FacebookLogo} className="tik" />
          </a>
        </li>
        <li>
          {' '}
          <a href={Links.instagramlhandle} className="fatik">
            <img src={InstagramLogo} className="tik" />
          </a>
        </li>
        <li>
          {' '}
          <a href={Links.tiktokhandle} className="fatik">
            <img src={TiktokLogo} className="tik" />
          </a>
        </li>
        <li>
          {' '}
          <a href={Links.youtubehandle} className="fatik">
            <img src={YoutubeLogo} className="tik" />
          </a>
        </li>
        {/* <li>
          {' '}
          <a href={Links.twitterhandle} className="fatik">
            <img src={TwitterLogo} className="tik" />
          </a>
        </li> */}
      </ul>
    </>
  );
};

export const Other_Social = () => {
  return (
    <>
      <ul>
        <li>
          {' '}
          <a href={Links.facebookhandle} className="fatik">
            <img src={FacebookLogo} className="tik" />
          </a>
        </li>
        <li>
          {' '}
          <a href={Links.instagramlhandle} className="fatik">
            <img src={InstagramLogo} className="tik" />
          </a>
        </li>
      </ul>
    </>
  );
};
