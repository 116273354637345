import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Modal, Button, Form} from 'react-bootstrap';
import {storeActions} from '../../Store/storeSlice';
import {flagActions} from '../../Store/flagSlice';
import {Link} from 'react-router-dom';
import * as API from '../../Utils/Global.js';
import {ThreeDots} from 'react-loader-spinner';
import {checkMobileDevice} from '../../Utils';
import * as Links from '../../Utils/Links';

var obj = null;
const PreScan = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const GlobalStore = useSelector((state) => state.store);
  const [defaultState, setDefaultState] = useState('');
  const [defaultStore, setDefaultStore] = useState({
    id: 0,
    name: '',
  });
  const [storeList, setStoreList] = useState([]);
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState();

  if (GlobalStore.stores.length == 0) {
    fetch(API.store)
      .then((response) => response.json())
      .then((resp) => {
        dispatch(storeActions.stores({stores: resp.stores}));
        getStateList(resp.stores);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // localStorage.setItem()
  let storeId = 0;
  let states = [];
  let storelist = [];

  useEffect(() => {
    // switch (document.referrer) {
    //   case Links.buildingsupplies:
    //     dispatch(flagActions.setIddFlag(true));
    //     dispatch(flagActions.setIddUrl(document.referrer));
    //     break;

    //   case Links.treasurehunt:
    //     dispatch(flagActions.setIddFlag(true));
    //     dispatch(flagActions.setIddUrl(document.referrer));
    //     break;

    //   case Links.dirtcheap:
    //     dispatch(flagActions.setIddFlag(true));
    //     dispatch(flagActions.setIddUrl(document.referrer));
    //     break;
    // }
    if (GlobalStore.state == '' || GlobalStore.store.name == '') {
      history.push('/');
    }
  }, [GlobalStore.state, GlobalStore.store.name]);

  useEffect(() => {
    if (!checkMobileDevice()) {
      handleShow('desktop');
    }
  }, []);
  //FUNCTIONS
  const handleShow = (msg) => {
    switch (msg) {
      case 'desktop':
        setMsg(
          <p>
            <strong>
              Visit this page on your mobile <br />
              device to check individual discounts <br />
              on great items in store! <br />
            </strong>
          </p>,
        );
        break;
    }
    setShow(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
    // if (price <= 0 && price != null) {
    //   sendData();
    // }
    // else {
    //cameraStanby();
    history.push('/profile');
    //}
  };
  function getStateList(stores) {
    var states = [];
    for (let i = 0; i < stores.length; i++) {
      if (states.length == 0) {
        states.push(stores[i].state);
      }
      if (states.indexOf(stores[i].state) == -1) {
        states.push(stores[i].state);
      }
    }
    // dispatch(storeActions.stateList({statelist:states}));
  }

  function getStoreList(stores, state) {
    storelist = [];
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].state == state)
        storelist.push({
          id: stores[i].id,
          name: stores[i].name,
        });
    }
    storelist = storelist.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    setStoreList(storelist);
    // dispatch(storeActions.storeList({storelist:storelist}));
  }

  const stateChange = (event) => {
    setDefaultState(event.target.value);
    getStoreList(GlobalStore.stores, event.target.value);
  };

  const storeChange = (event) => {
    GlobalStore.stores.forEach((element) => {
      if (element.id == event.target.value) {
        setDefaultStore({
          id: element.id,
          name: element.name,
        });
      }
    });
  };

  const save = (e) => {
    e.preventDefault();
    var content = document.getElementsByClassName('content')[0];
    if (defaultState != '') {
      dispatch(storeActions.stateChange({state: defaultState}));
      dispatch(storeActions.storeList({storelist: storeList}));
      setDefaultState('');
      setStoreList([]);
      content.style.display = 'none';
    }
    if (defaultStore.id != 0) {
      dispatch(
        storeActions.storeChange({
          id: defaultStore.id,
          name: defaultStore.name,
        }),
      );
      setDefaultStore({
        id: 0,
        name: '',
      });
      content.style.display = 'none';
    }
  };

  const scanNow = () => {
    history.push('/scan');
  };

  const display = () => {
    var content = document.getElementsByClassName('content')[0];
    if (content.style.display === 'block') {
      content.style.display = 'none';
      setDefaultState('');
      setStoreList([]);
      setDefaultStore({
        id: 0,
        name: '',
      });
    } else {
      content.style.display = 'block';
    }
  };

  return (
    <>
    <div className="clearfix"></div>
      <div className="col-sm-12">
        <div className="container main-wrap">
          <div className="">
            <div className="collapsible" data-toggle="tooltip" onClick={display} title="Change Store">
              {GlobalStore.store.name}, {GlobalStore.state} <i className="fa fa-solid fa-angle-down"></i>
            </div>
            <div className="content">
              <form onSubmit={save}>
                <div className="">
                  <div className="col-md-6 col-sm-6 col-xs-6 ">
                    <select
                      className="form-select form-select-sm"
                      onChange={stateChange}
                      value={defaultState == '' ? GlobalStore.state : defaultState}
                      required>
                      {/* <option value={""}>Select State</option>
                      {GlobalStore.statelist.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))} */}
                      {localStorage.getItem('store_type') === 'CANADA' ? (
                        <>
                          <option value={''}>Select Province</option>
                          {GlobalStore.statelist.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}{' '}
                        </>
                      ) : (
                        <>
                          <option value={''}>Select State</option>
                          {GlobalStore.statelist.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}{' '}
                        </>
                      )}
                    </select>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-6 ">
                    <select
                      className="form-select form-select-sm"
                      onChange={storeChange}
                      value={defaultStore.id == 0 ? GlobalStore.store.id : defaultStore.id}
                      required>
                      <option value={''}>Select Store</option>
                      {storeList.length == 0
                        ? GlobalStore.storelist.map((store) => (
                            <option key={store.id} value={store.id}>
                              {store.name}
                            </option>
                          ))
                        : storeList.map((store) => (
                            <option key={store.id} value={store.id}>
                              {store.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 center">
                  <button type="submit" className="btn redbtn  submit-button">
                    save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="">
            {' '}
            <div className="heading-wrap">
              <h2>scan product</h2>
              <h3 style={{ color: '#ff0000', padding: '0 50px', 'text-align': 'center', 'font-weight': 'bold', 'font-size': '20px' }} >Deals are always changing, scanned price is not guaranteed.</h3>
            </div>{' '}
          </div>
          <div id="cam" className="prescan"></div>
          <div className="form-wrap">
            <div className="col-sm-12 center">
              <button onClick={scanNow} className="btn redbtn  submit-button">
                Start Camera
              </button>
            </div>
          </div>
          <div></div>
          {/* <div className="top-pad"></div> */}
          <div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
              <form onSubmit={handleClose}>
                <div>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="modalcontent">
                          <div className="message-img"></div>
                          <br />
                          {msg}
                          <div className="modalbutton">
                            <button
                              type="submit"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              // onClick={handleClose}
                              className="btn redbtn  submit-button">
                              OK
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Modal>
          </div>
          {localStorage.getItem('user_token') ? (
            <></>
          ) : (
          <>
            <div className="main-sign-wrap" >
              <div className="sign-wrap">
              {(localStorage.getItem("store_type")==("DC")||localStorage.getItem("store_type")=="TH")?<h3>Sign up for alerts & loyalty rewards!</h3>:
                <h3>Sign up for alerts & more!</h3>}
                <button className="btn yellowbtnsmall  submit-button" onClick={() => history.push('/loyalty')}>Click here</button>
              </div> 
            </div>
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default PreScan;
