import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import * as API from '../../Utils/Global';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
let _ = require('underscore');

const PurchaseHistory = () => {
  // const [PurchaseHistory, setPurchaseHistory] = useState([]);
  const [purchaseHistorySorted, setPurchaseHistorySorted] = useState();
  const [isPageLoading, setIsPageLoading] = useState(true);

  const UserId = localStorage.getItem('user_id');
  const history = useHistory();
  const [MemberId, setMemberId] = useState(localStorage.getItem('member_id'));

  const [isMoreData, setIsMoreData] = useState(false);

  const [totalNoPages, setTotalNoPages] = useState(0);
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [groupedPurchaseHistory, setGroupedPurchaseHistory] = useState([]);
  const [uniqueTransIDMaster, setUniqueTransIDMaster] = useState([]);
  const pageSize = 2;

  const [selectedPurchaseMonth, setSelectedPurchaseMonth] = useState();
  const [selectedPurchaseYear, setSelectedPurchaseYear] = useState();
  let yearList = [];
  const noOfYearsToDisplay = 10;

  const currentYear = new Date().getFullYear();
  for (let index = 0; index < noOfYearsToDisplay; index++) {
    yearList.push(currentYear - index);
  }

  const monthList = [
    {displayName: 'January', value: 1},
    {displayName: 'February', value: 2},
    {displayName: 'March', value: 3},
    {displayName: 'April', value: 4},
    {displayName: 'May', value: 5},
    {displayName: 'June', value: 6},
    {displayName: 'July', value: 7},
    {displayName: 'August', value: 8},
    {displayName: 'September', value: 9},
    {displayName: 'October', value: 10},
    {displayName: 'November', value: 11},
    {displayName: 'December', value: 12},
  ];

  function loadNextPageData() {
    // console.log("loadNextPageData", currentPageNo);
    if (currentPageNo < totalNoPages) {
      // console.log("page++");
      setCurrentPageNo(currentPageNo + 1);
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  function addMoreTransactionData() {
    // console.log("addMoreTransactionData- totalNoPages", totalNoPages);
    // console.log("addMoreTransactionData- currentPageNo", currentPageNo);

    let sortedArray = [];
    for (var i = 0; i < currentPageNo * pageSize; i++) {
      if (uniqueTransIDMaster[i]) {
        let obj = {
          TransId: uniqueTransIDMaster[i],
          Stores: groupedPurchaseHistory[uniqueTransIDMaster[i]],
        };
        sortedArray.push(obj);
      }
    }
    setPurchaseHistorySorted(sortedArray);
  }

  const getMemberID = async () => {
    await axios
      .post(
        API.profile,
        {UserID: UserId},
        {
          headers: {
            'content-type': 'application/json',
            'x-auth-token': localStorage.getItem('user_token'),
          },
        },
      )
      .then(async (response) => {
        var data = response.data[0];
        setMemberId(data.member_id);
        localStorage.setItem('member_id', data.member_id);
        return data.member_id;
      })
      .catch((err) => {
        console.log('Error while loading member id', err);
      });
  };

  const getPurchaseHistory = async () => {
    setCurrentPageNo(0);
    setTotalNoPages(0);
    const inputParam = {
      member_id: localStorage.getItem('member_id'),
    };

    if (selectedPurchaseYear && selectedPurchaseMonth) {
      inputParam.created_date = `${selectedPurchaseYear}-${selectedPurchaseMonth}-01`;
    }

    await fetch(API.PurchaseHistoryApi, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      // 1000423
      body: JSON.stringify(inputParam),
    })
      .then(async (response) => {
        response = await response.json();
        if (response.length > 0) {
          var groupedPurchasedHistory = _.groupBy(response, 'TRASID');

          setGroupedPurchaseHistory(groupedPurchasedHistory);
          let uniqueTransIDs = Object.keys(groupedPurchasedHistory);
          uniqueTransIDs = uniqueTransIDs.sort().reverse();

          // console.log("uniqueTransIDs", uniqueTransIDs);
          setUniqueTransIDMaster(uniqueTransIDs);

          setTimeout(() => {
            setTotalNoPages(Math.ceil(uniqueTransIDs.length / pageSize));
          }, 50);
        } else {
          setPurchaseHistorySorted([]);
          setIsMoreData(false);
        }
        setIsPageLoading(false);
      })
      .catch((err) => {});
  };

  const onInitPageLoad = async () => {
    // console.log("onInitPageLoad");
    if (MemberId) {
      getPurchaseHistory(MemberId);
    } else {
      let member_id = await getMemberID();
      // console.log("member_id", member_id);
      getPurchaseHistory(member_id);
    }
  };

  const getFilteredData = (event) => {
    event.preventDefault();
    getPurchaseHistory(MemberId);
  };

  const onYearSelect = (event) => {
    event.preventDefault();
    setSelectedPurchaseYear(event.target.value);
    event.target.setCustomValidity('');
  };

  const onMonthSelect = (event) => {
    event.preventDefault();
    setSelectedPurchaseMonth(event.target.value);
    event.target.setCustomValidity('');
  };

  useEffect(() => {
    // console.log("useEffect totalNoPages", totalNoPages, currentPageNo);
    if (totalNoPages > 0) {
      loadNextPageData();
    }
  }, [totalNoPages]);

  useEffect(() => {
    // console.log("useEffect currentPageNo", currentPageNo, totalNoPages);
    if (currentPageNo > 0 && currentPageNo <= totalNoPages) {
      addMoreTransactionData();

      setIsMoreData(true);
    }

    if (currentPageNo >= 1 && currentPageNo == totalNoPages) {
      setIsMoreData(false);
    }
  }, [currentPageNo]);

  useEffect(() => {
    if (UserId == null || UserId == undefined) {
      history.push('/signin');
    } else {
      trackPromise(onInitPageLoad());
    }
  }, []);

  const nullorundefined = (value) => {
    if (value == null || value == undefined) {
      return true;
    }
    return false;
  };
  const getItem = (CATDESC, CAT_DESCRIPTION) => {
    const str = getItemName(CATDESC,CAT_DESCRIPTION);
var Obj = {
  test: "",
  TEST:"",
  Test:"",
  spot: "deal",
  Spot: "Deal",
  SPOT: "DEAL"
};
var newstr = '';
 
var join = Object.keys(Obj).join("|");
var RE = new RegExp("\\b^" + join+"\\b" , "g");
newstr = str.replace(RE, function(matched) {
  return Obj[matched];
});
    // const result = removeFromString(["Test", "TEST", "test", "spot", "Spot","SPOT"], str);
    return newstr;
  };
  const getItemName = (CATDESC, CAT_DESCRIPTION) => {
    if (nullorundefined(CATDESC)) {
      if (nullorundefined(CAT_DESCRIPTION)) {
        return "";
      }
      return CAT_DESCRIPTION
        ? ""
        : CAT_DESCRIPTION;
    }
    let value = (
      CATDESC.includes(CAT_DESCRIPTION)
        ? CATDESC
        : CAT_DESCRIPTION + " " + CATDESC
    ).substring(0, 50);
    return value;
  };
  function removeFromString(words, str) {
    return words.reduce((result, word) => result.replace(word, ""), str);
  }


  return (
    <>
      <div className="clearfix"></div>
      <div className=" container main-wrap">
        <div className="heading-wrap">
          <h2>Purchase History</h2>
        </div>

        <div className="form-wrap">
          <form onSubmit={getFilteredData}>
            <div className="row form_inner_wrap">
              <div className="col-md-6 col-sm-6 col-xs-6 ">
                <select
                  className="form-select form-select-sm"
                  onChange={onYearSelect}
                  // defaultValue={new Date().getFullYear()}
                  onInvalid={(e) => {
                    e.target.setCustomValidity('Please select a year');
                  }}
                  required>
                  <option value={''}>Select Year</option>
                  {yearList.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <select
                  className="form-select form-select-sm"
                  onChange={onMonthSelect}
                  // defaultValue={monthList[new Date().getMonth()].value}
                  onInvalid={(e) => {
                    e.target.setCustomValidity('Please select a month');
                  }}
                  required>
                  <option value={''}>Select Month</option>
                  {monthList.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-12 center">
              <button type="submit" className="btn redbtn submit-button">
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* Purchase table start  */}
        <div className="col-md-12 center" id="Purchase">
          {isPageLoading ? (
            <div> </div>
          ) : purchaseHistorySorted && purchaseHistorySorted.length == 0 ? (
            <div>
              <h3>
                Sorry!
                <br />
                No Purchase History found.
              </h3>
            </div>
          ) : (
            purchaseHistorySorted &&
            purchaseHistorySorted.map((el) => {
              let srNo = 0;
              const DateTime = el.Stores[0]?.CREATEDATE;
              const dateFormat =
                DateTime.split('T')[0].split('-')[1] + '-' + DateTime.split('T')[0].split('-')[2] + '-' + DateTime.split('T')[0].split('-')[0];

              return (
                <div className="col-md-12 center" id="Purchase" key={el.BARCODE_ID + '' + Math.random()}>
                  <div className="Purchase-wrap">
                    <div className="Purchase-main">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 s-12">
                          <div className="state_text">
                            <strong>Transaction Id: </strong>
                            {el.TransId}
                            <br />
                            <strong>Store Name: </strong>
                            {el.Stores[0].STORE_NAME}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 s-12">
                          <div className="date_text">
                            <strong>Purchase Date: </strong> {dateFormat}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Purchase-table">
                      <table>
                        <tbody>
                          <tr>
                            <th style={{width: '5%'}}>No</th>
                            <th style={{width: '35%'}}>Item</th>
                            <th style={{width: '20%'}} className="prices">
                              Retail Price
                            </th>
                            <th style={{width: '10%'}} className="center">
                              Disc
                            </th>
                            <th style={{width: '10%'}} className="prices">
                              Disc Price
                            </th>
                            <th style={{width: '10%'}} className="center">
                              Qty
                            </th>
                            <th style={{width: '20%'}} className="prices">
                              Total Price
                            </th>
                          </tr>
                          {el.Stores.map((store) => (
                            <tr key={store.BARCODE_ID + srNo + '' + Math.random()}>
                              <td>{++srNo}</td>
                              <td>{getItem(store.CATDESC, store.CAT_DESCRIPTION)}</td>
                              <td className="prices">${store.PRICE}</td>
                              <td className="center">{store.PERCENTAGE == null ? '--' : store.PERCENTAGE + '%'}</td>
                              <td className="prices">${store.EFFECTIVEPRICE}</td>
                              <td className="center">{store.QUANTITY}</td>
                              <td className="prices">${store.NEWPRICE}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td width="90%" className="textboldRight">
                            Subtotal:
                          </td>
                          <td width="10%" className="textbold prices">
                            ${el.Stores[0].ITEM_TOTAL}
                          </td>
                        </tr>
                        <tr>
                          <td className="textboldRight">Tax:</td>
                          <td className="textbold prices">${el.Stores[0].TAX}</td>
                        </tr>
                        <tr>
                          <td className="textboldRight">Total Amount:</td>
                          <td className="textbold prices">${el.Stores[0].AMOUNT_TENDERED}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })
          )}
        </div>

        {isMoreData && (
          <>
            <div className="col-sm-12 center mb-4">
              <button className="btn redbtn submit-button" onClick={loadNextPageData}>
                Load More
              </button>
            </div>
            <div className="top-pad-store"></div>
          </>

          // <div className="row">
          //   <button
          //     className="btn mainbtn submit-button"
          //     onClick={loadMoreData}
          //   >
          //     Show More
          //   </button>
          // </div>
        )}
      </div>
    </>
  );
};

export default PurchaseHistory;
