import {Link} from 'react-router-dom';
import * as Links from '../../Utils/Links';

const Container = (props) => {
  const closeNav = () => {
    document.getElementById('mySidenav').style.width = '0px';
  };

  // const closeNavsub = () => {
  //   document.getElementById('subNav').style.width = '0px';
  // };
  return (
    <div>
      <div className="logo_drawver">
        <div className="mobile-wrapper mobileOnly sidenav" id="mySidenav">
          <ul className="mobileStores">
            <a className="closebtn" onClick={closeNav}>
              &times;
            </a>
            <li className="selectstore">Select a Store</li>
            <li className={'dirtcheap' + (localStorage.getItem('store_type') === 'DC' ? ' active' : '')}>
              <a href={Links.dirtcheap}>
                <span className="icon"></span>Dirt Cheap
              </a>
            </li>
            <li className={'treasurehunt' + (localStorage.getItem('store_type') === 'TH' ? ' active' : '')}>
              <a href={Links.treasurehunt}>
                <span className="icon"></span>Treasure Hunt
              </a>
            </li>
            <li className={'dirtcheapbuilding' + (localStorage.getItem('store_type') === 'DCBS' ? ' active' : '')}>
              <a href={Links.buildingsupplies}>
                <span className="icon"></span>Dirt Cheap
                <br /> Building Supplies
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="mydropnav">
        <ul className="list-group">
          <li className="list-group-item">
            <a href={Links.home}>Home</a>
          </li>
          <li className="list-group-item">
            <a href={Links.about}>About</a>
          </li>
          <li className="list-group-item">
            <a href={Links.whatsinstore}>What's In Store</a>
          </li>
          <li className="list-group-item">
            <a href={Links.locations}>Location</a>
          </li>
          <li className="list-group-item">
            <a href={Links.careers}>Careers</a>
          </li>
          <li className="list-group-item">
            <a href={Links.contact}>Contact</a>
          </li>
        </ul>
      </div>
      <div>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default Container;
