import {createSlice} from '@reduxjs/toolkit';
//   {
//     "user_id" : 26,
//     "deliver_email" : 1,
//     "deliver_sms" : 1,
//     "subscriber_categories" : [1,2,3],
//     "subscriber_locations" : [1,2,99]
// }
const initialState = {
  stores: [],
  stateList: [],
  sorted: [],
  categories: [],
  deliver_email: 1,
  deliver_sms: 1,
  pref_id: 0,
  subscriber_categories: [],
  subscriber_locations: [],
};

const eAlertSlice = createSlice({
  name: 'eAlert',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setPrefId(state, action) {
      state.pref_id = action.payload;
    },
    setStores(state, action) {
      state.stores = action.payload.stores;
    },
    setStateList(state, action) {
      state.stateList = action.payload.stateList;
    },
    setSorted(state, action) {
      state.sorted = action.payload.sorted;
    },
    setCategories(state, action) {
      state.categories = action.payload.categories;
    },
    addCategories(state, action) {
      state.subscriber_categories = action.payload.categories;
    },
    setLocations(state, action) {
      state.subscriber_locations = action.payload.locations;
    },
    setEmailPref(state, action) {
      state.deliver_email = action.payload.email;
    },
    setSmsPref(state, action) {
      state.deliver_sms = action.payload.sms;
    },
  },
});

export const eAlertActions = eAlertSlice.actions;
export default eAlertSlice;
