import {useDispatch, useSelector} from 'react-redux';
// import "../../css/eAlert.css";
import * as API from '../../Utils/Global';
import {eAlertActions} from '../../Store/eAlertSlice';
import {Link} from 'react-router-dom';
import State from './State';
import Tab from './Tab';
import {profileActions} from '../../Store/profileSlice';
import $ from 'jquery';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let mapped = [];
let locations = [];
const Preferences = () => {
  var letter = 0;
  var tabletter = 0;
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.eAlert);
  var email = useSelector((state) => state.eAlert.deliver_email);
  var sms = useSelector((state) => state.eAlert.deliver_sms);
  const Profile = useSelector((state) => state.profile);
  const history = useHistory();
  var user_id = window.localStorage.getItem('user_id');
  const [mounted, setMounted] = useState(false);
  const sort = (st, states) => {
    var sl = st;
    var statelist = states;
    var sorted = [];

    for (var i = 0; i < statelist.length; i++) {
      var th = [];
      var dc = [];
      var dcbs = [];
      var canada = [];

      for (var j = 0; j < sl.length; j++) {
        if (statelist[i] === sl[j].state) {
          if (sl[j].store_type == 'TH') {
            th.push({
              store_id: sl[j].store_id,
              idd_store_id: sl[j].idd_store_id,
              name: sl[j].name,
            });
          }
          if (sl[j].store_type == 'DC') {
            dc.push({
              store_id: sl[j].store_id,
              idd_store_id: sl[j].idd_store_id,
              name: sl[j].name,
            });
          }
          if (sl[j].store_type == 'DCBS') {
            dcbs.push({
              store_id: sl[j].store_id,
              idd_store_id: sl[j].idd_store_id,
              name: sl[j].name,
            });
          }
          if (sl[j].store_type == 'CANADA') {
            canada.push({
              store_id: sl[j].store_id,
              idd_store_id: sl[j].idd_store_id,
              name: sl[j].name,
            });
          }
        }
      }
      th = th.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      dc = dc.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      dcbs = dcbs.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      canada = canada.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      sorted.push({
        state: statelist[i],
        stores: [
          {
            store_type: 'TH',
            allstores: th,
          },
          {
            store_type: 'DC',
            allstores: dc,
          },
          {
            store_type: 'DCBS',
            allstores: dcbs,
          },
          {
            store_type: 'CANADA',
            allstores: canada,
          },
        ],
      });
    }
    dispatch(eAlertActions.setSorted({sorted: sorted}));
  };
  const getProfileDetails = () => {
    $.ajax({
      url: API.profile,
      type: 'POST',
      async: false,
      accepts: 'application/json',
      contentType: 'application/json',
      data: JSON.stringify({
        UserID: user_id,
      }),
      headers: {
        'x-auth-token': localStorage.getItem('user_token'),
      },
      success: function (response) {
        var locations = [];
        if (response[0].favStores != null) {
          var ar = response[0].favStores.split(',');
          ar.map((el) => locations.push(parseInt(el)));
        }
        dispatch(eAlertActions.setLocations({locations: locations}));
        dispatch(profileActions.setDet(response));
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
  };
  const nextClick = () => {
    history.push('/categories');
  };

  const emailCheck = (e) => {
    var pref;
    if (e.target.checked) {
      pref = 1;
    } else {
      pref = 0;
    }
    dispatch(eAlertActions.setEmailPref({email: pref}));
    fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: pref,
        deliver_sms: stores.deliver_sms,
        subscriber_categories: stores.subscriber_categories,
        subscriber_locations: stores.subscriber_locations,
      }),
    })
      .then((raw) => {
        console.log(raw);
      })
      .catch((err) => {
        if (!e.target.checked) {
          pref = 1;
          $('#emailCheck').prop('checked', true);
        } else {
          pref = 0;
          $('#emailCheck').prop('checked', false);
        }
        dispatch(eAlertActions.setEmailPref({email: pref}));
        toast.error(err.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };
  function getStateList(stores) {
    var states = [];
    for (let i = 0; i < stores.length; i++) {
      if (states.length == 0) {
        states.push(stores[i].state);
      }
      if (states.indexOf(stores[i].state) == -1) {
        states.push(stores[i].state);
      }
    }
    states = states.sort();
    dispatch(eAlertActions.setStateList({stateList: states}));
    return states;
  }
  const smsCheck = (e) => {
    var pref;
    if (e.target.checked) {
      pref = 1;
    } else {
      pref = 0;
    }
    dispatch(eAlertActions.setSmsPref({sms: pref}));
    fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: stores.deliver_email,
        deliver_sms: pref,
        subscriber_categories: stores.subscriber_categories,
        subscriber_locations: stores.subscriber_locations,
      }),
    })
      .then((raw) => {
        console.log(raw);
      })
      .catch((err) => {
        if (!e.target.checked) {
          pref = 1;
          $('#smsCheck').prop('checked', true);
        } else {
          pref = 0;
          $('#smsCheck').prop('checked', false);
        }
        dispatch(eAlertActions.setSmsPref({sms: pref}));
        toast.error(err.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };
  if (stores.stores.length == 0) {
    let country = localStorage.getItem('store_type');
    var url = '';
    if (country.toLocaleLowerCase() == 'canada') {
      url = API.eAlertStores + '?store_type=CANADA';
    } else {
      url = API.eAlertStores;
    }

    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      success: function (response) {
        mapped = response.stores;
        dispatch(eAlertActions.setStores({stores: response.stores}));
        var states = getStateList(response.stores);
        sort(response.stores, states);
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
  }
  if (Profile.email == '') {
    getProfileDetails();
  }
  if (!mounted) {
    // if (stores.pref_id == 0) {
    $.ajax({
      url: API.userPref,
      type: 'POST',
      async: false,
      accepts: 'application/json',
      contentType: 'application/json',
      data: JSON.stringify({
        user_id: user_id,
      }),
      headers: {
        'x-auth-token': localStorage.getItem('user_token'),
      },
      success: async function (response) {
        var subscriber_categories = [];
        var subscriber_locations = [];
        var isEmpty = $.isEmptyObject(response);
        if (!isEmpty) {
          if (response.subscriber_categories != '') {
            var sub_cat = response.subscriber_categories.split(',');
            sub_cat.map((el) => subscriber_categories.push(parseInt(el)));
          }
          if (response.subscriber_locations_email != '') {
            var sub_loc = response.subscriber_locations_email.split(',');
            sub_loc.map((el) => subscriber_locations.push(parseInt(el)));
          }
          locations = subscriber_locations;
        }

        var deliver_sms = response.deliver_sms;
        var deliver_email = response.deliver_email;

        dispatch(eAlertActions.setPrefId(response.pref_id));
        dispatch(eAlertActions.setEmailPref({email: deliver_email}));
        dispatch(eAlertActions.setSmsPref({sms: deliver_sms}));
        dispatch(eAlertActions.addCategories({categories: subscriber_categories}));
        dispatch(eAlertActions.setLocations({locations: subscriber_locations}));
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
    // }
  }

  useEffect(() => {
    setMounted(true);
    // if (Profile.state == "") {
    //   $("#tab-0").addClass("active");
    //   $("#pane-0").addClass("show active");
    //   $("#collapse-0").addClass("show");
    // }
  }, []);

  useEffect(() => {
    if (user_id == null || user_id == '') {
      history.push('/signin');
    }
    return () => {
      locations = [];
    };
  });

  return (
    <div>
      <div className="clearfix"></div>
      <div className="container main-wrap">
        <div className="heading-wrap headtop">
          <h2 className='ealert'>eAlert Preferences</h2>
        </div>
        <div className="container heading-wrap">
          <div className="card-header1">Select your preference for eAlert</div>
          <div className="card-body1">
            <div className="row">
              <div className="col-am-12">
                <ul className="list-check" tabIndex={-1}>
                  <li className="list1">
                    <input id="emailCheck" type="checkbox" checked={email} className="checkbox" onChange={emailCheck} />
                    Email
                  </li>
                  &nbsp;&nbsp;
                  <li className="list1">
                    <input id="smsCheck" type="checkbox" checked={sms} className="checkbox" onChange={smsCheck} />
                    Text (SMS)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="location-header">Set your Subscription Locations</div>
        <section className="ourwork-tab-wrap">
          <div className="container">
            <br />
            <ul id="tabs" className="nav nav-tabs" role="tablist">
              {stores.sorted.map((state) => (
                <Tab idletter={tabletter++} state={state.state} class={state.state == Profile.state ? 'active' : ''} />
              ))}
            </ul>
            <div id="content" className="tab-content" role="tablist">
              {stores.sorted.map((state) => (
                <State
                  idletter={letter++}
                  state={state.state}
                  storelist={state.stores}
                  class={state.state == Profile.state ? 'show ' : ''}
                  tabclass={state.state == Profile.state ? 'show active' : ''}
                  locations={locations}
                />
              ))}
            </div>
            <div className="col-sm-12 center">
              <div className="top-pad">&nbsp;</div>
              <button className="btn redbtn  submit-button" onClick={nextClick}>
                Next
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Preferences;
