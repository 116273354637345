import {createSlice} from '@reduxjs/toolkit';

const storeSlice = createSlice({
  name: 'store',
  initialState: {
    state: '',
    store: {
      id: 0,
      name: '',
    },
    id: 0,
    statelist: [],
    storelist: [],
    stores: [],
  },
  reducers: {
    stateChange(state, action) {
      state.state = action.payload.state;
    },
    stateList(state, action) {
      state.statelist = action.payload.statelist;
    },
    storeChange(state, action) {
      state.store.id = action.payload.id;
      state.store.name = action.payload.name;
    },
    storeList(state, action) {
      state.storelist = action.payload.storelist;
    },
    stores(state, action) {
      state.stores = action.payload.stores;
    },
    setId(state, action) {
      state.id = action.payload.id;
    },
  },
});

export const storeActions = storeSlice.actions;
export default storeSlice;
