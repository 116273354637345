import {useDispatch, useSelector} from 'react-redux';
// import "../../css/eAlert.css";
import * as API from '../../Utils/Global';
import {eAlertActions} from '../../Store/eAlertSlice';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const EStores = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const dispatch = useDispatch();
  var Profile = useSelector((state) => state.profile);
  var eAlert = useSelector((state) => state.eAlert);
  var favStores = Profile.favStores.map((el) => parseInt(el));
  const [mounted, setMounted] = useState(false);
  var locations = props.locations;
  var idletter = props.idletter;
  var storelist = props.storelist;
  var th = storelist[0].allstores;
  var dc = storelist[1].allstores;
  var dcbs = storelist[2].allstores;
  var canada = storelist[3].allstores;
  var user_id = localStorage.getItem('user_id');
  var classes = 'collapse ' + props.class;
  var i = 0;

  const checkHandler = async (e, id) => {
    var locations = [...eAlert.subscriber_locations];

    if (e.target.checked) {
      locations.push(id);
    } else {
      const index = locations.indexOf(id);
      if (index > -1) {
        locations.splice(index, 1);
      }
    }
    dispatch(eAlertActions.setLocations({locations: locations}));
    var res = await fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: eAlert.deliver_email != null ? eAlert.deliver_email : 1,
        deliver_sms: eAlert.deliver_sms != null ? eAlert.deliver_sms : 1,
        subscriber_categories:
          eAlert.subscriber_categories != null ? eAlert.subscriber_categories : [],
        subscriber_locations: locations,
      }),
    })
      .then((raw) => {})
      .catch((err) => {
        var locs = [...locations];
        if (!e.target.checked) {
          locs.push(id);
          $('#' + id).prop('checked', true);
        } else {
          const index = locations.indexOf(id);
          if (index > -1) {
            locs.splice(index, 1);
            $('#' + id).prop('checked', false);
          }
        }
        dispatch(eAlertActions.setLocations({locations: locs}));
        toast.error(err.message, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      });
  };

  return (
    <div
      id={'collapse-' + idletter}
      className={classes + ' collapsesshow'}
      data-parent="#content"
      role="tabpanel"
      aria-labelledby={'heading-' + idletter}
      // style=""
    >
      <div className="card-body new-card-body">
        <div className="row" tabIndex={-1}>
          {dc.length > 0 ? (
            <div className="col-md-12">
              <h2 className="head-sub">Dirt Cheap</h2>
              <ul key={i++} className="list-check">
                {dc.map((store) => (
                  <li key={store.idd_store_id} className="list">
                    <input
                      key={store.idd_store_id + '/' + store.store_id}
                      id={store.idd_store_id.toString()}
                      type="checkbox"
                      className="checkbox"
                      defaultChecked={locations.indexOf(store.idd_store_id) != -1}
                      // onChange={checkHandler}
                      onChange={(event) => checkHandler(event, store.idd_store_id)}
                    />
                    {store.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {dcbs.length > 0 ? (
            <div className="col-md-12">
              <h2 className="head-sub">Dirt Cheap Building Supplies</h2>
              <ul key={i++} className="list-check">
                {dcbs.map((store) => (
                  <li key={store.idd_store_id} className="list">
                    <input
                      key={store.idd_store_id + '/' + store.store_id}
                      id={store.idd_store_id.toString()}
                      type="checkbox"
                      className="checkbox"
                      defaultChecked={locations.indexOf(store.idd_store_id) != -1}
                      // onChange={checkHandler}
                      onChange={(event) => checkHandler(event, store.idd_store_id)}
                    />
                    {store.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {th.length > 0 ? (
            <div className="col-md-12">
              <h2 className="head-sub">Treasure Hunt</h2>
              <ul key={i++} className="list-check">
                {th.map((store) => (
                  <li key={store.idd_store_id} className="list">
                    <input
                      key={store.idd_store_id + '/' + store.store_id}
                      id={store.idd_store_id.toString()}
                      type="checkbox"
                      className="checkbox"
                      defaultChecked={locations.indexOf(store.idd_store_id) != -1}
                      // onChange={checkHandler}
                      onChange={(event) => checkHandler(event, store.idd_store_id)}
                    />
                    {store.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {canada.length > 0 ? (
            <div className="col-md-12">
              <h2 className="head-sub">Canada</h2>
              <ul key={i++} className="list-check">
                {canada.map((store) => (
                  <li key={store.idd_store_id} className="list">
                    <input
                      key={store.idd_store_id + '/' + store.store_id}
                      id={store.idd_store_id.toString()}
                      type="checkbox"
                      className="checkbox"
                      defaultChecked={locations.indexOf(store.idd_store_id) != -1}
                      // onChange={checkHandler}
                      onChange={(event) => checkHandler(event, store.idd_store_id)}
                    />
                    {store.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EStores;
