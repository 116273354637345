import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  address_1: '',
  address_2: '',
  phone: '',
  city: '',
  state: '',
  country: '',
  zip: 0,
  cookie_agreement: 0,
  tnc_agreement: 0,
  member_id: '',
  last_login: null,
  home_store: 0,
  favStores: [],
  storeList: [],
  selected: [],
};

const profileSlice = createSlice({
  name: 'edit',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setForm(state, action) {
      switch (action.payload.attr) {
        case 'fName':
          state.first_name = action.payload.attrVal;
          break;
        case 'lName':
          state.last_name = action.payload.attrVal;
          break;
        case 'email':
          state.email = action.payload.attrVal;
          break;
        case 'addr1':
          state.address_1 = action.payload.attrVal;
          break;
        case 'addr2':
          state.address_2 = action.payload.attrVal;
          break;
        case 'phone':
          state.phone = action.payload.attrVal;
          break;
        case 'city':
          state.city = action.payload.attrVal;
          break;
        case 'country':
          state.country = action.payload.attrVal;
          break;
        case 'state':
          state.state = action.payload.attrVal;
          break;
        case 'zip':
          state.zip = action.payload.attrVal;
          break;
        case 'cookie':
          state.cookie_agreement = action.payload.attrVal;
          break;
        case 'tnc':
          state.tnc_agreement = action.payload.attrVal;
          break;
        case 'memberId':
          state.member_id = action.payload.attrVal;
          break;
        case 'lastLogin':
          state.last_login = action.payload.attrVal;
          break;
        case 'favStores':
          state.favStores = action.payload.attrVal;
          break;
      }
    },
    setDet(state, action) {
      state.first_name = action.payload[0].first_name;
      state.last_name = action.payload[0].last_name;
      state.email = action.payload[0].email;
      state.address_1 = action.payload[0].address_1;
      state.address_2 = action.payload[0].address_2;
      state.phone = action.payload[0].phone;
      state.city = action.payload[0].city;
      state.country = action.payload[0].country;
      state.state = action.payload[0].state;
      state.zip = action.payload[0].zip;
      state.cookie_agreement = action.payload[0].cookie_agreement;
      state.tnc_agreement = action.payload[0].tnc_agreement;
      state.member_id = action.payload[0].member_id;
      state.last_login = action.payload[0].last_login;
      state.storeList = action.payload[0].storeList;
      if (action.payload[0].favStores != null && action.payload[0].favStores != '') {
        state.favStores = action.payload[0].favStores.split(',');
      } else {
        state.favStores = [];
      }
      state.home_store = action.payload[0].home_store;
    },
    setStorelist(state, action) {
      state.storeList = action.payload.storelist;
    },
    setSelected(state, action) {
      state.selected = action.payload.selected;
    },
    setFav(state, action) {
      if (action.payload[0].favStores != null && action.payload[0].favStores != '') {
        state.favStores = action.payload[0].favStores.split(',');
      } else {
        state.favStores = [];
      }
    },
  },
});

export const profileActions = profileSlice.actions;
export default profileSlice;
