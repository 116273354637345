export const loadfont = () => {
  const store_type = localStorage.getItem('store_type');
  if (store_type === 'TH') {
    var newStyle = document.createElement('style');
    //     ITC Avant Garde Gothic Pro Bold

    // ITC Avant Garde Gothic Pro Book
    newStyle.appendChild(
      document.createTextNode(
        "\
        @font-face {\
            font-family:sans-serif;\
        font-display: auto;\
        font-style: normal;\
        font-weight: 700;\
        font-stretch: normal;\
      }\
        ",
      ),
    );
    newStyle.appendChild(
      document.createTextNode(
        "\
        @font-face {\
            font-family:itc-avant-garde-gothic-pro;\
            src: url('https://use.typekit.net/af/b89a53/00000000000000007735ba1d/30/l?subset_id=2&fvd=n3&v=3') format('woff2'),\
                url('https://use.typekit.net/af/b89a53/00000000000000007735ba1d/30/d?subset_id=2&fvd=n3&v=3') format('woff'),\
                url('https://use.typekit.net/af/b89a53/00000000000000007735ba1d/30/a?subset_id=2&fvd=n3&v=3') format('opentype');\
        font-display: auto;\
        font-style: normal;\
        font-weight: 400;\
        font-stretch: normal;\
      }\
        ",
      ),
    );
    document.head.appendChild(newStyle);
  } else {
    var newStyle = document.createElement('style');
    newStyle.appendChild(
      document.createTextNode(
        "\
        @font-face {\
            font-family:sans-serif;\
        font-display: auto;\
        font-style: normal;\
        font-weight: 700;\
        font-stretch: normal;\
      }\
        ",
      ),
    );
    newStyle.appendChild(
      document.createTextNode(
        "\
        @font-face {\
            font-family:neue-haas-grotesk-text;\
            src: url('https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),\
                url('https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff'),\
                url('https://use.typekit.net/af/0230dd/00000000000000007735bb33/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('opentype');\
        font-display: auto;\
        font-style: normal;\
        font-weight: 400;\
        font-stretch: normal;\
      }\
        ",
      ),
    );
    document.head.appendChild(newStyle);
  }
};
