import {DC_Social, Other_Social, TH_Social} from './SocialMedia';
import * as Links from '../../Utils/Links';
import {IoMdArrowDropupCircle} from 'react-icons/io';
import {useEffect, useState} from 'react';

const Footer = () => {
  const [showButton, setShowButton] = useState(false);
  let domain = localStorage.getItem('store_type');

  useEffect(() => {
    // Button is displayed after scrolling for 300 pixels
    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisiblity);
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisiblity);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({top: -20, behavior: 'smooth'});
  };

  return (
    <>
      <div className="clearfix"></div>
      <div className="social-container">
        <div className="social-icons">{domain == 'DC' ? <DC_Social /> : <Other_Social />}</div>
      </div>
      <div className="footer"></div>
      <div className="footerstrip"></div>
      <div className="Copyright">Copyright © 2023 CCM LLC, {Links.footer}</div>
      {showButton && (
        <div className={'scrollToTop'}>
          <IoMdArrowDropupCircle  id="scrollbutton" onClick={handleScrollToTop} />
        </div>
      )}
    </>
  );
};

export default Footer;
