import {BarcodeScanner} from 'dynamsoft-javascript-barcode';
import React from 'react';
import './VideoDecode.css';
import {ThreeDots} from 'react-loader-spinner';
import '../../dbr';

class VideoDecode extends React.Component {
  constructor(props) {
    super(props);
    this.pScanner = null;
    this.elRef = React.createRef();
  }
  async componentDidMount() {
    try {
      const scanner = await (this.pScanner = BarcodeScanner.createInstance());
      scanner.setUIElement(this.elRef.current);
      let runtimeSettings = await scanner.getRuntimeSettings();
      runtimeSettings.barcodeFormatIds = 0x10000000;
      runtimeSettings.expectedBarcodesCount = 1;
      await scanner.updateRuntimeSettings(runtimeSettings);
      await scanner.open();
      scanner.onUniqueRead = async (txt, result) => {
        let scanSettings = await scanner.getScanSettings();
        scanSettings.whenToPlaySoundforSuccessfulRead = 'frame';
        scanSettings.whenToVibrateforSuccessfulRead = 'frame';
        await scanner.updateScanSettings(scanSettings);
        this.props.callback(result.barcodeText);
        scanner.close();
      };
    } catch (ex) {
      console.error(ex);
    }
  }
  async componentWillUnmount() {
    if (this.pScanner) {
      (await this.pScanner).close();
      //console.log('BarcodeScanner Component Unmount');
    }
  }
  shouldComponentUpdate() {
    // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
    return false;
  }

  render() {
    return (
      <>
        <div ref={this.elRef} className="component-barcode-scanner">
          <>
            <div
              className="camLoader"
              style={{
                width: '100%',
                height: '100',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ThreeDots color={localStorage.getItem('color')} height="100" width="100" wrapperStyle={{position:'absolute',top:'30%'}}/>
            </div>
          </>
          <div className="dce-video-container"></div>
          <div className="dce-scanarea">
            <div className="dce-scanlight" style={{display: 'none'}}></div>
          </div>
          {/* <div className="dce-scanarea">
                        
                    </div> */}
        </div>
      </>
    );
  }
}

export default VideoDecode;
