import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {BrowserRouter, Route, Switch, useHistory} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import Container from './Components/Container/Container';
import Categories from './Components/eAlert/Categories';
import Preferences from './Components/eAlert/Preferences';
import HealthCheck from './Components/HealthCheck/HealthCheck';
import Loyalty from './Components/Loyalty/Loyalty';
import Page404 from './Components/Navigation/Page404';
import PriceCheck from './Components/PriceCheck/PriceCheck';
import ChangePassword from './Components/Profile/ChangePassword';
import EditProfile from './Components/Profile/EditProfile';
import ForgotPassword from './Components/Profile/ForgotPassword';
import Profile from './Components/Profile/Profile';
import ResetPassword from './Components/Profile/ResetPassword';
import PreScan from './Components/Scan/PreScan';
import Scan from './Components/Scan/Scan';
import SignIn from './Components/SignIn/SignIn';
import SignUp from './Components/SignUp/SignUp';
import StoreSelect from './Components/StoreSelect/StoreSelect';
import Success from './Components/Success/Success';
import PrivacyPolicy from './Components/Terms/PrivacyPolicy';
import Terms from './Components/Terms/Terms';
import {eAlertActions} from './Store/eAlertSlice';
import {flagActions} from './Store/flagSlice';
import {checkDomain} from './Utils/index';
import Template from './Utils/Template';

import PurchaseHistory from './Components/Profile/PurchaseHistory';

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const flag = useSelector((state) => state.flags);
  useEffect(() => {
    const unblock = history.block((tx) => {
      if (false) {
        unblock();
        tx.retry();
      }
    });
    const unlisten = history.listen((loc, action) => {
      if (action == 'POP') {
        if (loc.pathname == '/profile') {
          if (flag.came_from_idd) {
            const unblock = history.block((tx) => {
              // unblock();
            });
            window.location.href = flag.idd_url;
            dispatch(flagActions.setIddFlag(false));
            dispatch(flagActions.setIddUrl(''));
          }
        }
        if (loc.pathname == '/prescan') {
          if (flag.came_from_idd) {
            dispatch(flagActions.setIddFlag(false));
            dispatch(flagActions.setIddUrl(''));
            const unblock = history.block((tx) => {
              unblock();
              tx.retry();
            });
            window.location.href = flag.idd_url;
          }
        }
      }
    });
    checkDomain();

    return () => {
      dispatch(eAlertActions.setPrefId(0));
    };
  }, []);

  return (
    <BrowserRouter>
      <div>
        <Container>
          <ToastContainer />

          <Switch>
            <Template path="/" exact={true} element={<StoreSelect />} />
            <Template path="/purchasehistory" exact={true} element={<PurchaseHistory />} />
            <Template path="/scan" element={<Scan />} />
            <Template path="/prescan" element={<PreScan />} />
            <Template path="/product" exact={true} element={<PriceCheck />} />
            <Template path="/signup" exact={true} element={<SignUp />} />
            <Template path="/success" exact={true} element={<Success />} />
            <Template path="/signin" exact={true} element={<SignIn />} />
            <Template path="/profile" exact={true} element={<Profile />} />
            <Template path="/edit" exact={true} element={<EditProfile />} />
            <Template path="/changepassword" exact={true} element={<ChangePassword />} />
            <Template path="/loyalty" exact={true} element={<Loyalty />} />
            <Template path="/terms" exact={true} element={<Terms />} />
            <Template path="/forgotpassword" exact={true} element={<ForgotPassword />} />
            <Template path="/preferences" exact={true} element={<Preferences />} />
            <Template path="/categories" exact={true} element={<Categories />} />
            <Template path="/privacy" exact={true} element={<PrivacyPolicy />} />
            <Template path="/resetpassword" exact={false} element={<ResetPassword />} />
            <Route path="/healthcheck" exact={true}>
              <HealthCheck />
            </Route>
            <Template path="*" exact={true} element={<Page404 />} />
          </Switch>
        </Container>
      </div>
    </BrowserRouter>
  );
}
//EXPORT
export default App;

function ErrorModal() {
  const history = useHistory();
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    if (window.history.length > 2) {
      history.goBack();
    } else {
      history.push('/signin');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <div>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modalcontent">
                <div className="message-img"></div>
                <br />
                <p>
                  <strong>
                    Visit this page with your mobile <br />
                    device to check individual discounts <br />
                    on great items in store! <br />
                  </strong>
                </p>
                <div className="modalbutton">
                  <button type="submit" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} className="btn mainbtn">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
