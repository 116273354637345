import {useHistory} from 'react-router-dom';
import * as API from '../../Utils/Global.js';

const Success = () => {
  const history = useHistory();

  const Update = async () => {
    await fetch(API.updatePref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: localStorage.getItem('user_id'),
      }),
    })
      .then(async (raw) => {})
      .catch((err) => {});
  };

  const signIn = () => {
    history.push('/profile');
  };
  const signUp = () => {
    Update();
    history.push('/preferences');
  };
  return (


<>
  
{/* // <!-- main body start --> */}
<div className="clearfix"></div>
<div className="container main-wrap">
  <div className="heading-wrap">
   <h1> Congratulations</h1>
  </div>
  <div>
    <h4>Your account has been<br/>
      created successfully.</h4>
  </div>

  <div className="col-sm-12 center">



    <div className="thumbsimg"></div>



  </div>

  <div className="main-sign-wrap">

    <div className="sign-wrap">

      <h4>Have you signed up to hear

        about the great deals at your

        favorite stores? </h4>

    </div>

  </div>



  <div className="form-wrap">

    <div className="col-sm-12 center">

      {/* <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal"> */}

        <button className="btn redbtn  submit-button" onClick={signUp}>If not, sign up now</button> 

    </div>

    <div className="col-sm-12 center">

      <button className="btn yellowbtn  submit-button" onClick={signIn}>close</button>

    </div>

  </div>

</div>

</>

  );
};

export default Success;
