import $ from 'jquery';
import {useEffect, useState} from 'react';
import InputMask from 'react-input-mask';
import {trackPromise} from 'react-promise-tracker';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {allstoresActions} from '../../Store/allStoresSlice';
import {eAlertActions} from '../../Store/eAlertSlice';
import {profileActions} from '../../Store/profileSlice';
import * as API from '../../Utils/Global.js';
import {US_STORES} from '../../Utils/Global.js';
import OptOutModal from './OptOutModal';
import ShareMyInfoModal from './ShareMyInfoModal';
import {Link} from 'react-router-dom';

let diff = [];
let mapped = [];
let strs = [];
var stack = [];
var stackItem = null;
var home_store = null;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '55px',
  }),
};

const EditProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const AllStores = useSelector((state) => state.store);
  const AllStores = useSelector((state) => state.allstores);
  const Profile = useSelector((state) => state.profile);
  home_store = Profile.home_store;
  const eAlert = useSelector((state) => state.eAlert);
  const user_id = localStorage.getItem('user_id');
  const [fName, setfname] = useState(Profile.first_name);
  const [lName, setlname] = useState(Profile.last_name);
  const [email, setemail] = useState(Profile.email);
  const [addr1, setaddr1] = useState(Profile.address_1);
  const [addr2, setaddr2] = useState(Profile.address_2);
  const [phone, setphone] = useState(Profile.phone);
  const [country, setcountry] = useState(Profile.country);
  const [city, setcity] = useState(Profile.city);
  const [state, setstate] = useState(Profile.state);
  const [zip, setzip] = useState(Profile.zip);
  const [favstores, setfavstores] = useState([]);
  const [selected, setSelected] = useState([]);
  const [changed, setChanged] = useState(false);
  const stores = useSelector((state) => state.eAlert);
  const [mounted, setMounted] = useState(false);
  const [homeStore, setHomeStore] = useState(0);
  const [OptoutShow, setOptoutShow] = useState(false);
  const [consentCheck, setConsentCheck] = useState(true);
  const [ShareMyInfo, ShareMyInfoShow] = useState(false);

  const [resp, setUserResp] = useState({});
  var [selectRef, setRef] = useState(null);
  // checkDomain();

  let domain = localStorage.getItem('store_type');
  let Country = US_STORES.indexOf(domain) != -1 ? 'US' : 'CA';
  if (domain == null) {
    Country = 'US';
  }

  useEffect(() => {
    if (user_id == '' || user_id == null) {
      history.push('/signin');
    }
    getStateList(AllStores.stores, Country);
    // trackPromise();
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    let Country = US_STORES.indexOf(domain) != -1 ? 'US' : 'CA';
    if (domain == null) {
      Country = 'US';
    }
    var url = '';

    if (Country.toLocaleLowerCase() == 'ca') {
      url = API.eAlertStores + '?store_type=CANADA';
    } else {
      url = API.eAlertStores;
    }

    $.ajax({
      url: url,
      type: 'GET',
      async: false,
      success: function (response) {
        dispatch(allstoresActions.stores({stores: response.stores}));
        let appending = localStorage.getItem('store_type') === 'CANADA' ? 'canada' : 'us';
        $.ajax({
          url: API.state + '?country=' + appending,
          type: 'GET',
          async: false,
          success: function (response) {
            // var stateList=response;
            // setState(stateList);
            getStateList(response, Country);
          },
          error: function (xhr, response) {
            console.log(xhr);
            console.log(response);
          },
        });
        // getStateList(response.stores, null);
        getProfileDetails(response.stores);
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
  }, []);

  useEffect(() => {
    if (stackItem !== null) {
      selectRef.selectOption(stackItem);
    }
  }, [stackItem]);

  useEffect(() => {
    if (home_store !== null || home_store !== '') {
      let val = AllStores.storelist.filter((el) => {
        return el.value == home_store;
      })[0];
      var List = [...AllStores.storelist];
      List.splice(AllStores.storelist.indexOf(val), 1);
      dispatch(allstoresActions.homeStores({homestores: List}));
    }
  }, []);

  const setResponse = (response) => {
    dispatch(profileActions.setDet(response));
  };

  function getProfileDetails(stores, state) {
    $.ajax({
      url: API.profile,
      type: 'POST',
      async: false,
      accepts: 'application/json',
      contentType: 'application/json',
      data: JSON.stringify({
        UserID: user_id,
      }),
      headers: {
        'x-auth-token': localStorage.getItem('user_token'),
      },
      success: function (response) {
        setResponse(response);
        // var st = getStoreList(stores, response[0].state);
        // if (response[0].favStores != null) {
        //   var favSt = response[0].favStores.split(",");
        //   var arr1 = favSt.map((el) => parseInt(el));
        //   var arr = [];
        //   for (let i = 0; i < arr1.length; i++) {
        //     for (let j = 0; j < st.length; j++) {
        //       if (arr1[i] == st[j].value) {
        //         arr.push(st[j]);
        //       }
        //     }
        //   }
        //   // dispatch(profileActions.setSelected({ selected: arr }));
        //   setSelected(arr);
        // }
        setHomeStore(response[0].home_store);
        home_store = response[0].home_store;
        setfname(response[0].first_name);
        setlname(response[0].last_name);
        setemail(response[0].email);
        setaddr1(response[0].address_1);
        setaddr2(response[0].address_2);
        setcity(response[0].city);
        setcountry(response[0].country);
        setstate(response[0].state);
        setzip(response[0].zip);
        setphone(response[0].phone);
        let appending = localStorage.getItem('store_type') === 'CANADA' ? 'canada' : 'us';
        $.ajax({
          url: API.state + '?country=' + appending,
          type: 'GET',
          async: false,
          success: function (response) {
            // var stateList=response;
            // setState(stateList);
            getStateList(response, Country);
          },
          error: function (xhr, response) {
            console.log(xhr);
            console.log(response);
          },
        });
        // getStateList(stores, Country);
      },
      error: function (xhr, response) {
        console.log(xhr);
        console.log(response);
      },
    });
  }

  if (user_id != '' && user_id != null) {
    if (AllStores.stores.length != 0) {
      if (Profile.email == '') {
        trackPromise(
          $.ajax({
            url: API.profile,
            type: 'POST',
            async: false,
            accepts: 'application/json',
            contentType: 'application/json',
            data: JSON.stringify({
              UserID: user_id,
            }),
            headers: {
              'x-auth-token': localStorage.getItem('user_token'),
            },
            success: function (response) {
              setResponse(response);
              var st = getStoreList(AllStores.stores, response[0].state);
              if (response[0].favStores != null) {
                var favSt = response[0].favStores.split(',');
                var arr1 = favSt.map((el) => parseInt(el));
                var arr = [];
                for (let i = 0; i < arr1.length; i++) {
                  for (let j = 0; j < st.length; j++) {
                    if (arr1[i] == st[j].value) {
                      arr.push(st[j]);
                    }
                  }
                }
                dispatch(profileActions.setSelected({selected: arr}));
                setSelected(arr);
              }
              home_store = response[0].home_store;
              setHomeStore(response[0].home_store);
              setfname(response[0].first_name);
              setlname(response[0].last_name);
              setemail(response[0].email);
              setaddr1(response[0].address_1);
              setaddr2(response[0].address_2);
              setphone(response[0].phone);
              setcity(response[0].city);
              setcountry(response[0].country);
              Country = US_STORES.indexOf(response[0].country) != -1 ? 'US' : 'CA';
              getStateList(AllStores.storelist, Country);
              setstate(response[0].state);
              setzip(response[0].zip);
            },
            error: function (xhr, response) {
              console.log(xhr);
              console.log(response);
            },
          }),
        );
      } else {
      }
    } else {
    }
  }

  function getStateList(stores, country) {
    var states = [];
    if (country != null) {
      for (let i = 0; i < stores.length; i++) {
        if (states.length == 0 || states.indexOf(stores[i].state) == -1) {
          // if (
          //   (stores[i].store_type === "CANADA" && country === "CA") ||
          //   (stores[i].store_type !== "CANADA" && country === "US")
          // ) {
          states.push(stores[i].state);
          // }
        }
      }
    } else {
      for (let i = 0; i < stores.length; i++) {
        if (states.length == 0) {
          states.push(stores[i].state);
        }
        if (states.indexOf(stores[i].state) == -1) {
          states.push(stores[i].state);
        }
      }
    }
    states = states.sort();
    dispatch(allstoresActions.stateList({statelist: states}));
  }

  function getStoreList(stores, state) {
    var storelist = [];
    for (let i = 0; i < stores.length; i++) {
      // if (stores[i].state == state)
      storelist.push({
        value: stores[i].idd_store_id,
        label: stores[i].name,
      });
    }
    // dispatch(profileActions.setStorelist({ storelist: storelist }));
    storelist = storelist.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    dispatch(allstoresActions.storeList({storelist: storelist}));
    dispatch(allstoresActions.homeStores({homestores: storelist}));
    return storelist;
  }
  function Update() {
    fetch(API.updatePref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then(async (raw) => {
        console.log(raw);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function sendStores() {
    // var locations = new Set([[...homeStore], [...eAlert.subscriber_locations]]);
    var locations = [...new Set([...[homeStore], ...eAlert.subscriber_locations])];
    // var diffArray = diff.map((el) => parseInt(el));
    // var myArray = locations.filter((el) => !diffArray.includes(el));

    // // var union = [...new Set([...myArray, ...favstores])];
    // var fav = favstores;
    // if (fav.length == 0) {
    //   if (!changed) {
    //     fav = Profile.favStores.map((el) => parseInt(el));
    //   } else {
    //     fav = [];
    //   }
    // }
    dispatch(eAlertActions.setLocations({locations: locations}));

    fetch(API.setUserPref, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('user_token'),
      },
      body: JSON.stringify({
        user_id: user_id,
        deliver_email: eAlert.deliver_email,
        deliver_sms: eAlert.deliver_sms,
        subscriber_categories: eAlert.subscriber_categories,
        subscriber_locations: locations,
      }),
    })
      .then((raw) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  if (!mounted) {
    //Populate Profile Data
    if (Profile.email == '') {
      getProfileDetails(strs, '');
    }

    //Populate FavStore Data

    //Getting Stores
    if (strs.length == 0) {
      let Country = localStorage.getItem('store_type');
      var url = '';

      if (Country.toLocaleLowerCase() == 'canada') {
        url = API.eAlertStores + '?store_type=CANADA';
      } else {
        url = API.eAlertStores;
      }
      trackPromise(
        $.ajax({
          url: url,
          type: 'GET',
          async: false,
          success: function (response) {
            strs = response.stores;
          },
          error: function (xhr, response) {},
        }),
      );
    }

    //Getting User Preferences
    if (stores.pref_id == 0) {
      $.ajax({
        url: API.userPref,
        type: 'POST',
        async: false,
        accepts: 'application/json',
        contentType: 'application/json',
        data: JSON.stringify({
          user_id: user_id,
        }),
        headers: {
          'x-auth-token': localStorage.getItem('user_token'),
        },
        success: async function (response) {
          setUserResp(response);
          var isEmpty = $.isEmptyObject(response);
          if (!isEmpty) {
            var st = getStoreList(strs);
            var arr1 = '';

            arr1 = response.subscriber_locations_email;

            arr1 = arr1.split(',');
            arr1 = arr1.map((el) => parseInt(el));
            var arr = [];
            for (let i = 0; i < arr1.length; i++) {
              for (let j = 0; j < st.length; j++) {
                if (arr1[i] == st[j].value) {
                  arr.push(st[j]);
                }
              }
            }
            let storeObj = st.filter((el) => {
              return el.value == home_store;
            })[0];
            arr.splice(arr.indexOf(storeObj), 1);
            setSelected(arr);
            var subscriber_categories = [];
            var subscriber_locations = [];
            var isEmpty = $.isEmptyObject(response);
            if (!isEmpty) {
              if (response.subscriber_categories != '') {
                var sub_cat = response.subscriber_categories.split(',');
                sub_cat.map((el) => subscriber_categories.push(parseInt(el)));
              }
              if (response.subscriber_locations_email != '') {
                var sub_loc = response.subscriber_locations_email.split(',');
                sub_loc.map((el) => subscriber_locations.push(parseInt(el)));
              }
            }
            let List = [...AllStores.storelist];
            if (home_store != 0) {
              if (subscriber_locations.indexOf(home_store) != -1) {
                let obj = AllStores.storelist.filter((e) => {
                  return e.value == home_store;
                });
                stack.push(obj[0]);
                let index = AllStores.storelist.indexOf(obj[0]);
                let newList = [...List];
                newList.splice(index, 1);
                dispatch(allstoresActions.homeStores({homestores: newList}));
              }
            } else {
              dispatch(allstoresActions.homeStores({homestores: List}));
            }

            dispatch(eAlertActions.setPrefId(response.pref_id));
            dispatch(eAlertActions.setEmailPref({email: response.deliver_email}));
            dispatch(eAlertActions.setSmsPref({sms: response.deliver_sms}));
            dispatch(
              eAlertActions.addCategories({
                categories: subscriber_categories,
              }),
            );
            dispatch(
              eAlertActions.setLocations({
                locations: subscriber_locations,
              }),
            );
          } else {
            dispatch(eAlertActions.setPrefId(-1));
            dispatch(eAlertActions.setEmailPref({email: 1}));
            dispatch(eAlertActions.setSmsPref({sms: 1}));
            dispatch(
              eAlertActions.addCategories({
                categories: [],
              }),
            );
            dispatch(
              eAlertActions.setLocations({
                locations: [],
              }),
            );
          }
        },
        error: function (xhr, response) {
          console.log(xhr);
          console.log(response);
        },
      });
    }
    if (selected.length == 0) {
      var arr = [];
      var arr1 = [];
      var sub_loc_email = '';
      $.ajax({
        url: API.userPref,
        type: 'POST',
        async: false,
        accepts: 'application/json',
        contentType: 'application/json',
        data: JSON.stringify({
          user_id: user_id,
        }),
        headers: {
          'x-auth-token': localStorage.getItem('user_token'),
        },
        success: async function (response) {
          var isEmpty = $.isEmptyObject(response);
          if (!isEmpty) {
            sub_loc_email = response.subscriber_locations_email;
            arr1 = sub_loc_email;
            arr1 = arr1.split(',');
            arr1 = arr1.map((el) => parseInt(el));

            var st = getStoreList(strs, Profile.state);

            for (let i = 0; i < arr1.length; i++) {
              for (let j = 0; j < st.length; j++) {
                if (arr1[i] == st[j].value) {
                  arr.push(st[j]);
                }
              }
            }
            if (arr.length == 0) {
              for (let i = 0; i < arr1.length; i++) {
                for (let j = 0; j < strs.length; j++) {
                  if (arr1[i] == strs[j].idd_store_id) {
                    arr.push(strs[j]);
                  }
                }
              }
            }
            let storeObj = st.filter((el) => {
              return el.value == home_store;
            })[0];
            let indx = arr.indexOf(storeObj);
            if (indx != -1) {
              arr.splice(indx, 1);
            }
            if (arr.length > 0) {
              setSelected(arr);
            }
          }
        },
        error: function (xhr, response) {},
      });
    }
  }

  const fNameHandler = (event) => {
    setfname(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "fName", attrVal: event.target.value })
    // );
  };
  const lNameHandler = (event) => {
    setlname(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "lName", attrVal: event.target.value })
    // );
  };
  const emailHandler = (event) => {
    setemail(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "email", attrVal: event.target.value })
    // );
  };
  const addr1Handler = (event) => {
    setaddr1(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "addr1", attrVal: event.target.value })
    // );
  };
  const addr2Handler = (event) => {
    setaddr2(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "addr2", attrVal: event.target.value })
    // );
  };
  const phoneHandler = (event) => {
    setphone(event.target.value);
  };
  const cityHandler = (event) => {
    setcity(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "city", attrVal: event.target.value })
    // );
  };

  const stateHandler = (event) => {
    // selectRef.clearValue();
    setstate(event.target.value);
    getStoreList(AllStores.stores, event.target.value);
    // setSelected([]);
  };

  const zipHandler = (event) => {
    setzip(event.target.value);
    // dispatch(
    //   profileActions.setForm({ attr: "zip", attrVal: event.target.value })
    // );
  };
  const consentHandler = (e) => {
    if (!e.target.checked) {
      setOptoutShow(true);
    }
  };
  const favStoreChange = (params) => {
    // var locations = [...eAlert.subscriber_locations];
    var arr = params.map((store) => store.value);
    // locations = [...new Set([...arr, ...locations])];
    setChanged(true);
    setfavstores(arr);
    dispatch(eAlertActions.setLocations({locations: arr}));
  };

  const changePassword = () => {
    history.push('/changepassword');
  };

  const submitHandler = (event) => {
    event.preventDefault();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      toast.warning('Invalid email!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      setemail('');
      document.editform.email.focus();
      return;
    }
    if(domain !=="CANADA"){
        if (!zip) {
          let validationMessage = '';
          if (localStorage.getItem('store_type') === 'CANADA') {
            validationMessage = 'Postal code is required!';
          } else {
            validationMessage = 'Zip code is required!';
          }
          toast.warning(validationMessage, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          setzip('');
          return;
        }
        if (localStorage.getItem('store_type') === 'CANADA') {
          if (!zip.match(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/g)) {
            toast.warning('Invalid postal code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            setzip('');
            return;
          }
        } else {
          if (zip<=0) {
            toast.warning('Invalid zip code!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
            setzip(null);
            return;
          }
        }
        if (state == '') {
          toast.warning('Please select state!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          document.editform.state.focus();
          return;
        }
        if (!phone.match(/^[2-9]{1}\d/g) || phone.length < 10 || phone.length > 25) {
          toast.warning('Invalid phone!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
          return;
        }
    } 
    if (homeStore == '' || homeStore == null || homeStore == 0) {
      toast.warning('Please select Home Store!', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
      document.editform.homeStore.focus();
      return;
    }
    
    // const salt = bcrypt.genSaltSync(10);
    var arr1 = Profile.favStores.map((el) => parseInt(el));

    //favStore+homeStore logic
    //homeStore not visible as selected
    var storeUnion = null;
    if (changed) {
      storeUnion = [...new Set([...[homeStore], ...favstores])];
    } else {
      storeUnion = [...new Set([...[homeStore], ...arr1])];
    }

    const form = {
      UserID: localStorage.getItem('user_id'),
      EmailAddress: email,
      FirstName: fName,
      LastName: lName,
      Address1: addr1,
      Address2: addr2,
      Phone: phone,
      City: city,
      State: state,
      Country: country,
      ZipCode: zip,
      HomeStore: homeStore,
      FavStores: storeUnion,
    };
    stackItem = null;

    var fav = favstores.toString();
    if (fav == '') {
      if (!changed) {
        fav = Profile.favStores.toString();
      } else {
        fav = null;
      }
      setSelected([]);
    }

    var response = [
      {
        first_name: fName,
        last_name: lName,
        email: email,
        address_1: addr1,
        address_2: addr2,
        phone: phone,
        city: city,
        state: state,
        country: country,
        zip: zip,
        cookie_agreement: 1,
        tnc_agreement: 1,
        member_id: user_id,
        last_login: null,
        home_store: homeStore,
        favStores: fav,
        storeList: Profile.storeList,
        selected: [],
      },
    ];

    trackPromise(
      fetch(API.update, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      })
        .then((raw) => {
          if (raw.status == 200) {
            setResponse(response);
            toast.success('User Details Updated Successfully', {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});

            sendStores();
            // Update();
            dispatch(eAlertActions.setPrefId(0));
            history.push('/profile');
          }
        })
        .catch((err) => {
          toast.error(err, {position: toast.POSITION.TOP_CENTER}, {autoClose: 1000});
        }),
    );
  };
  const homeStoreHandler = (event) => {
    //     If(selected in fav stores)
    // Favourite.pop(selected);
    // Side_Array.push(selected);
    let value = event.target.value;
    value = value == '' ? 0 : parseInt(value);
    home_store = value;
    setHomeStore(value);
    let List = [...AllStores.storelist];
    if (stack.length !== 0) {
      stackItem = stack.pop();
      List.push(stackItem);
      dispatch(allstoresActions.homeStores({homestores: List}));
    }
    let obj = AllStores.storelist.filter((e) => {
      return e.value == value;
    });
    let index = AllStores.storelist.indexOf(obj[0]);
    let newList = [...List];
    newList.splice(index, 1);
    dispatch(allstoresActions.homeStores({homestores: newList}));
    // if (stackItem != null) {
    //   selectRef.props.options.push(stackItem);
    // }
    let favIndex = -1;
    if (selected.length != 0) {
      favIndex = selected.indexOf(obj[0]);
      if (favIndex != -1) {
        let arr = [...selected];
        arr.splice(favIndex, 1);
        // dispatch(signUpActions.setForm({ attr: "favStore", attrVal: arr }));
        let val = selectRef.props.value.filter((el) => {
          return el.value == value;
        })[0];
        selectRef.removeValue(val);
        stack.push(val);
      }
    } else {
      favIndex = favstores.indexOf(obj[0].value);
      if (favIndex != -1) {
        let arr = [...favstores];
        arr.splice(favIndex, 1);
        // dispatch(signUpActions.setForm({ attr: "favStore", attrVal: arr }));
        let val = selectRef.props.value.filter((el) => {
          return el.value == value;
        })[0];
        selectRef.removeValue(val);
        stack.push(val);
      }
    }
  };
  return (
    <>
      <div className="clearfix"></div>
      <div className="container main-wrap">
          <div className="heading-wrap">
            <h2>Edit Profile</h2>
          </div>

        <div className="form-wrap">
          <form name="editform" onSubmit={submitHandler}>
            <div className="row form_inner_wrap">
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" placeholder={'First Name'} onChange={fNameHandler} value={fName} required />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="text" className="form-control" placeholder={'Last Name'} onChange={lNameHandler} value={lName} required />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6">
                <input type="email" disabled className="form-control" placeholder={'Email'} onChange={emailHandler} value={email} required />
              </div>
              {domain ==="CANADA" 
              ? <></> 
              :
              <>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <input type="text" className="form-control" placeholder={'Address 1'} onChange={addr1Handler} value={addr1} />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <input type="text" className="form-control" placeholder={'Address 2'} onChange={addr2Handler} value={addr2} />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <input type="number" className="form-control" value={phone} placeholder="Phone Number*" required onChange={phoneHandler} />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <input type="text" className="form-control" placeholder={'City'} onChange={cityHandler} value={city} />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <input type="text" className="form-control" value={Country == 'US' ? 'United States' : 'Canada'} disabled />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <select className="form-select" onChange={stateHandler} value={state} required>
                    {localStorage.getItem('store_type') === 'CANADA' ? (
                      <>
                        <option value={''}>Select Province*</option>{' '}
                        {AllStores.statelist.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}{' '}
                      </>
                    ) : (
                      <>
                        <option value={''}>Select State*</option>
                        {AllStores.statelist.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}{' '}
                      </>
                    )}
                  </select>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  {localStorage.getItem('store_type') === 'CANADA' ? (
                    <InputMask mask="*** ***" onChange={zipHandler} placeholder="Postal Code*" value={zip} className="form-control"></InputMask>
                  ) : (
                    <InputMask mask="99999" onChange={zipHandler} value={zip} placeholder="Zip*" className="form-control"></InputMask>
                  )}
                </div>
              </>
              }
              <div className="col-md-6 col-sm-6 col-xs-6">
                <select className="form-select" onChange={homeStoreHandler} value={homeStore} required>
                  <option value={''}>What is your home store?*</option>
                  {AllStores.storelist.map((store) => (
                    <option key={store.value} value={store.value}>
                      {store.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 mt-1">
                <Select
                  ref={(ref) => {
                    selectRef = ref;
                  }}
                  placeholder={
                    localStorage.getItem('store_type') === 'CANADA' ? 'Would you like to add other stores?' : 'Would you like to add other stores?'
                  }
                  isMulti
                  options={AllStores.homestores}
                  onChange={favStoreChange}
                  className="multiSelectContainer"
                  defaultValue={selected}
                  styles={customStyles}
                  isDisabled={homeStore === 0 ? true : false}
                />
              </div>
              {localStorage.getItem('store_type') === 'CANADA' ? (
                <div className="col-md-12">
                  <div className="checkbox-wrap">
                    <input id="consentCheckBox" type="checkbox" onChange={consentHandler} checked={consentCheck} />
                  </div>
                  <div className="content-wrap">
                    I consent to{' '}
                    <Link onClick={() => ShareMyInfoShow(true)}>
                      <u>Sharing My Information</u>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="">
              <div className="col-md-12">
                <div className="col-sm-12 center">
                  <button className="btn yellowbtn  submit-button" onClick={changePassword}>
                    change password
                  </button>
                </div>
                <div className="col-sm-12 center">
                  <button className="btn redbtn  submit-button" type="submit" onClick={submitHandler}>
                    Save details
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <OptOutModal setOptoutShow={setOptoutShow} show={OptoutShow} setConsentCheck={setConsentCheck} />
        <ShareMyInfoModal show={ShareMyInfo} ShareMyInfoShow={ShareMyInfoShow} setConsentCheck={setConsentCheck} setOptoutShow={setOptoutShow} />
      </div>
    </>
  );
};

export default EditProfile;
