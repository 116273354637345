import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  state: '',
  store: {
    id: 0,
    name: '',
  },
  id: 0,
  statelist: [],
  storelist: [],
  homestores: [],
  stores: [],
};
const allStoresSlice = createSlice({
  name: 'allstores',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    stateChange(state, action) {
      state.state = action.payload.state;
    },
    stateList(state, action) {
      state.statelist = action.payload.statelist;
    },
    storeChange(state, action) {
      state.store.id = action.payload.id;
      state.store.name = action.payload.name;
    },
    storeList(state, action) {
      state.storelist = action.payload.storelist;
    },
    homeStores(state, action) {
      state.homestores = action.payload.homestores;
    },
    stores(state, action) {
      state.stores = action.payload.stores;
    },
    setId(state, action) {
      state.id = action.payload.id;
    },
  },
});

export const allstoresActions = allStoresSlice.actions;
export default allStoresSlice;
