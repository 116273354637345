import {combineReducers, configureStore} from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import storeSlice from './storeSlice';
import signUpSlice from './signUpSlice';
import profileSlice from './profileSlice';
import allStoresSlice from './allStoresSlice';
import eAlertSlice from './eAlertSlice';
import flagSlice from './flagSlice';
import persistReducer from 'redux-persist/es/persistReducer';

const reducers = combineReducers({
  profile: profileSlice.reducer,
  store: storeSlice.reducer,
  allstores: allStoresSlice.reducer,
  signUp: signUpSlice.reducer,
  eAlert: eAlertSlice.reducer,
  flags: flagSlice.reducer,
});

const persistConfig = {
  key: 'dfkey_globalstoreV2',
  blacklist: ['profile', 'signUp', 'eAlert', 'flags'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const Store = configureStore({
  reducer: persistedReducer,
});

export default Store;
export const per = persistConfig;
