import {createSlice} from '@reduxjs/toolkit';

const flagSlice = createSlice({
  name: 'flags',
  initialState: {
    came_from_idd: false,
    idd_url: '',
  },
  reducers: {
    setIddFlag(state, action) {
      state.came_from_idd = action.payload;
    },
    setIddUrl(state, action) {
      state.idd_url = action.payload;
    },
  },
});

export const flagActions = flagSlice.actions;
export default flagSlice;
